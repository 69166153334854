import React from 'react';
import { useMutation } from 'react-fetching-library';

import { ParticipantAddEditDialog } from '../ParticipantAddEditDialog';

import { createAddParticipant } from 'api/actions/participants/participantsActions';
import { ParticipantEditValues } from 'api/actions/participants/participantsActions.types';
import { ParticipantAddContainerProps } from './ParticipantAddContainer.types';

export const ParticipantAddContainer = ({
  onClose,
  updateParticipantsList,
  eventId,
  open,
}: ParticipantAddContainerProps) => {
  const { mutate, error: isError, payload: errorMessage } = useMutation(createAddParticipant(eventId || ''));

  const onUpdate = async (values: ParticipantEditValues) => {
    const { error } = await mutate(values);

    if (!error) {
      onClose();
      updateParticipantsList();
    }
  };

  return (
    <ParticipantAddEditDialog
      onClose={onClose}
      data={undefined}
      onUpdate={onUpdate}
      open={open}
      error={isError}
      errorMessage={errorMessage}
    />
  );
};
