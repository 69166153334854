import React from 'react';

export const usePagination = (initialRowsPerPage: number) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);

  const handlePageChange = (nextPage: number) => {
    setPage(nextPage);
  };

  const handleRowsPerPageChange = (perPage: number) => {
    setRowsPerPage(perPage);
    setPage(0);
  };

  return { page, handlePageChange, rowsPerPage, handleRowsPerPageChange };
};
