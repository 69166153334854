import React from 'react';

import { Container } from 'shared/components/container/Container';
import { Image } from 'shared/components/image/Image';
import { Separator } from 'shared/components/separator/Separator';
import { Typography } from 'shared/components/typography/Typography';
import { useLocale } from 'shared/hooks/useLocale/useLocale';
import { MailLink } from 'shared/components/mailLink/MailLink';
import { StyledGridContainer, Wrapper } from './ErrorPage.styles';
import icon from 'assets/icons/screen_bg.svg';

export const ErrorPage: React.FC = () => {
  const { formatMessage } = useLocale();
  const email = formatMessage({ id: 'static.support_email' }) as string;
  const content = formatMessage({ id: 'error_page.content' }, { email: <MailLink mailto={email}>{email}</MailLink> });

  return (
    <Container>
      <StyledGridContainer spacing={0} direction="column" alignItems="center" justify="center">
        <Wrapper>
          <Image src={icon} alt="screen icon" />
          <Separator height={20} />
          <Typography variant="h1">error_page.title</Typography>
          <Separator height={20} />
          <Typography>{content}</Typography>
        </Wrapper>
      </StyledGridContainer>
    </Container>
  );
};
