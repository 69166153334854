import { createReducerAction } from 'shared/utils';
import {
  SetTokens,
  Tokens,
  SetAuthorized,
  SetUnAuthorized,
  StartAuthorizing,
  ClearTokens,
  User,
} from './authStateActions.types';

export const SET_AUTHORIZED = 'auth/set-authorized';
export const SET_UNAUTHORIZED = 'auth/set-unauthorized';
export const SET_TOKENS = 'auth/set-tokens';
export const CLEAR_TOKENS = 'auth/clear-tokens';
export const START_AUTHORIZING = 'auth/start-authorizing';

export const setTokens = (tokens: Tokens): SetTokens => createReducerAction(SET_TOKENS, tokens);

export const setAuthorized = (user: User): SetAuthorized => createReducerAction(SET_AUTHORIZED, user);

export const setUnAuthorized = (): SetUnAuthorized => createReducerAction(SET_UNAUTHORIZED);

export const clearTokens = (): ClearTokens => createReducerAction(CLEAR_TOKENS);

export const startAuthorizing = (): StartAuthorizing => createReducerAction(START_AUTHORIZING);
