import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    display: 'inline-flex',
    '& svg path, & svg rect': {
      fill: palette.green.main,
    },
  },
  fullScreen: {
    '&$root': {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));
