import React from 'react';
import { useField } from 'react-final-form';

import { Font } from '../defaultTemplate/DefaultTemplate.types';

const createFontFamily = (url: string, name: string) => `@font-face {
  font-family: ${name};
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${url});
}`;

export const FontLoader = () => {
  const {
    input: { value: fonts },
  } = useField<Font[]>('fonts', { subscription: { value: true } });

  return <style type="text/css">{fonts.map(font => createFontFamily(font.url, font.name))}</style>;
};
