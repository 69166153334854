import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { useAutoHashScroll } from 'shared/hooks';
import { StepperStateContext } from './context/SteppertStateContext';
import { Step, StepperProps } from './Stepper.types';

const UNSET = '';

export const Stepper: React.FC<StepperProps> = ({
  children,
  onChange,
  activeStep = UNSET,
  scrollToSection = false,
}) => {
  useAutoHashScroll({ enabled: scrollToSection });

  const [step, setStep] = useState<Step>(activeStep);
  const { pathname } = useLocation();
  const { push } = useHistory();

  const body = React.Children.toArray(children) as React.ReactElement[];

  const getIndex = (name: string) => body.findIndex(({ props }) => props.step === name);

  if (body.some(child => typeof child.props.step === 'undefined')) {
    throw Error('Stepper child must have step prop');
  }

  useEffect(() => {
    if (typeof onChange !== 'undefined' && step !== UNSET) {
      onChange({ step });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (typeof activeStep !== 'undefined' && activeStep !== step) {
      setStep(activeStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    if (scrollToSection) {
      push(`${pathname}#${step}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const goNext = () => {
    const nextElement = body[getIndex(step) + 1];

    if (nextElement) {
      setStep(nextElement.props.step);
    }
  };

  const goBack = () => {
    const nextElement = body[getIndex(step) - 1];

    if (nextElement) {
      setStep(nextElement.props.step);
    }
  };

  const reset = () => setStep(UNSET);

  const value = {
    step,
    setStep,
    goNext,
    goBack,
    isLast: step === body[body.length - 1].props.step,
    isFirst: step === body[0].props.step,
    reset,
  };

  return <StepperStateContext.Provider value={value}>{body}</StepperStateContext.Provider>;
};
