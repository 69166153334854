import React from 'react';
import { FormHelperText as MaterialFormHelperText, FormHelperTextProps } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

export const StyledFormHelperText: React.ComponentType<FormHelperTextProps> = withStyles(({ typography, palette }) => ({
  root: {
    ...typography.caption,
    color: palette.error.main,
    padding: '0 15px',
  },
}))(MaterialFormHelperText);

export const useStyles = makeStyles(({ palette }) => ({
  success: {
    color: palette.primary.main,
  },
}));
