import { Config } from './handleRequest.types';
import { createApiErrorEvent } from './apiErrorEvent';

const noop = () => {
  return undefined;
};

const dispatch = window.dispatchEvent;

export const handleRequest = async <Payload = any>(config: Config<Payload>) => {
  const { action, onSuccess = noop, onFailure = noop, errorMessage = 'error.unknown' } = config;

  const { error, payload, status } = await action();

  if (error) {
    if (onFailure === noop) {
      dispatch(createApiErrorEvent({ message: errorMessage, severity: 'error' }));
    }

    return onFailure(payload, status);
  }

  return onSuccess(payload, status);
};
