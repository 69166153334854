import React, { useState } from 'react';
import { useMutation } from 'react-fetching-library';

import { ResetPasswordSuccess } from '../ResetPasswordSuccess/ResetPasswordSuccess';

import { createResetPasswordAction } from 'api/actions/auth/authActions';
import { ResetPasswordForm } from './ResetPasswordForm';
import { handleOnSubmit } from 'shared/utils/handleOnSubmit/handleOnSubmit';
import { ResetPasswordBody } from 'api/types';

export const ResetPasswordFormContainer: React.FC = () => {
  const [submitSucceeded, setSubmitSucceeded] = useState(false);
  const { mutate } = useMutation(createResetPasswordAction);

  const handleSubmit = handleOnSubmit<ResetPasswordBody>({
    action: mutate,
    successCallback: () => {
      setSubmitSucceeded(true);
    },
  });

  const handleReset = () => setSubmitSucceeded(false);

  if (submitSucceeded) {
    return <ResetPasswordSuccess goBack={handleReset} />;
  }

  return <ResetPasswordForm onSubmit={handleSubmit} />;
};
