import React from 'react';

import {
  ClipTemplateData,
  ClipTemplateSectionEnum,
  ClipTemplateAudioSectionData,
  ClipTemplateIntroSectionData,
  ClipTemplateOverlaySectionData,
  ClientDefaultsData,
} from 'api/types';
import { InitialValuesContextType } from './initialValues/context/InitialValuesContext';

export enum SectionType {
  audio = 'audio',
  overlay = 'overlay',
  regular = 'regular',
}

export type SectionBody<Values = any> = {
  title: string;
  subtitle: string;
  content: React.ReactNode;
  getInitialValues: (values: Values, defaults: ClientDefaultsData) => InitialValuesContextType;
};

export type Sections = {
  [ClipTemplateSectionEnum.intro]: SectionBody<ClipTemplateIntroSectionData>;
  [ClipTemplateSectionEnum.overlay]: SectionBody<ClipTemplateOverlaySectionData>;
  [ClipTemplateSectionEnum.outro]: SectionBody<ClipTemplateIntroSectionData>;
  [ClipTemplateSectionEnum.audio]: SectionBody<ClipTemplateAudioSectionData>;
};

export type ClipTemplateProps = {
  data: ClipTemplateData;
  isLoading: boolean;
};
