import { EmailTemplateData } from 'api/types';
import { updatableContextFactory } from 'shared/utils';

const [StorageDataProvider, useStorageData, updateStorageData] = updatableContextFactory<EmailTemplateData>();

export { StorageDataProvider, useStorageData, updateStorageData };

const [FormStorageProvider, useFormStorage, updateFormStorage] = updatableContextFactory<EmailTemplateData>();

export { FormStorageProvider, useFormStorage, updateFormStorage };

const [AcceptedStateProvider, useAcceptedState, updateAcceptedState] = updatableContextFactory<{
  header: boolean;
  content: boolean;
}>({ header: false, content: false });

export { AcceptedStateProvider, useAcceptedState, updateAcceptedState };
