import React from 'react';
import { Chip as MaterialChip, ChipProps } from '@material-ui/core';

import { useTranslations } from 'shared/hooks';

export const Chip: React.FC<ChipProps> = ({ label, ...props }) => {
  const { message } = useTranslations(label);

  return <MaterialChip {...props} label={message} />;
};
