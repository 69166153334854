import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Theme } from '../theme/Theme';
import { ErrorBoundary } from '../errorBoundary/ErrorBoundary';

import { ClientContextController } from 'context/client';
import { AuthContextController } from 'context/auth';
import { LocaleContextController } from 'context/locale';
import { NotificationsProvider } from 'shared/components/notifications/notifications.context';

export const Providers: React.FC = ({ children }) => (
  <Theme>
    <LocaleContextController>
      <Router>
        <ErrorBoundary>
          <AuthContextController>
            <ClientContextController>
              <NotificationsProvider>{children}</NotificationsProvider>
            </ClientContextController>
          </AuthContextController>
        </ErrorBoundary>
      </Router>
    </LocaleContextController>
  </Theme>
);
