import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker as MaterialUIDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { controlBase } from '../controlBase';
import { connectControl } from '../connectControl/connectControl';

import { DateFormat } from 'shared/enums/dateFormat';
import { Input } from 'shared/components/controls/input/Input';
import { DatePickerProps } from './DatePicker.types';

// @ts-ignore
const DateInput = ({ InputProps, ...dateProps }: TextFieldProps) => <Input {...dateProps} {...InputProps} />;

export const DatePickerBase: React.FC<DatePickerProps> = ({
  onChange,
  name,
  id,
  label,
  value,
  dateFormat = DateFormat.Date,
  ...props
}) => {
  const handleOnChange = (date: Date) => {
    if (onChange) {
      onChange(date);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MaterialUIDatePicker
        disableToolbar
        id={id}
        label={label}
        name={name}
        value={value}
        onChange={date => handleOnChange(date as Date)}
        format={dateFormat}
        TextFieldComponent={DateInput}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export const DatePicker = controlBase<DatePickerProps>(DatePickerBase);
export const DatePickerField = connectControl<DatePickerProps>(DatePicker);
