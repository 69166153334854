import { createContext } from 'react';

import { VariantType } from '../../variant/Variant.types';

type DefaultShape = { [key: string]: any } & { accepted: boolean };
type CustomShape = { [key: string]: any } & { accepted: boolean };

export type InitialValuesContextType = {
  default: DefaultShape;
  custom: CustomShape;
  initial: VariantType.custom | VariantType.default;
  hideVariantChange?: boolean;
};

export const InitialValuesContext = createContext<InitialValuesContextType>({
  custom: { accepted: false },
  default: { accepted: false },
  initial: VariantType.none as any,
  hideVariantChange: false,
});
