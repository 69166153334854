import React from 'react';

import { useCollapsibleState } from '../context/useCollapsibleState';

import { Typography } from 'shared/components';
import { isChildrenAsFunction } from 'shared/typesGuard';
import { Header } from './CollapsibleHeader.styles';
import { CollapsibleHeaderProps } from './CollapsibleHeader.types';

export const CollapsibleHeader: React.FC<CollapsibleHeaderProps> = ({ children, disabled }) => {
  const { toggle, open } = useCollapsibleState();

  const handleOnClick = () => {
    if (!disabled) {
      toggle();
    }
  };

  return (
    <Header onClick={handleOnClick} data-testid="collapsible-header">
      <Typography variant="body1">{isChildrenAsFunction(children) ? children({ open }) : children}</Typography>
    </Header>
  );
};
