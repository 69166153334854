import { useState } from 'react';
import { get } from 'lodash';

import { SortDirection, SortType } from '../Table.types';

const parseSortedValue = (rowObject: any, orderBy: string) => {
  const sortedValue = get(rowObject, orderBy);

  return typeof sortedValue === 'string' ? sortedValue.toLowerCase() : sortedValue;
};

const descendingComparator = (previousRow: any, nextRow: any, orderBy: string) => {
  const previousValue = parseSortedValue(previousRow, orderBy);
  const nextValue = parseSortedValue(nextRow, orderBy);

  if (previousValue === nextValue) return 0;

  if (nextValue < previousValue || !nextValue) return -1;

  if (nextValue > previousValue || !previousValue) return 1;

  return 0;
};

const getComparator = (order: SortDirection, orderBy: string) => {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

const getSortedData = (array: any[], comparator: any) => {
  const stabilizedArray = array.map((el: any, index: number) => [el, index]);
  stabilizedArray.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedArray.map((el: any) => el[0]);
};

export const useSort = (sort?: SortType) => {
  const [order, setOrder] = useState<SortDirection>(sort?.direction);
  const [orderBy, setOrderBy] = useState(sort?.column || '');

  const handleSort = (field: string) => {
    const isAsc = orderBy === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(field);
  };

  return {
    order,
    orderBy,
    handleSort,
    getSortedData,
    getComparator,
  };
};
