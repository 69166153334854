import { FORM_ERROR, FormApi } from 'final-form';

import { Config } from './handleOnSubmit.types';

export const handleOnSubmit = <Values = any, SuccessResponse = any, FailureResponse = any>({
  action,
  successCallback,
  failureCallback,
}: Config<Values, SuccessResponse, FailureResponse>) => async (values: Values, formApi: FormApi<Values>) => {
  try {
    const payloadKeys = Object.keys(values);
    const { error, payload } = await action(values, formApi);

    if (error) {
      if (failureCallback) {
        failureCallback(payload, formApi);
      }

      if (payload && payload.errors) {
        const errorKeys = Object.keys(payload.errors);

        if (errorKeys.some(key => payloadKeys.includes(key))) {
          return payload.errors;
        }
      }

      return { [FORM_ERROR]: (payload && payload.message) || 'error.unknown' };
    }

    if (successCallback) {
      successCallback(payload, formApi);
    }

    return undefined;
  } catch (e) {
    return { [FORM_ERROR]: 'error.unknown' };
  }
};
