import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum SurveyQuestionType {
  SHORT_ANSWER = 'short',
  PARAGRAPH = 'long',
  SINGLE_CHOICE = 'single_choice',
  MULTIPLE_CHOICE = 'multiple_choice',
  EMAIL = 'email',
}

export const options = [
  {
    label: <FormattedMessage id="survey_template.question_type.options.short_answer" />,
    value: SurveyQuestionType.SHORT_ANSWER,
  },
  {
    label: <FormattedMessage id="survey_template.question_type.options.paragraph" />,
    value: SurveyQuestionType.PARAGRAPH,
  },
  {
    label: <FormattedMessage id="survey_template.question_type.options.single_choice" />,
    value: SurveyQuestionType.SINGLE_CHOICE,
  },
  {
    label: <FormattedMessage id="survey_template.question_type.options.multiple_choice" />,
    value: SurveyQuestionType.MULTIPLE_CHOICE,
  },
  {
    label: <FormattedMessage id="survey_template.question_type.options.email" />,
    value: SurveyQuestionType.EMAIL,
  },
];
