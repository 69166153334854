import React, { useMemo } from 'react';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { FormControl } from './formControl/FormControl';
import { Label } from './label/Label';
import { Description } from './description/Description';
import { Separator } from 'shared/components/separator/Separator';
import { FormHelperText } from './formHelperText/FormHelperText';
import { FormControlStateProvider } from './provider/ControlBaseContextProvider';
import { withCommonStyles } from './ControlBase.styles';
import { ControlBaseProps } from './ControlBase.types';
import { withBorder } from './withBorder/withBorder';
import { withCommonBehavior } from './withCommonBehavior/withCommonBehavior';

const formControlProps = [
  'classes',
  'color',
  'component',
  'disabled',
  'error',
  'fullWidth',
  'hiddenLabel',
  'margin',
  'required',
  'size',
  'variant',
  'onFocus',
  'onBlur',
];

const controlProps = [...formControlProps, 'hiddenError', 'helperText', 'label'];

export const controlBase = <ControlProps extends any>(RawControl: React.ComponentType<ControlProps>) => {
  type Props = ControlBaseProps & ControlProps;

  // @ts-ignore
  const StyledControl = withCommonStyles(withBorder<ControlProps>(RawControl));
  const Control = withCommonBehavior<ControlProps>(StyledControl);

  return ({ name, fullWidth = true, success, label, helperText, hiddenError, description, ...props }: Props) => {
    const id = useMemo(() => name || Date.now().toString(), [name]);

    const contextValues = {
      success,
      name,
      id,
      hiddenError,
    };

    return (
      <FormControl {...pick(props, formControlProps)} fullWidth={fullWidth}>
        <FormControlStateProvider values={contextValues}>
          <Label>{label}</Label>
          {!!description && (
            <>
              <Description>{description}</Description>
              <Separator height={8} />
            </>
          )}
          <Control {...(omit({ id, ...props }, controlProps) as ControlProps)} />
          <FormHelperText>{helperText || <span>&nbsp;</span>}</FormHelperText>
        </FormControlStateProvider>
      </FormControl>
    );
  };
};
