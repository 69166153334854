import { useMemo } from 'react';

import { useLocale } from 'shared/hooks';
import { prettify } from './prettify/prettify';
import { Translation } from './useTranslations.types';

const isTranslationString = (value: any): value is string => {
  if (typeof value === 'string') {
    return /^([a-z0-9_]+\.)([a-z0-9_]+\.?)+[^.]$/.test(value);
  }

  return false;
};

const isString = (translated: any): translated is string => typeof translated === 'string';

export const useTranslations = (value: Translation, markup = true) => {
  const { formatMessage } = useLocale();

  return useMemo(() => {
    if (isTranslationString(value)) {
      const translated = formatMessage({ id: value });

      return { message: markup && isString(translated) ? prettify(translated) : translated };
    }

    return { message: value };
  }, [value, formatMessage, markup]);
};
