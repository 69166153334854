import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  fullHeight: {
    height: '100%',
  },
  disableVerticalMargins: {
    marginTop: 0,
    marginBottom: 0,
  },
});
