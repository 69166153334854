import React, { useState, useEffect } from 'react';
import { Collapse, Fade } from '@material-ui/core';

import { Typography } from '../typography/Typography';
import { Separator } from '../separator/Separator';
import { Skeleton } from '../skeleton/Skeleton';
import { Collapsible } from '../collapsible/Collapsible';

import { PageSectionProps } from './PageSection.types';

export const PageSectionTitle: React.FC = ({ children }) => {
  if (typeof children === 'string') {
    return (
      <>
        <Typography variant="h3">{children}</Typography>
        <Separator height={20} />
      </>
    );
  }

  return (
    <>
      {children}
      <Separator height={20} />
    </>
  );
};

export const PageSectionBody: React.FC = ({ children }) => {
  return <Collapsible.Body>{children}</Collapsible.Body>;
};

export const PageSectionFooter: React.FC = ({ children }) => {
  return <>{children}</>;
};

export const PageSectionBodyCollapsed: React.FC = ({ children }) => {
  return (
    <Collapsible.Header>
      {({ open }) => (
        <>
          <Collapse in={!open}>{children}</Collapse>
        </>
      )}
    </Collapsible.Header>
  );
};

export const PageSection: React.FC<PageSectionProps> & {
  Title: typeof PageSectionTitle;
  Body: typeof PageSectionBody;
  BodyCollapsed: typeof PageSectionBodyCollapsed;
  Footer: typeof PageSectionFooter;
} = ({ title: propsTitle, loading, children, open: isOpen = true, onChange }) => {
  const [open, setOpen] = useState(isOpen);

  const handleCollapsibleChange = (nextOpen: boolean) => {
    if (open !== nextOpen) {
      setOpen(nextOpen);

      if (onChange) {
        onChange(nextOpen);
      }
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  if (loading) {
    return (
      <>
        <PageSectionTitle>{propsTitle}</PageSectionTitle>
        <Collapsible>
          <Skeleton />
        </Collapsible>
      </>
    );
  }

  const childrenAsArray = React.Children.toArray(children);
  const title =
    propsTitle ||
    childrenAsArray.find(child => {
      if (React.isValidElement(child)) {
        return child.type === PageSectionFooter;
      }

      return false;
    });

  const body = childrenAsArray.filter(child => {
    if (React.isValidElement(child)) {
      return child.type === PageSectionBody || child.type === PageSectionBodyCollapsed;
    }

    return true;
  });

  const footer = childrenAsArray.find(child => {
    if (React.isValidElement(child)) {
      return child.type === PageSectionFooter;
    }

    return false;
  });

  return (
    <>
      {!!title && <PageSectionTitle>{title}</PageSectionTitle>}
      <Collapsible open={open} onChange={handleCollapsibleChange}>
        {body}
      </Collapsible>
      {footer && (
        <Fade in={open}>
          <div>{footer}</div>
        </Fade>
      )}
    </>
  );
};

PageSection.Title = PageSectionTitle;
PageSection.Body = PageSectionBody;
PageSection.BodyCollapsed = PageSectionBodyCollapsed;
PageSection.Footer = PageSectionFooter;
