import React, { useState, useEffect } from 'react';

import { Image } from '../image/Image';

import { BlobImageProps } from './BlobImage.types';

const hash = (file: File) => `${file.name}:${file.type}:${file.size}`;

export const BlobImage = ({ src, ...props }: BlobImageProps) => {
  const [blob, setBlob] = useState();

  useEffect(() => {
    setBlob(URL.createObjectURL(src));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(src)]);

  return <Image {...props} src={blob} />;
};
