import React from 'react';
import Box from '@material-ui/core/Box';

import { GridContainer, GridItem } from 'shared/components/grid';
import { Typography } from 'shared/components';
import { PageSection } from 'shared/components/pageSectionStatic/PageSection';
import { EventParticipantsStatsProps, SummaryStats } from './EventParticipantsStats.types';
import { Participant } from 'api/actions/events/eventsActions.types';

const getSummary = (data: Participant[]): SummaryStats =>
  data.reduce(
    (prev: SummaryStats, participant: Participant) => {
      if (participant.virtualGraduation) {
        return {
          ...prev,
          [participant.virtualGraduation.state]: (prev[participant.virtualGraduation.state] || 0) + 1,
        };
      }

      return prev;
    },
    { new: 0, invited: 0, submitted: 0, rejected: 0, accepted: 0 },
  );

export const EventParticipantsStats = ({ data }: EventParticipantsStatsProps) => {
  const summary = getSummary(data);
  const stats = [
    { name: 'event_details.stats.all', value: `${data.length}` },
    { name: 'event_details.stats.awaiting', value: summary.new + summary.invited },
    { name: 'event_details.stats.submitted', value: summary.submitted },
    { name: 'event_details.stats.rejected', value: summary.rejected },
    { name: 'event_details.stats.accepted', value: summary.accepted },
  ];

  return (
    <GridContainer>
      <GridItem xs={12} md={6} lg={5}>
        <PageSection title="event_details.stats.title">
          <PageSection.Body>
            {stats.map(stat => (
              <Box key={`${stat.name}-${stat.value}`} display="flex">
                <Box flexGrow={1}>
                  <Typography variant="subtitle2">{stat.name}</Typography>
                </Box>
                <Box>{stat.value}</Box>
              </Box>
            ))}
          </PageSection.Body>
        </PageSection>
      </GridItem>
    </GridContainer>
  );
};
