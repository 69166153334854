import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-fetching-library';
import { useLocation, Redirect } from 'react-router-dom';
import { parse } from 'query-string';

import { SetPasswordSuccess } from '../SetPasswordSuccess/SetPasswordSuccess';

import { useAuthDispatch } from 'shared/hooks/useAuthDispatch/useAuthDispatch';
import { setTokens, startAuthorizing } from 'context/auth';
import { createSetPasswordAction, createVerifyResetToken } from 'api/actions/auth/authActions';
import { SetPasswordForm } from './SetPasswordForm';
import { handleOnSubmit } from 'shared/utils/handleOnSubmit/handleOnSubmit';
import { SetPasswordFormValues } from 'api/actions/auth/auth.types';

export const SetPasswordFormContainer: React.FC = () => {
  const [submitSucceeded, setSubmitSucceeded] = useState(false);
  const { mutate, payload } = useMutation(createSetPasswordAction);
  const { search } = useLocation();
  const { token } = parse(search) as { token?: string };
  const authDispatch = useAuthDispatch();
  const { payload: tokenIsActive } = useQuery(createVerifyResetToken(token), true);

  if (!token || tokenIsActive === false) {
    return <Redirect to={{ pathname: '/login', state: { error: 'error.reset_password_token_invalid' } }} />;
  }

  const handleSubmit = handleOnSubmit<SetPasswordFormValues>({
    action: mutate,
    successCallback: () => {
      setSubmitSucceeded(true);
    },
  });

  const handleLogin = () => {
    authDispatch(startAuthorizing());
    authDispatch(setTokens({ accessToken: payload.sessionToken, refreshToken: payload.refreshToken }));
  };

  if (submitSucceeded) {
    return <SetPasswordSuccess login={handleLogin} />;
  }

  return <SetPasswordForm onSubmit={handleSubmit} initialValues={{ token }} />;
};
