import React, { memo } from 'react';
import { IconButton } from '@material-ui/core';

import { QuestionRenderProps } from '../base/BaseQuestion.types';

import { url } from 'shared/validators';
import { ConnectedTextField } from 'shared/components/controls';
import { InputAdornment } from 'shared/components/controls/inputAdornment/InputAdornment';
import { Icon } from 'shared/components/icon/Icon';
import { Tooltip } from 'shared/components/tooltip/Tooltip';

export const SurveyEmailExcludedDomain: React.FC<QuestionRenderProps> = memo(({ field }) => {
  return (
    <ConnectedTextField
      name={`${field}.excludedDomain`}
      placeholder="survey_template.email.excluded_domain.placeholder"
      validate={[url({ protocolIdentifier: false })]}
      maxLength={100}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="survey_template.email.excluded_domain.tooltip" arrow>
              <IconButton>
                <Icon icon="InfoOutlined" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
});
