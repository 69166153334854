import React from 'react';
import { Link } from '@material-ui/core';

import { MailLinkProps } from './MailLink.types';

export const MailLink: React.FC<MailLinkProps> = ({ children, mailto, textColor }) => (
  <Link href={`mailto:${mailto}`} style={{ color: textColor }}>
    {children}
  </Link>
);
