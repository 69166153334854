import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  root: {
    minHeight: '100vh',
  },
  left: {
    background: palette.black.main,
  },
  sidebarWrapper: {
    background: palette.black.main,
  },
  sidebarContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: '64px 0',
  },
}));
