import React, { memo } from 'react';

import { QuestionRenderProps } from '../base/BaseQuestion.types';

import { required } from 'shared/validators';
import { ConnectedTextField, TextField } from 'shared/components/controls';

export const SurveyEmail: React.FC<QuestionRenderProps> = memo(({ field }) => {
  return (
    <>
      <ConnectedTextField
        name={`${field}.question`}
        placeholder="survey_template.email.question"
        validate={[required()]}
        maxLength={250}
      />
      <TextField placeholder="survey_template.email.answer" disabled />
    </>
  );
});
