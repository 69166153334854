import { useEffect } from 'react';

import { usePushNotification } from 'shared/components/notifications/notifications.context';
import { PushNotification } from 'shared/components/notifications/Notifications.types';
import { API_ERROR_EVENT } from 'shared/utils/handleRequest/apiErrorEvent';

const events = [API_ERROR_EVENT];

export const NotificationEventHandler = () => {
  const push = usePushNotification();

  const handleEvent = ({ detail }: CustomEvent<PushNotification>) => {
    push(detail);
  };

  useEffect(() => {
    events.forEach(event => window.addEventListener(event, handleEvent as EventListener));

    return () => {
      events.forEach(event => window.removeEventListener(event, handleEvent as EventListener));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
