import { Action } from 'react-fetching-library';

import { GenerateReportAction } from './client.types';

export const fetchClientDefaults: Action = {
  method: 'GET',
  endpoint: '/client-portal/client/defaults',
};

export const createGenerateReport: GenerateReportAction = () => body => {
  return {
    method: 'POST',
    endpoint: `/client-portal/client/client-metrics`,
    responseType: 'blob',
    body,
  };
};
