import React from 'react';

import { Body } from './DialogBody.styles';
import { DialogBodyProps } from './DialogBody.types';

export const DialogBody: React.FC<DialogBodyProps> = ({ children, className }) => (
  <Body data-testid="dialog-content" className={className}>
    {children}
  </Body>
);
