import { styled, withStyles } from '@material-ui/core';

import { Paper } from 'shared/components';
import { ConnectedTextField } from 'shared/components/controls';

export const StyledPaper = styled(Paper)({
  overflow: 'hidden',
});

export const StyledWrapper = styled('div')(({ theme: { spacing } }) => ({
  padding: `${spacing(8)}px ${spacing(8)}px ${spacing(5)}px`,
}));

export const StyledDetailsField = withStyles({
  root: {
    '& textarea': {
      textAlign: 'center',
    },
  },
})(ConnectedTextField);
