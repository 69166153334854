import React from 'react';
import { AlertProps } from '@material-ui/lab';

import { Icon } from '../icon/Icon';

import { useTranslations } from 'shared/hooks';
import { StyledAlert } from './Alert.styles';

export const Alert: React.FC<AlertProps> = ({ children, iconMapping = {}, ...props }) => {
  const { message } = useTranslations(children);

  return (
    <StyledAlert
      {...props}
      iconMapping={{
        success: <Icon icon="CheckCircle" />,
        error: <Icon icon="Error" />,
        warning: <Icon icon="Error" />,
        ...iconMapping,
      }}
    >
      {message}
    </StyledAlert>
  );
};
