import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import cookies from 'js-cookie';

import { useAuthDispatch } from 'shared/hooks';
import { clearTokens } from 'context/auth/authState/actions/authStateActions';

export const LogoutContainer: React.FC = () => {
  const dispatch = useAuthDispatch();

  useEffect(() => {
    dispatch(clearTokens());
    cookies.remove('accessToken');
    cookies.remove('refreshToken');
  }, [dispatch]);

  return <Redirect to="/login" />;
};
