import Validators from 'redux-form-validators';

export const minValue = (min: number) => (value: string) =>
  Number(value) < min
    ? Validators.formatMessage({
        id: `validation.number.min`,
        values: { value: min },
      })
    : undefined;

export const integer = (value: string) => {
  const valueToFloat = parseFloat(value);

  return valueToFloat && Math.floor(valueToFloat) !== valueToFloat
    ? Validators.formatMessage({ id: `validation.number.integer` })
    : undefined;
};
