import React from 'react';

import { ClientDefaultsController } from 'context/clientDefaults/clientDefaultsController';
import { Dashboard } from './Dashboard';

export const DashboardContainer: React.FC = () => {
  // TODO: Remove ClientDefaultsController from components
  return (
    <ClientDefaultsController>
      <Dashboard />
    </ClientDefaultsController>
  );
};
