import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import { Form, Separator } from 'shared/components';
import { length } from 'shared/validators';
import { SurveyActionBarContainer } from './actionBar/SurveyActionBarContainer';
import { SurveyQuestionContainer } from './question/SurveyQuestionContainer';
import { SurveyBodyProps } from './SurveyBody.types';
import { SurveyDetails } from './details/SurveyDetails';

export const SurveyBody: React.FC<SurveyBodyProps> = ({ initialValues, onSubmit }) => {
  const accepted = !!initialValues?.survey;

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      destroyOnUnregister
      render={({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <SurveyDetails />
          <FieldArray
            name="survey.questions"
            validate={length({ min: 1, message: 'survey_template.action_bar.accept_hover' })}
          >
            {({ fields }) => (
              <>
                {fields.map((name, index) => (
                  <SurveyQuestionContainer key={name} name={name} fields={fields} index={index} />
                ))}
                <Separator height={42} />
                <SurveyActionBarContainer accepted={accepted && !dirty} fields={fields} />
              </>
            )}
          </FieldArray>
        </form>
      )}
    />
  );
};
