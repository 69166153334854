import React from 'react';
import { useField } from 'react-final-form';

import { SectionType } from '../ClipTemplate.types';

import { PreviewAudio } from './audio/PreviewAudio';
import { PreviewRegular } from './regular/PreviewRegular';
import { PreviewOverlay } from './overlay/PreviewOverlay';
import { Wrapper } from './Preview.styles';

export const Preview: React.FC = () => {
  const {
    input: { value: type },
  } = useField('type', { subscription: { value: true } });

  switch (type) {
    case SectionType.audio:
      return <PreviewAudio />;
    case SectionType.regular:
      return <PreviewRegular />;
    case SectionType.overlay:
      return <PreviewOverlay />;
    default:
      return <Wrapper data-testid="preview-placeholder" />;
  }
};
