import React from 'react';
import { useField } from 'react-final-form';

import { BlobImage } from 'shared/components';
import { BlobImageProps } from 'shared/components/blobImage/BlobImage.types';
import { PreviewText, PreviewTextWrapper, PreviewTextTypo, useStyles } from './ImagePreview.styles';

export const ImagePreview = (props: BlobImageProps) => {
  const {
    input: { value: color },
  } = useField('color');

  const {
    input: { value: fonts = [] },
  } = useField('fonts', { subscription: { value: true } });

  const {
    input: { value: font },
  } = useField('font', { subscription: { value: true } });

  const fontFamily = (fonts.find(({ url }) => url === font) || { name: '' }).name;

  const styles = useStyles({ fontFamily, color });

  return (
    <PreviewTextWrapper>
      <PreviewText>
        <PreviewTextTypo className={styles.preview}>clip_template.overlay.image_text.name</PreviewTextTypo>
        <PreviewTextTypo className={styles.preview}>clip_template.overlay.image_text.subtitle</PreviewTextTypo>
      </PreviewText>
      <BlobImage {...props} />
    </PreviewTextWrapper>
  );
};
