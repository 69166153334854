import React from 'react';

import { Footer } from '../section/Section.styles';

import { PageTemplate } from 'shared/templates';
import { GridContainer, GridItem, Separator, Skeleton } from 'shared/components';
import { PageSection } from 'shared/components/pageSection/PageSection';

export const EmailTemplatePlaceholder = () => {
  return (
    <PageTemplate title="email_template.page.title">
      <GridContainer>
        <GridItem xs={6}>
          <PageSection title="email_template.sections.styling">
            <PageSection.Body>
              <Skeleton />
              <Skeleton />
            </PageSection.Body>
            <PageSection.Footer>
              <Footer>
                <Skeleton width={50} />
              </Footer>
            </PageSection.Footer>
          </PageSection>
          <Separator height={30} />
          <PageSection title="email_template.sections.content">
            <PageSection.Body>
              <Skeleton />
              <Skeleton />
            </PageSection.Body>
            <PageSection.Footer>
              <Footer>
                <Skeleton width={50} />
              </Footer>
            </PageSection.Footer>
          </PageSection>
        </GridItem>
        <GridItem xs={6}>
          <PageSection title="email_template.sections.preview">
            <PageSection.Body>
              <Skeleton height={220} variant="rect" />
              <Separator height={40} />
              <Skeleton width="50%" />
              <Separator height={40} />
              <Skeleton />
              <Separator height={30} />
              <Skeleton width="50%" />
              <Separator height={40} />
              <Skeleton />
              <Separator height={50} />
              <Skeleton width="50%" />
              <Separator height={50} />
            </PageSection.Body>
          </PageSection>
        </GridItem>
      </GridContainer>
    </PageTemplate>
  );
};
