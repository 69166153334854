import React, { useEffect, useRef } from 'react';

import { Collapsible } from 'shared/components';
import { Content } from './SectionBody.styles';
import { useCollapsibleState } from 'shared/components/collapsible/context/useCollapsibleState';

export const SectionBody: React.FC = ({ children }) => {
  const { open } = useCollapsibleState();
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  // pause and reset any media when section is closed
  useEffect(() => {
    if (!open && wrapperRef.current) {
      const element = wrapperRef.current.querySelector('video') || document.querySelector('audio');

      if (element && !element.paused) {
        element.pause();
        element.currentTime = 0;
      }
    }
  }, [open]);

  return (
    <div ref={wrapperRef}>
      <Collapsible.Body>
        <Content>{children}</Content>
      </Collapsible.Body>
    </div>
  );
};
