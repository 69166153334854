import { Action } from 'api/types';

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export type Client = {
  id: string;
  name: string;
};

export type Token = {
  id: string;
  name: string;
  role: Role;
  iat: number;
  exp: number;
  refreshToken: string;
  clipTemplateEditor: boolean;
  emailTemplateEditor: boolean;
  surveyTemplateEditor: boolean;
  client: Client;
};

export type LoginFormValues = {
  email: string;
  password: string;
};

export type RefreshTokenResponse = {
  sessionToken: string;
  refreshToken: string;
};

export type ResetPasswordBody = { email: string };
export type CreateResetPasswordAction = (values: ResetPasswordBody) => Action;

export type SetPasswordFormValues = {
  token: string;
  password: string;
  repeat_password: string;
};
export type CreateSetPasswordAction = (values: SetPasswordFormValues) => Action;
export type CreateVerifyResetToken = (token: string | undefined) => Action;
