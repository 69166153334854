import React from 'react';
import { useField } from 'react-final-form';

import { Variant } from '../variant/Variant';
import { VariantType } from '../variant/Variant.types';
import { CustomTemplate } from '../customTemplate/CustomTemplate';

import { ItemProps } from 'shared/components/specification/item/Item.types';
import { AudioDefaultTemplate } from './default/AudioDefaultTemplate';

const allowedExtensions = ['mp3', 'wav'];

export const rules: ItemProps[] = [
  { icon: 'InsertDriveFile', label: 'clip_template.specification.file_type', content: allowedExtensions.join(', ') },
  { icon: 'PhotoSizeSelectSmall', label: 'clip_template.specification.max_size', content: '50MB' },
];

export const AudioTemplate: React.FC = () => {
  const {
    input: { value: variant },
  } = useField('variant', { subscription: { value: true } });

  switch (variant) {
    case VariantType.default:
      return <AudioDefaultTemplate />;

    case VariantType.custom:
      return (
        <CustomTemplate
          info="clip_template.audio.info"
          specificationRules={rules}
          allowedExtensions={allowedExtensions}
          fileDropZoneProps={{ maxSize: '50 MB' }}
        />
      );

    default:
      return <Variant />;
  }
};
