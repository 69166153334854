import React, { useState, useEffect } from 'react';
import { useFormState, FormSpy } from 'react-final-form';

import { updateFormStorage } from '../EmailTemplate.storage';

import { EmailTemplateData } from 'api/types';
import { Button, Icon } from 'shared/components';
import { Footer, StyledAlert } from './Section.styles';
import { SectionProps } from './Section.types';
import { PageSection } from 'shared/components/pageSection/PageSection';

export const Section: React.FC<SectionProps> = ({ children, section, title, handleSubmit, accepted }) => {
  const { submitSucceeded, dirty } = useFormState({ subscription: { submitSucceeded: true, dirty: true } });
  const update = updateFormStorage();
  const [isOpen, setIsOpen] = useState(!accepted);

  useEffect(() => {
    if (submitSucceeded) {
      setIsOpen(false);
    }
  }, [submitSucceeded]);

  const submitButtonProps = accepted ? { disabled: !dirty } : { disabled: false };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageSection title={title} open={isOpen} onChange={setIsOpen}>
          <PageSection.BodyCollapsed>
            <StyledAlert severity="success" action={<Icon icon="Edit" />}>
              email_template.section.success_msg
            </StyledAlert>
          </PageSection.BodyCollapsed>
          <PageSection.Body>{children}</PageSection.Body>
          <PageSection.Footer>
            <Footer>
              {/* {dirty && ( */}
              {/*  <Button */}
              {/*    color="primary" */}
              {/*    variant="outlined" */}
              {/*    loading={submitting} */}
              {/*    className="ml-auto" */}
              {/*    onClick={() => reset()} */}
              {/*  > */}
              {/*    email_template.section.reset */}
              {/*  </Button> */}
              {/* )} */}

              <Button type="submit" color="primary" variant="contained" {...submitButtonProps}>
                email_template.section.accept
              </Button>
            </Footer>
          </PageSection.Footer>
        </PageSection>
      </form>
      <FormSpy<EmailTemplateData>
        // @ts-ignore
        onChange={({ values }) => update(prev => ({ ...(prev || {}), [section]: { ...(values[section] || {}) } }))}
        subscription={{ values: true }}
      />
    </>
  );
};
