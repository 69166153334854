import { withStyles, styled } from '@material-ui/core';

import { Dialog } from 'shared/components';
import { CheckboxField } from 'shared/components/controls';

export const Body = styled(Dialog.Body)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 'auto 60px',
});

export const StyledCheckbox = withStyles(theme => ({
  root: {
    marginRight: 'auto',
  },
  label: {
    ...theme.typography.caption,
    opacity: 0.6,
  },
}))(CheckboxField);
