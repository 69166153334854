import { Dispatch } from 'react';
import { ResponseInterceptor, QueryResponse } from 'react-fetching-library';

import { createRefreshTokenAction } from 'api/actions/auth/authActions';
import { RefreshTokenResponse } from 'api/types';
import { Actions, User } from 'context/auth/authState/actions/authStateActions.types';
import { setUnAuthorized, setTokens } from 'context/auth/authState/actions/authStateActions';

type responseRefreshTokenInterceptorType = (
  refreshToken: string,
  user: User | null,
  authDispatch: Dispatch<Actions>,
) => ResponseInterceptor;

let refreshPromise: null | Promise<QueryResponse<RefreshTokenResponse>> = null;

export const responseRefreshTokenInterceptor: responseRefreshTokenInterceptorType = (
  refreshToken,
  user,
  authDispatch,
) => client => async (action, response) => {
  if (!user || action.skipAuth || action.skipRefreshToken) {
    return response;
  }

  if (response.status === 401) {
    if (!refreshPromise) {
      refreshPromise = client
        .query<RefreshTokenResponse>(createRefreshTokenAction(refreshToken, user.id))
        .then(refreshResponse => {
          if (refreshResponse.error || !refreshResponse.payload) {
            authDispatch(setUnAuthorized());

            refreshPromise = null;

            return refreshResponse;
          }

          const { sessionToken, refreshToken: newRefreshToken } = refreshResponse.payload;

          authDispatch(
            setTokens({
              accessToken: sessionToken,
              refreshToken: newRefreshToken,
            }),
          );

          refreshPromise = null;

          return refreshResponse;
        });
    }

    const refreshResponse = await refreshPromise;

    if (refreshResponse.error || !refreshResponse.payload) {
      return response;
    }

    const { sessionToken } = refreshResponse.payload;

    return client.query({
      ...action,
      skipAuth: true,
      headers: {
        ...action.headers,
        Authorization: `Bearer ${sessionToken}`,
      },
    });
  }

  return response;
};
