import { styled } from '@material-ui/core';

import { Image } from 'shared/components/image/Image';

export const Center = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const Wrapper = styled('div')({
  alignItems: 'center',
  background: props => props.color,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  position: 'relative',
});

export const Logo = styled(Image)({
  width: 'auto',
  height: 'auto',
  position: 'relative',
  top: '-8%',
});

export const Text = styled('div')({
  fontFamily: props => props.font || 'inherit',
  color: props => props.color,
  position: 'absolute',
  bottom: `18.5%`,
  fontSize: 20,
});
