import React, { ElementType, memo } from 'react';

import { SurveyQuestionType } from '../SurveyQuestion.constants';
import { SurveyShortAnswer } from '../shortAnswer/SurveyShortAnswer';
import { SurveyParagraph } from '../paragraph/SurveyParagraph';
import { SurveySingleChoice } from '../singleChoice/SurveySingleChoice';
import { SurveyMultipleChoice } from '../multipleChoice/SurveyMultipleChoice';
import { SurveyEmail } from '../email/SurveyEmail';

import { BaseQuestionProps } from './BaseQuestion.types';

const QUESTION_TYPES: Record<string, ElementType> = {
  [SurveyQuestionType.SHORT_ANSWER]: SurveyShortAnswer,
  [SurveyQuestionType.PARAGRAPH]: SurveyParagraph,
  [SurveyQuestionType.SINGLE_CHOICE]: SurveySingleChoice,
  [SurveyQuestionType.MULTIPLE_CHOICE]: SurveyMultipleChoice,
  [SurveyQuestionType.EMAIL]: SurveyEmail,
};

export const BaseQuestion: React.FC<BaseQuestionProps> = memo(({ field, input: { value } }) => {
  if (value) {
    const Question = QUESTION_TYPES[value];

    return <Question field={field} />;
  }

  return null;
});
