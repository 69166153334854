import { Sections, SectionType } from '../../ClipTemplate.types';
import { VariantType } from '../../variant/Variant.types';
import { Font, getFonts } from '../common';

import { ClientDefaultsData, ClipTemplateIntroSectionCustom, ClipTemplateIntroSectionRegular } from 'api/types';

const createDefault = (values: ClipTemplateIntroSectionRegular & { fonts: Font[] }, defaults: ClientDefaultsData) => {
  const {
    header = {
      color: undefined,
      font: undefined,
      text: undefined,
    },
  } = values;

  return {
    accepted: values.accepted,
    type: SectionType.regular,
    logo: values.logo || defaults.logo,
    background: values.background || defaults.colors.secondary,
    header: {
      color: header.color || defaults.colors.primary,
      font: header.font || values.fonts[0].url,
      text: header.text || 'graduation',
    },
    fonts: values.fonts,
    variant: VariantType.none,
    ...(values.accepted ? { variant: VariantType.default } : {}),
  };
};

const createCustom = (values: ClipTemplateIntroSectionCustom) => ({
  accepted: values.accepted,
  file: values.file,
  type: SectionType.overlay,
  variant: VariantType.none,
  ...(values.accepted ? { variant: VariantType.custom } : {}),
});

export const getIntroInitialValues: Sections['intro']['getInitialValues'] = (data, defaults) => {
  const values = {
    ...data,
    fonts: getFonts(data),
  };

  return {
    // @ts-ignore
    [VariantType.default]: createDefault(values, defaults),
    [VariantType.custom]: createCustom(data as ClipTemplateIntroSectionCustom),
    initial: 'file' in data ? VariantType.custom : VariantType.default,
  };
};
