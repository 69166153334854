import React, { memo, useCallback, useRef } from 'react';
import { IconButton } from '@material-ui/core';

import { SurveyChoiceItem } from '../item/SurveyChoiceItem';

import { Icon } from 'shared/components';
import { ConnectedTextField } from 'shared/components/controls';
import { GridItem } from 'shared/components/grid';
import { SurveyOptionProps } from './SurveyOption.types';
import { StyledGridItem } from './SurveyOption.styles';

export const SurveyOption: React.FC<SurveyOptionProps> = memo(({ field, icon, isDeletable, onDelete }) => {
  const inputRef = useRef<HTMLInputElement>();

  const handleFocus = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  }, []);

  return (
    <SurveyChoiceItem icon={icon}>
      <GridItem lg={5}>
        <ConnectedTextField name={field} inputRef={inputRef} onFocus={handleFocus} maxLength={250} />
      </GridItem>
      {isDeletable && (
        <StyledGridItem>
          <IconButton type="button" onClick={onDelete}>
            <Icon icon="Close" />
          </IconButton>
        </StyledGridItem>
      )}
    </SurveyChoiceItem>
  );
});
