import React from 'react';
import { useForm } from 'react-final-form';

import { Preview } from '../preview/Preview';
import { useInitialValues } from '../initialValues/useInitialValues/useInitialValues';

import editIcon from 'assets/icons/edit.svg';
import uploadIcon from 'assets/icons/upload.svg';
import { Image } from 'shared/components';
import {
  Buttons,
  TemplateButton,
  TemplateButtonsLabels,
  TemplateButtonTitle,
  TemplateButtonSubtitle,
  TemplateButtonSeparator,
} from './Variant.styles';
import { VariantType } from './Variant.types';
import { Typography } from 'shared/components/typography/Typography';

export const Variant: React.FC = () => {
  const { reset } = useForm();
  const initialValues = useInitialValues();

  const handleVariantChange = (variant: VariantType.default | VariantType.custom) => {
    reset({
      ...initialValues[variant],
      variant,
    });
  };

  return (
    <>
      <Preview />
      <Buttons>
        <TemplateButton variant="outlined" color="primary" onClick={() => handleVariantChange(VariantType.default)}>
          <Image src={editIcon} alt="clip_template.variant.edit.title" />
          <TemplateButtonsLabels>
            <TemplateButtonTitle>clip_template.variant.edit.title</TemplateButtonTitle>
            <TemplateButtonSubtitle>clip_template.variant.edit.desc</TemplateButtonSubtitle>
          </TemplateButtonsLabels>
        </TemplateButton>
        <TemplateButtonSeparator>
          <Typography variant="body2">clip_template.variant.separator</Typography>
        </TemplateButtonSeparator>
        <TemplateButton variant="outlined" color="primary" onClick={() => handleVariantChange(VariantType.custom)}>
          <Image src={uploadIcon} alt="clip_template.variant.upload.title" />
          <TemplateButtonsLabels>
            <TemplateButtonTitle>clip_template.variant.upload.title</TemplateButtonTitle>
            <TemplateButtonSubtitle>clip_template.variant.upload.desc</TemplateButtonSubtitle>
          </TemplateButtonsLabels>
        </TemplateButton>
      </Buttons>
    </>
  );
};
