import React from 'react';
import { Color } from '@material-ui/lab';

import { Separator, Typography } from 'shared/components';
import { WelcomeDialog } from 'shared/components/welcomeDialog/WelcomeDialog';
import { PageTemplate } from 'shared/templates';
import { SurveyBody } from './body/SurveyBody';
import { SurveyStyleContainer } from './style/SurveyStyleContainer';
import { SurveyTemplateProps } from './SurveyTemplate.types';

export const SurveyTemplate: React.FC<SurveyTemplateProps> = ({ accepted, initialValues, loading, onSubmit }) => {
  const message = loading
    ? undefined
    : {
        content: accepted ? 'survey_template.message.all_accepted' : 'survey_template.message.not_all_accepted',
        color: (accepted ? 'success' : 'warning') as Color,
      };

  return (
    <>
      <PageTemplate title="survey_template.page.title" subtitle="survey_template.page.subtitle" message={message}>
        <>
          <Typography variant="h3">survey_template.sections.style</Typography>
          <Separator height={8} />
          {loading || <SurveyStyleContainer initialValues={initialValues} onSubmit={onSubmit} />}
          <Separator height={32} />
          <Typography variant="h3">survey_template.sections.survey</Typography>
          <Separator height={8} />
          {loading || <SurveyBody initialValues={initialValues} onSubmit={onSubmit} />}
        </>
      </PageTemplate>
      <WelcomeDialog
        title="survey_template.welcome.title"
        body="survey_template.welcome.body"
        storageKey="survey_template_welcome_dialog"
      />
    </>
  );
};
