import React from 'react';
import { useQuery } from 'react-fetching-library';

import { createGetParticipantAuditLog } from 'api/actions/participants/participantsActions';
import { ParticipantAuditLogContainerProps } from './ParticipantAuditLog.types';
import { ParticipantAuditLogDialog } from './ParticipantAuditLogDialog';

export const ParticipantAuditLogContainer = ({ open, onClose, data }: ParticipantAuditLogContainerProps) => {
  const { payload } = useQuery(createGetParticipantAuditLog(data?.id || ''));

  return (
    <ParticipantAuditLogDialog
      data={payload}
      open={open}
      participantFullName={data?.fullName || ''}
      onClose={onClose}
    />
  );
};
