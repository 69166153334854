import React from 'react';

import { Specification } from 'shared/components/specification/Specification';
import { Separator } from 'shared/components/separator/Separator';
import { FileDropZoneField } from 'shared/components/controls';
import { CustomTemplateProps } from './CustomTemplate.types';
import { Info } from './CustomTemplate.styles';

export const CustomTemplate = ({
  info,
  specificationRules,
  allowedExtensions,
  fileDropZoneProps,
  previews,
  specificationTitle = 'clip_template.specification.title',
}: CustomTemplateProps) => {
  return (
    <>
      <FileDropZoneField
        name="file"
        accept={allowedExtensions}
        {...fileDropZoneProps}
        previews={previews}
        ratio={16 / 9}
      />
      <div>
        <Specification options={specificationRules} title={specificationTitle} />
        <Separator height={40} />
        {!!info && <Info variant="caption">{info}</Info>}
      </div>
    </>
  );
};
