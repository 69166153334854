import React from 'react';
import { Collapse } from '@material-ui/core';

import { useCollapsibleState } from '../context/useCollapsibleState';

export const CollapsibleBody: React.FC = ({ children }) => {
  const { open } = useCollapsibleState();

  return (
    <Collapse in={open}>
      <div data-testid="collapsible-body">{children}</div>
    </Collapse>
  );
};
