import { makeStyles } from '@material-ui/core';

import { bold } from 'app/core/theme/typography';

export const useStyles = makeStyles({
  wrapper: {
    padding: '14px 18px',
  },
  title: {
    fontSize: 16,
    fontWeight: bold,
  },
});
