import { Sections, SectionType } from '../../ClipTemplate.types';
import { VariantType } from '../../variant/Variant.types';
import { getFileName, isArrayString } from '../common';

import { Playlist } from 'shared/components/player/Player.types';
import { ClipTemplateAudioSectionData } from 'api/types';

const getSongs = (values: ClipTemplateAudioSectionData & { songs?: string[] | Playlist }) => {
  const songs = values.songs && values.songs[0] ? values.songs : (process.env.REACT_APP_SONGS || '').split(';');

  if (!isArrayString(songs)) {
    return songs;
  }

  return songs.map((src: string, index) => ({
    src,
    title: getFileName(src) || `Song ${index + 1}`,
    subtitle: 'clip_template.audio.default_music',
  }));
};

export const getAudioInitialValues: Sections['audio']['getInitialValues'] = data => {
  const values = {
    ...data,
    songs: getSongs(data),
  };

  const isInSongs = values.songs.find(({ src }) => src === values.file);

  return {
    [VariantType.default]: {
      ...values,
      type: SectionType.audio,
      variant: VariantType.none,
      ...(values.accepted ? { variant: VariantType.default } : {}),
    },
    [VariantType.custom]: {
      ...values,
      file: isInSongs ? undefined : values.file,
      type: SectionType.audio,
      variant: VariantType.none,
      ...(values.accepted ? { variant: VariantType.custom } : {}),
    },
    initial: isInSongs ? VariantType.default : VariantType.custom,
  };
};
