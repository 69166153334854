import React, { Component, ReactElement } from 'react';

import { ErrorPage } from '../errorPage/ErrorPage';

import { withScope, captureException } from 'shared/utils/sentry/sentry';

export class ErrorBoundary extends Component<{ children: ReactElement }, { hasError?: boolean }> {
  constructor(props: any) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    withScope(scope => {
      scope.setExtras(errorInfo);
      captureException(error);
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorPage />;
    }

    return children;
  }
}
