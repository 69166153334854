import React, { useMemo } from 'react';
import { useField } from 'react-final-form';

import { Preview } from '../preview/Preview';
import { FontLoader } from '../fontLoader/FontLoader';
import { FontLabel } from '../fontSelect/FontSelect.styles';

import { Specification } from 'shared/components/specification/Specification';
import { ItemProps } from 'shared/components/specification/item/Item.types';
import { LogoDialog } from 'shared/components/logoDialog/LogoDialog';
import { ColorPickerField, FileField, InputField, SelectField } from 'shared/components/controls';
import { useLogoDialog } from 'shared/hooks';
import { Font } from './DefaultTemplate.types';

export const DefaultTemplate: React.FC = () => {
  const { isDialogOpened, handleDialogCancel, handleDialogConfirm, open } = useLogoDialog('logo');

  const {
    input: { value: accepted },
  } = useField('accepted', { subscription: { value: true } });

  const {
    input: { value: fontsList },
  } = useField<Font[]>('fonts', { subscription: { value: true } });

  const fonts = useMemo(
    () =>
      fontsList.map(({ url, name }: Font) => ({
        label: <FontLabel fontFamily={name}>{name}</FontLabel>,
        value: url,
      })),
    [fontsList],
  );

  const options: ItemProps[] = useMemo(
    () => [
      {
        label: 'clip_template.specification.logo',
        icon: 'CropFree',
        content: (
          <FileField
            name="logo"
            placeholder={
              accepted ? 'clip_template.specification.logo_uploaded' : 'clip_template.specification.select_logo'
            }
            onClick={() => open()}
            hiddenError
            hiddenLabel
          />
        ),
      },
      {
        label: 'clip_template.specification.background',
        icon: 'PaletteOutlined',
        content: <ColorPickerField name="background" hiddenError hiddenLabel />,
      },
      {
        label: 'text colour',
        icon: 'PaletteOutlined',
        content: <ColorPickerField name="header.color" hiddenError hiddenLabel />,
      },
      {
        label: 'clip_template.specification.font_family',
        icon: 'TextFields',
        content: <SelectField options={fonts} name="header.font" hiddenError hiddenLabel />,
      },
      {
        label: 'clip_template.specification.subheader',
        icon: 'ShortText',
        content: <InputField name="header.text" maxLength={40} hiddenLabel counter />,
      },
    ],
    [accepted, fonts, open],
  );

  return (
    <>
      {isDialogOpened && (
        <LogoDialog onConfirm={handleDialogConfirm} onCancel={handleDialogCancel} open={isDialogOpened} name="logo" />
      )}
      <Preview />
      <div>
        <FontLoader />
        <Specification options={options} title="clip_template.default_template.specification.title" />
      </div>
    </>
  );
};
