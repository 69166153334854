import React, { useEffect, useReducer } from 'react';
import cookies from 'js-cookie';
import decode from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import * as qs from 'query-string';

import { authStateReducer } from '../authState/reducer/authStateReducer';
import { setAuthorized, setTokens, setUnAuthorized } from '../authState';
import { AuthDispatchProvider, AuthStateProvider } from '../authContextProvider/AuthContextProvider';

import { Token } from 'api/types';

export const AuthContextController: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(authStateReducer, {
    isAuthorized: false,
    isAuthorizing: true,
    accessToken: String(cookies.get('accessToken') || ''),
    refreshToken: String(cookies.get('refreshToken') || ''),
    user: null,
  });

  const location = useLocation();
  useEffect(() => {
    if (!!location.search && location.pathname === '/login') {
      const params = qs.parse(location.search);
      const token = String(params.token);

      if (token) {
        const decodedToken = decode<Token>(token);
        dispatch(
          setTokens({
            accessToken: token,
            refreshToken: decodedToken.refreshToken,
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!state.accessToken.length || !state.refreshToken.length) {
      dispatch(setUnAuthorized());
    } else {
      cookies.set('accessToken', state.accessToken);
      cookies.set('refreshToken', state.refreshToken);

      const decodedToken = decode<Token>(state.accessToken);
      const expirationTime = decodedToken.exp * 1000;

      if (expirationTime > new Date().getTime()) {
        dispatch(
          setAuthorized({
            role: decodedToken.role,
            id: decodedToken.id,
            name: decodedToken.name,
            clipTemplateEditor: decodedToken.clipTemplateEditor || false,
            emailTemplateEditor: decodedToken.emailTemplateEditor || false,
            surveyTemplateEditor: decodedToken.surveyTemplateEditor || false,
            client: decodedToken.client,
          }),
        );
      } else {
        dispatch(setUnAuthorized());
      }
    }
  }, [state.accessToken, state.refreshToken]);

  return (
    <AuthStateProvider value={state}>
      <AuthDispatchProvider value={dispatch}>{children}</AuthDispatchProvider>
    </AuthStateProvider>
  );
};
