import { withStyles } from '@material-ui/core';

import { GridItem } from 'shared/components';

export const StyledGridItem = withStyles({
  root: {
    '&.MuiGrid-item': {
      padding: 0,
    },
  },
})(GridItem);
