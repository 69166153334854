import { Action } from 'api/types';
import { isObject } from 'shared/utils';
import { flatten } from './flatten/flatten';

const isAnyFileValue = (value: any) => {
  if (isObject(value)) {
    return Object.values(value).some(isAnyFileValue);
  }

  return value instanceof File;
};

export const requestBodyInterceptor = () => (action: Action) => {
  const { body, method } = action;
  const shouldBeFormData = method !== 'GET' && isAnyFileValue(body);

  if (shouldBeFormData) {
    const form = new FormData();
    const flatForm = Object.entries(flatten(body)).filter(([, v]) => !!v);

    flatForm.forEach(([key, value]) => {
      form.append(key, value);
    });

    return {
      ...action,
      body: form,
    };
  }

  return action;
};
