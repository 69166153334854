import React from 'react';

import { OptionFormControl } from '../optionFormControl/OptionFormControl';

import { StyledRadio } from './Radio.styles';
import { RadioProps } from './Radio.types';

export const Radio: React.FC<RadioProps> = ({ checked, onChange, value, label, disabled }) => (
  <OptionFormControl
    label={label}
    control={<StyledRadio checked={checked} onChange={onChange} value={value} disabled={disabled} />}
  />
);
