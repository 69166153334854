import React, { useMemo } from 'react';
import { useMutation } from 'react-fetching-library';
import { useIntl } from 'react-intl';
import { FormApi, FORM_ERROR } from 'final-form';

import { useStorageData, useAcceptedState, updateAcceptedState } from '../EmailTemplate.storage';

import { Form } from 'shared/components';
import { updateEmailTemplate } from 'api/actions/emailTemplate/emailTemplateActions';
import { Section } from './Section';
import { SectionContainerProps } from './Section.types';
import { EmailTemplateData, ClientDefaultsData } from 'api/types';
import { useClientDefaults, useAuthState } from 'shared/hooks';
import { User } from 'context/auth/authState/actions/authStateActions.types';

const getInitialValues = (
  response: EmailTemplateData | undefined,
  defaults: ClientDefaultsData | null,
  defaultBodyText: string,
  user: User | null,
) => {
  if (!response || !defaults) {
    return {};
  }

  const { header, content } = response;

  const logo = header.logo || defaults.logo;
  const color = header.color || defaults.colors.primary;

  return {
    header: { logo, color },
    content: {
      organisationName: content.organisationName || user?.client.name,
      text: content.text || defaultBodyText,
    },
  };
};

export const SectionContainer: React.FC<SectionContainerProps> = ({ children, ...props }) => {
  const { mutate: update } = useMutation(updateEmailTemplate);
  const defaults = useClientDefaults();
  const data = useStorageData();
  const { user } = useAuthState();
  const acceptedState = useAcceptedState();
  const updateAccepted = updateAcceptedState();

  const { formatMessage } = useIntl();
  const defaultBodyText = formatMessage({ id: 'email_template.content.bottom_text.default_value' });

  const { section } = props;
  const isAccepted = acceptedState ? acceptedState[section] : false;

  const handleOnSubmit = async (values: EmailTemplateData, { reset }: FormApi<EmailTemplateData>) => {
    const { payload, error } = await update({
      [section]: values[section],
    });

    if (!error) {
      setTimeout(() => reset(getInitialValues(payload, defaults, defaultBodyText, user)));
      updateAccepted(prev => {
        if (prev) {
          return { ...prev, [section]: true };
        }

        return prev;
      });

      return undefined;
    }

    if (error && payload && payload.errors) {
      return payload.errors;
    }

    return {
      [FORM_ERROR]: 'error.unknown',
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValues = useMemo(() => getInitialValues(data, defaults, defaultBodyText, user), []);

  return (
    <Form<EmailTemplateData>
      onSubmit={handleOnSubmit}
      initialValues={initialValues as EmailTemplateData}
      render={({ handleSubmit }) => (
        <Section {...props} handleSubmit={handleSubmit} accepted={isAccepted}>
          {children}
        </Section>
      )}
    />
  );
};
