import { styled } from '@material-ui/core';

export const Content = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  '& > :first-child': {
    flex: '0 0 422px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
  },
  '& > :last-child': {
    flex: '1 1 calc(100% - 422px)',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10%',
    [breakpoints.down('md')]: {
      marginLeft: '5%',
    },
  },
}));
