import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from 'react-fetching-library';

import { createFetchEventDetails } from 'api/actions/events/eventsActions';
import { EventDetails as Response } from 'api/actions/events/eventsActions.types';
import { EventDetails } from './EventDetails';

export const EventDetailsContainer = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const { payload, error, loading } = useQuery<Response>(createFetchEventDetails(id));

  return <EventDetails loading={loading} error={error} data={payload} />;
};
