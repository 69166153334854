import React from 'react';

import { ScrollToTop } from '../../components/scrollToTop/ScrollToTop';

import { Typography, Separator, PageAlert } from 'shared/components';
import { Container } from './PageTemplate.styles';
import { PageTemplateProps } from './PageTemplate.types';

export const PageTemplate: React.FC<PageTemplateProps> = ({ children, title, subtitle, message }) => {
  return (
    <>
      <ScrollToTop />
      <Container>
        <Typography variant="h2">{title}</Typography>
        {subtitle && <Typography variant="body1">{subtitle}</Typography>}
        <Separator height={message ? 46 : 96} />
        {!!message && (
          <>
            <PageAlert color={message.color} severity={message.color}>
              {message.content}
            </PageAlert>
            <Separator height={50} />
          </>
        )}
        {children}
      </Container>
    </>
  );
};
