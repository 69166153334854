import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';

import { EventsListContainer } from './eventsList/EventsListContainer';
import { EventDetailsContainer } from './eventDetails/EventDetailsContainer';

export const Events = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`} component={EventDetailsContainer} />
      <Route path={path} component={EventsListContainer} />
    </Switch>
  );
};
