import { styled } from '@material-ui/core';

import { Alert } from 'shared/components/alert/Alert';

export const Footer = styled('footer')({
  display: 'flex',
  justifyContent: 'flex-end',
  '& > :last-child': {
    marginLeft: 20,
  },
});

export const StyledAlert = styled(Alert)({
  border: 0,
  background: 'transparent',
});
