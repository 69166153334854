import React, { memo } from 'react';

import { GridItem, Icon } from 'shared/components';
import { SurveyChoiceItemProps } from './SurveyChoiceItem.types';
import { StyledGridContainer } from './SurveyChoiceItem.styles';

export const SurveyChoiceItem: React.FC<SurveyChoiceItemProps> = memo(({ children, icon }) => {
  return (
    <StyledGridContainer spacing={1} alignItems="center">
      <GridItem>
        <Icon icon={icon} />
      </GridItem>
      {children}
    </StyledGridContainer>
  );
});
