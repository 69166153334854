import { useEffect, useCallback } from 'react';

export const useEventOutside = (callback: () => void, ref: HTMLElement | undefined | null, events: string[] = []) => {
  const handleEvent = useCallback(
    (event: Event) => {
      if (ref && !ref.contains(event.target as Node)) {
        callback();
      }
    },
    [callback, ref],
  );

  useEffect(() => {
    if (ref) {
      events.forEach(eventType => document.addEventListener(eventType, handleEvent));
    }

    return () => {
      events.forEach(eventType => document.removeEventListener(eventType, handleEvent));
    };
  }, [ref, events, handleEvent]);
};

export type EventOutsideParameters = Parameters<typeof useEventOutside>;
