import { styled, Theme } from '@material-ui/core';

export const StyledWrapper = styled('div')<Theme, { background: string | undefined }>(
  ({ theme: { spacing }, background }) => ({
    padding: spacing(6),
    backgroundColor: background || '#fbfbfb',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

export const StyleImage = styled('img')({
  maxWidth: 300,
  maxHeight: 200,
});
