import React, { memo } from 'react';
import { useFormState } from 'react-final-form';

import { Button, GridContainer, GridItem, Icon, Tooltip } from 'shared/components';
import { SurveyActionBarProps } from './SurveyActionBar.types';

export const SurveyActionBar: React.FC<SurveyActionBarProps> = memo(
  ({ accepted, isAddingDisabled, isSubmittingDisabled, onAdd }) => {
    const { dirty } = useFormState();

    const submitButtonProps = accepted ? { disabled: !dirty } : { disabled: false };

    const SubmitButton = (
      <Button type="submit" color="primary" variant="contained" {...submitButtonProps}>
        {accepted ? 'survey_template.action_bar.accepted' : 'survey_template.action_bar.accept'}
      </Button>
    );

    return (
      <GridContainer spacing={2} justify="flex-end">
        <GridItem>
          <Button
            type="button"
            variant="outlined"
            disabled={isAddingDisabled}
            onClick={onAdd}
            startIcon={<Icon icon="Add" />}
          >
            survey_template.action_bar.add
          </Button>
        </GridItem>
        <GridItem>
          {isSubmittingDisabled ? (
            <Tooltip title="survey_template.action_bar.accept_hover" placement="top">
              <span>{SubmitButton}</span>
            </Tooltip>
          ) : (
            SubmitButton
          )}
        </GridItem>
      </GridContainer>
    );
  },
);
