import React, { memo } from 'react';

import { QuestionRenderProps } from '../base/BaseQuestion.types';

import { ConnectedTextField, TextField } from 'shared/components/controls';
import { required } from 'shared/validators';

export const SurveyShortAnswer: React.FC<QuestionRenderProps> = memo(({ field }) => {
  return (
    <>
      <ConnectedTextField
        name={`${field}.question`}
        placeholder="survey_template.short_answer.question"
        validate={[required()]}
        maxLength={250}
      />
      <TextField placeholder="survey_template.short_answer.answer" disabled />
    </>
  );
});
