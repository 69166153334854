import React, { useRef } from 'react';

import { useClientDefaults } from 'shared/hooks';
import { SurveyStyleContainerProps } from './SurveyStyleContainer.types';
import { SurveyStyle } from './SurveyStyle';

export const SurveyStyleContainer: React.FC<SurveyStyleContainerProps> = ({
  initialValues: baseInitialValues,
  onSubmit,
}) => {
  const accepted = useRef(false);
  const defaults = useClientDefaults();

  const initialValues = {
    style: {
      logo: baseInitialValues?.style?.logo || defaults?.logo,
      color: baseInitialValues?.style?.color || defaults?.colors?.primary,
    },
  };

  accepted.current = !!baseInitialValues?.style?.logo && !!baseInitialValues?.style?.color;

  return <SurveyStyle initialValues={initialValues} accepted={accepted.current} onSubmit={onSubmit} />;
};
