import {
  CreateParticipantUploadStatus,
  CreateGetParticipantUploadLink,
  CreateUpdateParticipant,
  CreateGetParticipantAuditLog,
  CreateDeleteParticipant,
  CreateAddParticipant,
} from './participantsActions.types';

export const createParticipantUploadStatus: CreateParticipantUploadStatus = ({ participantId, status }) => ({
  endpoint: `/client-portal/participants/${participantId}/virtual-graduation/${status}`,
  method: 'POST',
});

export const createGetParticipantUploadLink: CreateGetParticipantUploadLink = participantId => ({
  endpoint: `/client-portal/participants/${participantId}/virtual-graduation/upload`,
  method: 'GET',
});

export const createUpdateParticipant: CreateUpdateParticipant = participantId => body => ({
  endpoint: `/participants/${participantId}`,
  method: 'PATCH',
  body,
});

export const createAddParticipant: CreateAddParticipant = eventId => body => ({
  endpoint: `/events/${eventId}/participants`,
  method: 'POST',
  body,
});

export const createGetParticipantAuditLog: CreateGetParticipantAuditLog = participantId => ({
  endpoint: `/participants/${participantId}/participant-actions`,
  method: 'GET',
});

export const createDeleteParticipant: CreateDeleteParticipant = participantId => ({
  endpoint: `/participants/${participantId}`,
  method: 'DELETE',
});
