import { styled, makeStyles } from '@material-ui/core';

import { Typography } from 'shared/components/typography/Typography';

export const PreviewTextWrapper = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
});

export const PreviewText = styled('div')({
  position: 'absolute',
  left: 10,
  bottom: 15,
  textAlign: 'left',
  zIndex: 1,
});

export const PreviewTextTypo = styled(Typography)(({ theme: { palette } }) => ({
  fontSize: 12,
  color: palette.white.main,
}));

type T = {
  color: string;
  fontFamily: string;
};

export const useStyles = makeStyles({
  preview: {
    color: (props: T) => props.color,
    fontFamily: (props: T) => props.fontFamily,
  },
});
