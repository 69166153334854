import React, { useState } from 'react';
import { useField } from 'react-final-form';

import { SectionContainer } from '../section/SectionContainer';

import { LogoDialog } from 'shared/components';
import { ColorPickerField, FileField } from 'shared/components/controls';
import { ItemProps } from 'shared/components/specification/item/Item.types';
import { Specification } from 'shared/components/specification/Specification';
import { required } from 'shared/validators';

export const StylingContent: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    input: { value: logo, onChange: changeLogo },
  } = useField('header.logo', { subscription: { value: true } });

  const options: ItemProps[] = [
    {
      icon: 'CropFree',
      label: 'email_template.specification.logo',
      content: (
        <FileField
          name="header.logo"
          placeholder={logo ? 'clip_template.specification.logo_uploaded' : 'clip_template.specification.select_logo'}
          onClick={() => setIsModalOpen(true)}
          validate={[required()]}
        />
      ),
    },
    {
      label: 'email_template.specification.header_bg',
      icon: 'PaletteOutlined',
      content: <ColorPickerField name="header.color" hiddenLabel hiddenError />,
    },
  ];

  const handleLogoDialogCancel = () => {
    setIsModalOpen(false);
  };

  const handleLogoDialogConfirm = (file: File) => {
    changeLogo(file);
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <LogoDialog
          name="header.logo"
          onCancel={handleLogoDialogCancel}
          onConfirm={handleLogoDialogConfirm}
          open={isModalOpen}
        />
      )}
      <Specification options={options} />
    </>
  );
};

export const Styling = () => (
  <SectionContainer title="email_template.sections.styling" section="header">
    <StylingContent />
  </SectionContainer>
);
