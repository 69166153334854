import React from 'react';

import { Subtitle, Title } from '../section/Section.styles';

import { Separator, Collapsible, Skeleton } from 'shared/components';

type PlaceholderProps = {
  title: string;
  subtitle: string;
  step: string;
};

export const SectionPlaceholder = ({ title, subtitle }: PlaceholderProps) => {
  return (
    <>
      <Title variant="h3">{title}</Title>
      <Subtitle variant="caption">{subtitle}</Subtitle>
      <Separator height={8} />
      <Collapsible open>
        <Skeleton />
      </Collapsible>
    </>
  );
};
