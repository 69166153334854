import { withStyles, makeStyles } from '@material-ui/core';

import { IconWithText } from 'shared/components';

export const StyledIconWithText = withStyles({
  root: {
    alignItems: 'center',
  },
})(IconWithText);

export const useStyles = makeStyles(({ palette }) => ({
  success: {
    '& > svg': {
      color: palette.success.main,
    },
  },
  error: {
    '& > svg': {
      color: palette.error.main,
    },
  },
  normal: {
    '& > svg': {
      fontSize: 8,
    },
  },
  progress: {},
}));
