import { VariantType } from 'app/clipTemplate/variant/Variant.types';

export enum ClipTemplateSectionEnum {
  intro = 'intro',
  overlay = 'overlay',
  outro = 'outro',
  audio = 'audio',
  previewRequested = 'previewRequested',
}

export type ClipTemplateSectionDataCommon = {
  accepted: boolean;
  variant?: keyof typeof VariantType;
};

export type ClipTemplateAudioSectionData = ClipTemplateSectionDataCommon & {
  file: string;
};

export type ClipTemplateIntroSectionRegular = {
  logo: string;
  background: string;
  header: {
    color: string;
    font: string;
    text: string;
  };
  font: string;
} & ClipTemplateSectionDataCommon;

export type ClipTemplateIntroSectionCustom = { file: string } & ClipTemplateSectionDataCommon;

export type ClipTemplateIntroSectionData = ClipTemplateIntroSectionRegular | ClipTemplateIntroSectionCustom;

export type ClipTemplateOverlaySectionData = {
  color: string;
  font: string;
  file: string;
} & ClipTemplateSectionDataCommon;

export type ClipTemplateData = {
  [ClipTemplateSectionEnum.intro]: ClipTemplateIntroSectionData;
  [ClipTemplateSectionEnum.overlay]: ClipTemplateOverlaySectionData;
  [ClipTemplateSectionEnum.outro]: ClipTemplateSectionDataCommon;
  [ClipTemplateSectionEnum.audio]: ClipTemplateAudioSectionData;
  previewRequested: boolean;
};
