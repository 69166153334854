import React from 'react';
import { Form } from 'react-final-form';

import { Alert, Separator } from 'shared/components';
import { Dialog } from 'shared/components/dialog/Dialog';
import { Button } from 'shared/components/button/Button';
import { InputField } from 'shared/components/controls';
import { email, length, required } from 'shared/validators';
import { integer, minValue } from 'shared/validators/number/number.validator';
import { ParticipantEditValues } from 'api/actions/participants/participantsActions.types';
import { ParticipantAddEditDialogProps } from './ParticipantAddEdit.types';

export const ParticipantAddEditDialog = ({
  open,
  onClose,
  data,
  onUpdate,
  edit,
  error,
  errorMessage,
}: ParticipantAddEditDialogProps) => {
  const onCancel = () => {
    onClose();
  };

  const onSubmit = (form: ParticipantEditValues) => {
    if (edit) {
      onUpdate({
        fullName: form.fullName,
        email: form.email || null,
        subtitle: form.subtitle || '',
        secondSubtitle: form.secondSubtitle || '',
        clientParticipantId: form.clientParticipantId || null,
        eventOrderIndex: form.eventOrderIndex,
      });

      return;
    }

    onUpdate(form);
  };

  return (
    <Dialog open={open}>
      <Form<ParticipantEditValues>
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, pristine }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Dialog.Title>{edit ? 'edit_participant.header' : 'add_participant.header'}</Dialog.Title>
              <Dialog.Body>
                {error && (
                  <>
                    <Alert severity="error">{errorMessage.message}</Alert>
                    <Separator height={30} />
                  </>
                )}
                <InputField
                  name="fullName"
                  type="text"
                  label="edit_participant.full_name"
                  validate={[required(), length({ max: 50, msg: 'validation.length.max' })]}
                />
                <InputField
                  name="subtitle"
                  type="text"
                  label="edit_participant.subtitle"
                  validate={[length({ max: 90, msg: 'validation.length.max' })]}
                />
                <InputField
                  name="secondSubtitle"
                  type="text"
                  label="edit_participant.second_subtitle"
                  validate={[length({ max: 90, msg: 'validation.length.max' })]}
                />
                <InputField name="clientParticipantId" type="text" label="edit_participant.id" />
                <InputField
                  name="email"
                  type="email"
                  label="edit_participant.email"
                  validate={[email({ allowBlank: true })]}
                />
                <InputField
                  name="eventOrderIndex"
                  type="number"
                  label="edit_participant.ceremony_order"
                  validate={[minValue(1), ...(edit ? [required()] : []), integer]}
                />
              </Dialog.Body>
              <Dialog.Footer>
                <Button onClick={onCancel} color="primary" variant="outlined">
                  edit_participant.cancel
                </Button>
                <Button color="primary" type="submit" variant="contained" autoFocus disabled={pristine}>
                  edit_participant.submit
                </Button>
              </Dialog.Footer>
            </form>
          );
        }}
      />
    </Dialog>
  );
};
