import { useFormState } from 'react-final-form';

import { ButtonProps } from './Button.types';

export const useDisabled = (props: ButtonProps) => {
  const {
    submitting,
    dirtySinceLastSubmit,
    submitSucceeded,
    submitFailed,
    hasValidationErrors,
    hasSubmitErrors,
    touched,
  } = useFormState({
    subscription: {
      submitting: true,
      dirtySinceLastSubmit: true,
      submitSucceeded: true,
      submitFailed: true,
      hasValidationErrors: true,
      hasSubmitErrors: true,
      touched: true,
    },
  });

  const hasAnyTouched = Object.values(touched || {}).some(v => v);

  if (submitting) {
    return true;
  }

  if (submitFailed) {
    if (hasValidationErrors) {
      return true;
    }
  }

  if (hasAnyTouched) {
    if (hasValidationErrors) {
      return true;
    }
  }

  if (submitFailed && hasSubmitErrors && !dirtySinceLastSubmit) {
    return true;
  }

  if (Object.keys(props).includes('disabled')) {
    return props.disabled;
  }

  return submitSucceeded && !dirtySinceLastSubmit;
};
