import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-fetching-library';

import { fetchClientDefaults } from 'api/actions/client/clientActions';
import { ClientDefaultsData } from 'api/types';
import { ClientDefaultsProvider } from './clientDefaultsProvider';
import { ClientDefaultsContextType } from './clientDefaultsContext';

export const ClientDefaultsController: React.FC = ({ children }) => {
  const { query } = useQuery<ClientDefaultsData>(fetchClientDefaults, false);
  const [defaults, setDefaults] = useState<ClientDefaultsContextType>(null);

  useEffect(() => {
    const fetchData = async () => {
      const { error, payload } = await query();

      if (!error && payload) {
        setDefaults(payload);
      }
    };

    fetchData();
  }, [query]);

  return <ClientDefaultsProvider value={defaults}>{children}</ClientDefaultsProvider>;
};
