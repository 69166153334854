import { Action } from 'react-fetching-library';

import {
  LoginFormValues,
  CreateResetPasswordAction,
  CreateSetPasswordAction,
  CreateVerifyResetToken,
} from './auth.types';

export const createLoginAction: (body: LoginFormValues) => Action = body => ({
  skipAuth: true,
  endpoint: '/sessions',
  method: 'POST',
  body,
});

export const createRefreshTokenAction = (refreshToken: string, userId: string) => ({
  skipAuth: true,
  endpoint: `/sessions/${userId}/refresh`,
  method: 'POST',
  body: {
    refreshToken,
  },
});

export const createResetPasswordAction: CreateResetPasswordAction = body => ({
  skipAuth: true,
  endpoint: `/users/password-reset`,
  method: 'POST',
  body,
});

export const createSetPasswordAction: CreateSetPasswordAction = ({ token, ...body }) => ({
  skipAuth: true,
  endpoint: `/users/set-new-password?token=${token}`,
  method: 'POST',
  body,
});

export const createVerifyResetToken: CreateVerifyResetToken = token => ({
  skipAuth: true,
  endpoint: `/users/verify-reset-token?token=${token}`,
  method: 'GET',
});
