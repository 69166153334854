import React from 'react';

const applyMarkup = (regex: RegExp, tag: string) => (stringParts: any[]) => {
  return stringParts.reduce((str, stringPart) => {
    if (stringPart.match(regex)) {
      return [...str, React.createElement(tag, undefined, stringPart.replace(regex, '$1'))];
    }

    return [...str, stringPart];
  }, []);
};

const curry = (fn: Function[], value: any) => fn.reduce((result: any, next: any) => next(result), value);

/*
This helper allows to use markup inside translations and transform it to defined tags by applyMarkup.

It search all wrapped words by defined matcher and replaces markup signs into tags defined in applyMarkup fn
inside curry function. The function applyMarkup transform markup sign to tag via React.createElement - it is needed
because React has protection against XSS attacks and escapes all HTML tags.

The output of the prettify function is an array of elements (string or component) created by React.Children.toArray to
avoid errors about keys when React renders an array of elements. In the output, all possible strings are joined to avoid
rendering multiple text lines in the DOM tree which is not necessary.
 */
export const prettify = (message: string) => {
  const boldMatcher = /\*(\S+)\*/gim;

  if (![boldMatcher].some(matcher => message.match(matcher))) {
    return message;
  }

  const stringParts = message.split(' ');
  const parsed = curry([applyMarkup(boldMatcher, 'strong')], stringParts);

  return React.Children.toArray(
    parsed.reduce((acc: any[], next: any) => {
      if (typeof next !== 'string') {
        return [...acc, next];
      }

      if (typeof next !== 'undefined') {
        if (typeof acc[acc.length - 1] === 'string') {
          return [...acc.slice(0, acc.length - 1), ' ', [...acc.slice(acc.length - 1, acc.length), next].join(' ')];
        }

        return [...acc, next];
      }

      return acc;
    }, []),
  );
};
