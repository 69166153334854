import styled from '@material-ui/core/styles/styled';
import { makeStyles } from '@material-ui/styles';

import { Typography } from 'shared/components/typography/Typography';
import { Dialog } from 'shared/components';

export const SectionWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  '& > video,& > img': {
    maxHeight: '50vh',
  },
});

export const SectionTitle = styled(Typography)({
  alignSelf: 'flex-start',
});

export const ModalForm = styled('form')({
  width: '100%',
});

export const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& button': {
      marginLeft: '5px',
    },
  },
});

export const Footer = styled(Dialog.Footer)({
  flexDirection: 'column',
});
