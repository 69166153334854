import { withStyles } from '@material-ui/core';

import { Paper } from '../paper/Paper';

export const Card = withStyles({
  root: {
    padding: '28px 30px',
    marginBottom: 22,
    overflow: 'hidden',
  },
})(Paper);
