import React from 'react';

import { Typography } from 'shared/components';
import { useStyles } from './Dialog.styles';
import { DialogProps } from './Dialog.types';

export const Dialog: React.RefForwardingComponent<HTMLDivElement, DialogProps> = React.forwardRef(
  ({ children, title }, ref: React.Ref<any>) => {
    const styles = useStyles();

    return (
      <div className={styles.wrapper} ref={ref}>
        <Typography className={styles.title}>{title}</Typography>
        {children}
      </div>
    );
  },
);
