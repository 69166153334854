import React from 'react';

import { useStorageData } from '../../SurveyTemplate.storage';

import { StyledWrapper, StyleImage } from './SurveyBodyHeader.styles';

export const SurveyBodyHeader: React.FC = () => {
  const data = useStorageData();

  const getFilePreview = (file: string | File | undefined | null): string | undefined => {
    if (!file) {
      return undefined;
    }

    if (typeof file === 'string') {
      return file;
    }

    if (file) {
      return URL.createObjectURL(file);
    }

    throw new Error('invalid file');
  };

  return (
    <StyledWrapper background={data?.style?.color}>
      <StyleImage src={getFilePreview(data?.style?.logo)} />
    </StyledWrapper>
  );
};
