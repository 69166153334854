import React, { useState, useEffect } from 'react';
import { List as MaterialList, ListItem } from '@material-ui/core';

import { ListItemText } from './itemText/ListItemText';
import { ListProps } from './List.types';

const UNSET = -1;

export const List: <T extends { [key: string]: any }>(props: ListProps<T>) => React.ReactElement = ({
  options,
  onChange,
  selected: activeItem,
  classes,
}) => {
  const [selected, setSelected] = useState<number>(typeof activeItem !== 'undefined' ? activeItem : UNSET);

  useEffect(() => {
    if (onChange && selected !== UNSET) {
      onChange(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (typeof activeItem === 'number') {
      setSelected(activeItem);
    }
  }, [activeItem]);

  return (
    <MaterialList classes={{ root: classes?.root }}>
      {options.map(({ label, onClick }, index) => {
        const isSelected = selected === index;

        const handleOnClick = () => {
          setSelected(index);

          if (onClick) {
            onClick();
          }
        };

        return (
          <ListItem button onClick={handleOnClick} key={label} selected={isSelected}>
            <ListItemText>{label}</ListItemText>
          </ListItem>
        );
      })}
    </MaterialList>
  );
};
