import React from 'react';
import { TablePaginationProps } from '@material-ui/core';

import { StyledPagination } from './Pagination.styles';

export const Pagination: React.FC<TablePaginationProps> = ({
  rowsPerPageOptions,
  rowsPerPage,
  count,
  page,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  return (
    <StyledPagination
      // @ts-ignore
      component="div"
      rowsPerPageOptions={rowsPerPageOptions}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  );
};
