import { styled } from '@material-ui/core';

import { Icon } from '../../icon/Icon';

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '7px 0',
});

export const Label = styled('div')({
  display: 'flex',
  flex: '0 0 50%',
  alignItems: 'center',
});

export const Content = styled('div')({
  flex: 1,
  overflow: 'hidden',
});

export const StyledIcon = styled(Icon)({
  marginRight: 14,
});
