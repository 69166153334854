import React from 'react';
import { styled, withStyles, Box, fade } from '@material-ui/core';

import { medium } from 'app/core/theme/typography';
import { Button, Typography } from 'shared/components';
import { ButtonProps } from 'shared/components/button/Button.types';

export const Wrapper = styled('div')({
  display: 'flex',
  width: '100%',
});

export const Buttons = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto 0 auto 125px',
});

export const TemplateButton: React.ComponentType<ButtonProps> = withStyles(({ palette }) => ({
  root: {
    borderRadius: 16,
    padding: '19px 23px',
    borderColor: palette.black.main,
    maxWidth: 410,
  },
  label: {
    alignItems: 'flex-start',
    textTransform: 'none',
    color: palette.black.main,
    textAlign: 'left',
  },
}))(Button);

export const TemplateButtonsLabels = withStyles({
  root: {
    marginLeft: 17,
  },
})(Box);

export const TemplateButtonTitle = withStyles({
  root: {
    textTransform: 'uppercase',
    fontSize: 14,
    marginBottom: 5,
    fontWeight: medium,
  },
})(Typography);

export const TemplateButtonSubtitle = withStyles(({ palette, typography }) => ({
  root: {
    ...typography.caption,
    color: fade(palette.black.main, 0.6),
  },
}))(Typography);

export const TemplateButtonSeparator = styled('div')(({ theme: { palette } }) => ({
  margin: '10px 0',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: 410,
  '&:after': {
    content: `''`,
    display: 'block',
    width: '70%',
    height: 1,
    background: palette.gray.light,
    position: 'absolute',
    zIndex: 0,
  },
  '& > *': {
    zIndex: 1,
    background: palette.white.main,
    padding: '0 7px',
    color: fade(palette.black.main, 0.6),
  },
}));
