import * as Sentry from '@sentry/browser';

import { name } from '../../../../package.json';

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
const environment = process.env.REACT_APP_ENVIRONMENT || 'unknown';
const release = process.env.REACT_APP_RELEASE || `${name}-unknown`;

const isSentryEnabled = !!sentryDsn;

export const init = () => {
  if (isSentryEnabled) {
    Sentry.init({ dsn: sentryDsn, release, environment });
  }
};

export const withScope = (callback: (scope: Sentry.Scope) => void) => {
  if (isSentryEnabled) {
    Sentry.withScope(callback);
  }
};

// eslint-disable-next-line
export const captureException = Sentry.captureException;
