import { styled, fade, Theme } from '@material-ui/core';

export const Title = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '-10px',
});

export const FinalLookWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
});

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  overflow: 'hidden',
  margin: '-28px -30px',
});

export const Header = styled('div')<Theme, { color: string | undefined }>({
  padding: '50px 100px',
  width: '100%',
  height: 220,
  background: props => props.color,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

export const Content = styled('div')({
  padding: '25px 60px',
});

export const Footer = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: palette.black.main,
  color: palette.white.main,
  padding: '25px 60px',
}));

export const Line = styled('div')(({ theme: { palette } }) => ({
  background: palette.white.main,
  height: 1,
  width: '100%',
}));

export const Highlight = styled('span')(({ theme: { palette } }) => ({
  background: fade(palette.green.main, 0.2),
  borderRadius: 8,
  padding: '2px 5px',
  whiteSpace: 'pre-wrap',
}));
