import { isObject } from 'shared/utils';

export const flatten = (obj: any, parent: string | null = null, acc = {} as Record<string, any>) => {
  Object.entries(obj).forEach(([key, value]) => {
    const nextKey = parent ? `${parent}[${key}]` : key;

    if (isObject(value)) {
      return flatten(value, nextKey, acc);
    }

    acc[nextKey] = value;

    return false;
  });

  return acc;
};
