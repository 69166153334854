import React, { memo } from 'react';

import { QuestionRenderProps } from '../base/BaseQuestion.types';

import { ConnectedTextField, TextField } from 'shared/components/controls';
import { required } from 'shared/validators';

export const SurveyParagraph: React.FC<QuestionRenderProps> = memo(({ field }) => {
  return (
    <>
      <ConnectedTextField
        name={`${field}.question`}
        placeholder="survey_template.paragraph.question"
        validate={[required()]}
        maxLength={250}
      />
      <TextField name={`${field}.answer`} placeholder="survey_template.paragraph.answer" rows={5} multiline disabled />
    </>
  );
});
