import React, { memo } from 'react';
import { useFormState } from 'react-final-form';

import { Button, GridContainer, GridItem } from 'shared/components';
import { SurveyStyleActionBarProps } from './SurveyStyleActionBar.types';

export const SurveyStyleActionBar: React.FC<SurveyStyleActionBarProps> = memo(({ accepted }) => {
  const { dirty } = useFormState({ subscription: { dirty: true } });

  const submitButtonProps = accepted ? { disabled: !dirty } : { disabled: false };

  return (
    <GridContainer spacing={2} justify="flex-end">
      <GridItem>
        <Button type="submit" color="primary" variant="contained" {...submitButtonProps}>
          {accepted ? 'survey_template.style.action_bar.accepted' : 'survey_template.style.action_bar.accept'}
        </Button>
      </GridItem>
    </GridContainer>
  );
});
