import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { options } from '../SurveyQuestion.constants';

export function renderValue(value: unknown): ReactNode {
  if (value) {
    return options.find(({ value: option }) => option === value)?.label;
  }

  return <FormattedMessage id="survey_template.question_type.label" />;
}
