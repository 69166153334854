import React from 'react';

import { SectionContainer } from '../section/SectionContainer';

import { InputField } from 'shared/components/controls';
import { Label } from './label/Label';
import { Input } from './Content.styles';
import { email, required } from 'shared/validators';

export const Content: React.FC = () => {
  return (
    <SectionContainer title="email_template.sections.content" section="content">
      <Input>
        <Label
          label="email_template.content.sender_email.label"
          sublabel="email_template.content.sender_email.sublabel"
        />
        <InputField name="content.senderEmail" hiddenLabel fullWidth validate={[required(), email()]} maxLength={150} />
      </Input>
      <Input>
        <Label
          label="email_template.content.organisation_name.label"
          sublabel="email_template.content.organisation_name.sublabel"
        />
        <InputField name="content.organisationName" hiddenLabel fullWidth validate={[required()]} maxLength={150} />
      </Input>
      <Input>
        <Label
          label="email_template.content.bottom_text.label"
          sublabel="email_template.content.bottom_text.sublabel"
        />
        <InputField
          name="content.text"
          hiddenLabel
          fullWidth
          multiline
          rowsMax={5}
          rows={3}
          validate={[required()]}
          maxLength={500}
        />
      </Input>
    </SectionContainer>
  );
};
