import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Providers } from 'app/core';
import { Login } from 'app/login/Login';
import { LogoutContainer } from 'app/logout/LogoutContainer';
import { DashboardContainer } from './dashboard/DashboardContainer';
import { AuthorizedRoute } from 'shared/components';
import { ForgotPassword } from './forgotPassword/ForgotPassword';
import { SetPassword } from './setPassword/SetPassword';
import { Notifications } from 'shared/components/notifications/Notifications';
import { NotificationEventHandler } from 'shared/components/notificationEventHandler/NotificationEventHandler';

const App: React.FC = () => {
  return (
    <Providers>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/logout" exact component={LogoutContainer} />
        <Route path="/forgot-password" exact component={ForgotPassword} />
        <Route path="/set-password" exact component={SetPassword} />
        <AuthorizedRoute path="/" component={DashboardContainer} />
        <Redirect to="/dashboard" />
      </Switch>
      <Notifications />
      <NotificationEventHandler />
    </Providers>
  );
};

export default App;
