import React from 'react';

import { Button } from 'shared/components/button/Button';
import { Dialog } from 'shared/components/dialog/Dialog';
import { Form } from 'shared/components/form/Form';
import { useDownload } from 'shared/hooks/useDownload/useDownload';
import { DatePickerField } from 'shared/components/controls/datePicker/DatePicker';
import { handleOnSubmit, formattedDate } from 'shared/utils';
import { PageAlert } from 'shared/components/pageAlert/PageAlert';
import { Separator } from 'shared/components/separator/Separator';
import { createGenerateReport } from 'api/actions/client/clientActions';
import { isBeforeDate, isAfterDate } from 'shared/validators';
import { useAuthState } from 'shared/hooks/useAuthState/useAuthState';
import { DialogFooter } from './GenerateReportDialog.styles';
import { GenerateReportDialogFormProps, GenerateReportDialogProps } from './GenerateReportDialog.types';
import { User } from 'context/auth/authState/actions/authStateActions.types';

const initialDate = new Date();
const dateFormat = 'yyyy-MM-dd';

const getFileName = (clientName: string, values: GenerateReportDialogFormProps) =>
  `Report-${clientName}-${formattedDate(values.dateFrom, dateFormat)}-${formattedDate(values.dateTo, dateFormat)}.pdf`;

export const GenerateReportDialog = ({ open = false, closeModal }: GenerateReportDialogProps) => {
  const { download, downloadAbort, loading } = useDownload(createGenerateReport(), 'report.pdf');
  const {
    user: { client },
  } = useAuthState() as { user: User };

  const onSubmit = handleOnSubmit<GenerateReportDialogFormProps>({
    action: values => download(values, getFileName(client.name, values)),
    successCallback: () => {
      closeModal();
    },
  });

  const onCancel = () => {
    if (loading) {
      downloadAbort();
    }

    closeModal();
  };

  return (
    <Dialog open={open}>
      <Dialog.Title>events.generate_report.title</Dialog.Title>
      <Form<GenerateReportDialogFormProps>
        errorAlert={false}
        errorSeparator={18}
        onSubmit={onSubmit}
        initialValues={{
          dateFrom: initialDate,
          dateTo: initialDate,
        }}
        render={({ handleSubmit, values, errorAlert }) => {
          const { dateFrom, dateTo } = values;

          return (
            <form onSubmit={handleSubmit}>
              <Dialog.Body>
                {errorAlert}
                {loading && (
                  <>
                    <PageAlert severity="warning">events.generate_report.in_progress</PageAlert>
                    <Separator height={18} />
                  </>
                )}
                <DatePickerField
                  name="dateFrom"
                  label="events.generate_report.date_from"
                  validate={[isAfterDate({ field: 'dateTo' })]}
                  disableFuture
                  maxDate={dateTo}
                />
                <DatePickerField
                  name="dateTo"
                  label="events.generate_report.date_to"
                  validate={[isBeforeDate({ field: 'dateFrom' })]}
                  disableFuture
                  minDate={dateFrom}
                />
              </Dialog.Body>
              <DialogFooter>
                <Button onClick={onCancel} color="primary" variant="outlined">
                  events.generate_report.cancel
                </Button>
                <Button color="primary" type="submit" variant="contained" autoFocus>
                  events.generate_report.generate
                </Button>
              </DialogFooter>
            </form>
          );
        }}
      />
    </Dialog>
  );
};
