import { ValuesType } from 'utility-types';

import { ClipTemplateData, ClipTemplateSectionEnum } from 'api/types';
import { updatableContextFactory } from 'shared/utils';

const [SectionStorageProvider, useSectionData, updateSectionData] = updatableContextFactory<ClipTemplateData>(
  {} as ClipTemplateData,
);

const useSectionsStorage = () => {
  const data = useSectionData();

  if (data === undefined) {
    throw new Error('useSectionStorage must be used within an SectionStorageProvider');
  }

  return data;
};

const useSectionStorageUpdate = (name: ClipTemplateSectionEnum) => {
  const update = updateSectionData();

  return (values: ValuesType<ClipTemplateData>) =>
    update(prev => {
      if (prev) {
        return { ...prev, [name]: values };
      }

      return prev;
    });
};

export { SectionStorageProvider, useSectionsStorage, useSectionStorageUpdate };
