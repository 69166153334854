import React, { useCallback } from 'react';

import { SurveyOption } from './SurveyOption';
import { SurveyOptionContainerProps } from './SurveyOptionContainer.types';

export const SurveyOptionContainer: React.FC<SurveyOptionContainerProps> = ({ fields, icon, index, name }) => {
  const isDeletable = (fields.length || 0) > 1;

  const handleDelete = useCallback(() => {
    fields.remove(index);
  }, [fields, index]);

  return <SurveyOption field={name} icon={icon} isDeletable={isDeletable} onDelete={handleDelete} />;
};
