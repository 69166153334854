import React from 'react';

import { Separator } from '../../separator/Separator';
import { modalPadding } from '../Dialog.styles';

import { Footer } from './DialogFooter.styles';
import { DialogFooterProps } from './DialogFooter.types';

export const DialogFooter: React.FC<DialogFooterProps> = ({ children, className }) => (
  <>
    <Separator height={modalPadding} />
    <Footer data-testid="dialog-footer" className={className}>
      {children}
    </Footer>
  </>
);
