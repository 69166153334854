import { connectControl } from '../connectControl/connectControl';
import { ConnectControlProps } from '../connectControl/connectControl.types';

import { InputControl } from './Input';
import { length } from 'shared/validators';
import { TextFieldProps } from './Input.types';

const getValidators = (props: ConnectControlProps<TextFieldProps>) => {
  const { validate = [], maxLength } = props;

  if ([maxLength].some(value => typeof value === 'undefined')) {
    return { validate };
  }

  return { validate: [...validate, length({ max: maxLength })] };
};

// @ts-ignore
export const InputField = connectControl<TextFieldProps, string | number>(InputControl, props => ({
  ...props,
  ...getValidators(props),
}));
