import React from 'react';
import { useField } from 'react-final-form';

import { Variant } from '../variant/Variant';
import { VariantType } from '../variant/Variant.types';
import { DefaultTemplate } from '../defaultTemplate/DefaultTemplate';
import { CustomTemplate } from '../customTemplate/CustomTemplate';

import { ItemProps } from 'shared/components/specification/item/Item.types';

const allowedExtensions = ['mp4', 'mov', 'jpg', 'png'];

const rules: ItemProps[] = [
  { icon: 'InsertDriveFile', label: 'clip_template.specification.file_type', content: allowedExtensions.join(', ') },
  { icon: 'AspectRatio', label: 'clip_template.specification.resolution', content: '1280x720' },
  { icon: 'ImageAspectRatio', label: 'clip_template.specification.ratio', content: '16:9' },
  { icon: 'PhotoSizeSelectSmall', label: 'clip_template.specification.max_size', content: '256MB' },
];

const fileDropZoneProps = { maxSize: '256 MB' };

export const IntroTemplate: React.FC = () => {
  const {
    input: { value: variant },
  } = useField('variant', { subscription: { value: true } });

  switch (variant) {
    case VariantType.default:
      return <DefaultTemplate />;

    case VariantType.custom:
      return (
        <CustomTemplate
          info="clip_template.intro.info"
          specificationRules={rules}
          allowedExtensions={allowedExtensions}
          fileDropZoneProps={fileDropZoneProps}
        />
      );
    default:
      return <Variant />;
  }
};
