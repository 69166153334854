import React from 'react';
import { useQuery } from 'react-fetching-library';

import { fetchEvents } from 'api/actions/events/eventsActions';
import { EventsList } from './EventsList';
import { Event } from 'api/actions/events/eventsActions.types';

export const EventsListContainer = () => {
  const { payload = null, error } = useQuery<Event[]>(fetchEvents);

  return <EventsList data={error ? null : payload} error={error} />;
};
