export const password = () => (value?: string) => {
  if (value) {
    if (!/(?=.*[A-Z])/.test(value)) {
      return 'validation.password.uppercase';
    }

    if (!/(?=.*[a-z])/.test(value)) {
      return 'validation.password.lowercase';
    }

    if (!/(?=.*[0-9])/.test(value)) {
      return 'validation.password.digit';
    }

    if (!/(?=.*[^A-Za-z0-9])/.test(value)) {
      return 'validation.password.symbol';
    }
  }

  return undefined;
};
