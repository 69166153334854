import { InputBase, withStyles, styled, makeStyles, IconButton } from '@material-ui/core';

import { Typography } from '../../typography/Typography';

export const StyledInput = withStyles({
  root: {
    width: '100%',
  },
  input: {
    backgroundColor: 'transparent',
    border: 0,
    fontSize: 16,
    width: '100%',
    padding: 0,
  },
  inputAdornedEnd: {},
})(InputBase);

export const Counter = styled(Typography)({
  opacity: 0.6,
  textAlign: 'right',
  position: 'absolute',
  right: 0,
  bottom: 0,
});

export const useStyles = makeStyles(({ palette }) => ({
  success: {
    borderColor: palette.primary.main,
  },
  password: {
    marginRight: 50,
  },
}));

export const PasswordInputWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const ShowPasswordButton = styled(IconButton)({
  position: 'absolute',
  right: -50,
});
