import React from 'react';

import { Typography } from 'shared/components/typography/Typography';
import { Separator } from 'shared/components/separator/Separator';
import { IconButton } from 'shared/components/iconButton/IconButton';
import { Icon } from 'shared/components/icon/Icon';
import { Title } from './DialogTitle.styles';

export const DialogTitle: React.FC<{ children: any; onClose?: () => void }> = ({ children, onClose }) => (
  <>
    <Title data-testid="dialog-title">
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" onClick={() => onClose()}>
          <Icon icon="Close" />
        </IconButton>
      ) : (
        ''
      )}
    </Title>
    <Separator height={20} />
  </>
);
