import React, { useMemo, ProviderProps } from 'react';

import { InitialValuesContext, InitialValuesContextType } from '../context/InitialValuesContext';

type InitialValuesProviderProps = ProviderProps<InitialValuesContextType> & { step: string };

export const InitialValuesProvider: React.FC<InitialValuesProviderProps> = ({ children, value }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const state = useMemo(() => value, []);

  return <InitialValuesContext.Provider value={state}>{children}</InitialValuesContext.Provider>;
};
