import React, { useState, useRef } from 'react';
import classNames from 'classnames';

import { Separator } from 'shared/components';
import { useAuthState, useOnMouseOverOutside } from 'shared/hooks';
import { MenuItem } from './item/MenuItem';
import { ellipsis } from 'shared/utils/ellipsis/ellipsis';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ReactComponent as LogoShort } from 'assets/logo_short.svg';
import { Drawer, useStyles } from './Menu.styles';

export const Menu: React.FC = () => {
  const { user } = useAuthState();
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>();
  const styles = useStyles();

  const drawerPaperClassNames = classNames({
    [styles.menuExpanded]: isOpen,
  });

  useOnMouseOverOutside(() => {
    setIsOpen(false);
  }, wrapperRef.current);

  const logoClassNames = isOpen ? styles.logoExpanded : styles.logo;

  return (
    <Drawer
      variant="permanent"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      classes={{
        paper: drawerPaperClassNames,
      }}
      data-testid="menu"
      ref={wrapperRef}
    >
      <div className={logoClassNames}>{isOpen ? <Logo /> : <LogoShort />}</div>
      <Separator height={48} />

      <div className={styles.wrapper}>
        <div>
          <MenuItem icon="EventOutlined" expanded={isOpen} to="/events">
            menu.page.events
          </MenuItem>
          {!!user?.clipTemplateEditor && (
            <MenuItem icon="PersonalVideoOutlined" expanded={isOpen} to="/clip-template">
              menu.page.clip_template
            </MenuItem>
          )}
          {!!user?.emailTemplateEditor && (
            <MenuItem icon="MailOutline" expanded={isOpen} to="/email-template">
              menu.page.email_template
            </MenuItem>
          )}
          {!!user?.surveyTemplateEditor && (
            <MenuItem icon="ForumOutlined" expanded={isOpen} to="/survey-template">
              menu.page.survey_template
            </MenuItem>
          )}
        </div>

        <div>
          {!!user && (
            <MenuItem expanded={isOpen} icon="PersonOutlineOutlined" disabled>
              {user.name}
            </MenuItem>
          )}

          {!!user && (
            <MenuItem expanded={isOpen} icon="SchoolOutlined" disabled>
              {ellipsis(user.client.name)}
            </MenuItem>
          )}

          <MenuItem expanded={isOpen} icon="ExitToAppOutlined" to="/logout">
            menu.user.logout
          </MenuItem>
        </div>
      </div>
    </Drawer>
  );
};
