import React, { useState, useEffect } from 'react';

import { CollapsibleHeader } from './header/CollapsibleHeader';
import { CollapsibleBody } from './body/CollapsibleBody';
import { CollapsibleFooter } from './footer/CollapsibleFooter';
import { CollapsibleStateContext } from './context/CollapsibleStateContext';
import { CollapsibleProps } from './Collapsible.types';
import { Card } from './Collapsible.styles';

export const Collapsible: React.FC<CollapsibleProps> & {
  Header: typeof CollapsibleHeader;
  Body: typeof CollapsibleBody;
  Footer: typeof CollapsibleFooter;
} = ({ children, open: isOpen, onChange }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(prev => !prev);

    if (typeof onChange !== 'undefined') {
      onChange(!open);
    }
  };

  useEffect(() => {
    if (typeof isOpen !== 'undefined') {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const contextValue = {
    open,
    toggle,
  };

  return (
    <>
      <CollapsibleStateContext.Provider value={contextValue}>
        <Card data-testid="collapsible">{children}</Card>
      </CollapsibleStateContext.Provider>
    </>
  );
};

Collapsible.Header = CollapsibleHeader;
Collapsible.Body = CollapsibleBody;
Collapsible.Footer = CollapsibleFooter;
