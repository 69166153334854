import { makeStyles, withStyles, TableRow as MaterialTableRow } from '@material-ui/core';

export const useStyles = makeStyles({
  textCenter: {
    textAlign: 'center',
  },
  wrapper: {
    padding: '10px 30px',
  },
});

export const TableRow = withStyles({
  hover: {
    cursor: 'pointer',
  },
})(MaterialTableRow);
