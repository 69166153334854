import React, { useState } from 'react';
import { useMutation } from 'react-fetching-library';

import { useSectionStorageUpdate, useSectionsStorage } from '../ClipTemplate.storage';

import { Dialog } from 'shared/components';
import { ClipTemplateSectionEnum, ClipTemplateData } from 'api/types';
import { updateClipTemplates } from 'api/actions/clipTemplate/clipTemplateActions';
import { Button } from 'shared/components/button/Button';
import { Skeleton } from 'shared/components/skeleton/Skeleton';
import { Tooltip } from 'shared/components/tooltip/Tooltip';
import { ButtonWrapper } from './RequestPreview.styles';
import { RequestPreviewProps } from './RequestPreview.types';
import { useTranslations } from 'shared/hooks';

export const RequestPreview: React.FC<RequestPreviewProps> = ({ loading, allAccepted }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { message: popupContent } = useTranslations('clip_template.preview_button.popup.content');
  const { previewRequested } = useSectionsStorage();
  const updatePreviewRequested = useSectionStorageUpdate(ClipTemplateSectionEnum.previewRequested);
  const { mutate, loading: loadingRequestPreview } = useMutation<ClipTemplateData>(updateClipTemplates);

  const handlePreviewRequest = async () => {
    const { error } = await mutate({ previewRequested: true });

    if (!error) {
      updatePreviewRequested(true);
      setIsModalOpen(true);
    }
  };

  const button = loading ? (
    <Skeleton width="10%" />
  ) : (
    <Button
      variant="contained"
      color="primary"
      disabled={!allAccepted || previewRequested}
      onClick={handlePreviewRequest}
      loading={loadingRequestPreview}
    >
      {previewRequested ? 'clip_template.preview_button.requested' : 'clip_template.preview_button.request'}
    </Button>
  );

  return (
    <>
      <Dialog
        title="clip_template.preview_button.popup.title"
        onConfirm={() => setIsModalOpen(false)}
        confirmLabel="clip_template.preview_button.popup.button"
        open={isModalOpen}
      >
        {popupContent}
      </Dialog>
      <ButtonWrapper>
        {allAccepted ? (
          button
        ) : (
          <Tooltip title="clip_template.preview_button.tooltip">
            <span>{button}</span>
          </Tooltip>
        )}
      </ButtonWrapper>
    </>
  );
};
