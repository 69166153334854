import React, { useEffect } from 'react';
import { Color } from '@material-ui/lab';

import { PageTemplate } from 'shared/templates';
import { GridContainer, GridItem, Separator } from 'shared/components';
import { WelcomeDialog } from 'shared/components/welcomeDialog/WelcomeDialog';
import { Styling } from './styling/Styling';
import { Content } from './content/Content';
import { Preview } from './preview/Preview';
import { updateStorageData, useAcceptedState, updateAcceptedState, useStorageData } from './EmailTemplate.storage';
import { EmailTemplatePlaceholder } from './placeholder/EmailTemplatePlaceholder';
import { useClientDefaults } from 'shared/hooks';
import { EmailTemplateProps } from './EmailTemplate.types';
import { EmailTemplateData } from 'api/types';
import { scroll } from 'shared/utils/scroll/scroll';

const isAccepted = (data: EmailTemplateData, section: 'content' | 'header') =>
  Object.values(data[section]).every(v => v);

export const EmailTemplate: React.FC<EmailTemplateProps> = props => {
  const { loading, payload } = props;
  const updateStorage = updateStorageData();
  const storageData = useStorageData();
  const defaults = useClientDefaults();
  const updateAccepted = updateAcceptedState();
  const { header: isHeaderAccepted, content: isContentAccepted } = useAcceptedState() || {};
  const allAccepted = isHeaderAccepted && isContentAccepted;

  useEffect(() => {
    if (payload && defaults) {
      updateStorage(payload);

      updateAccepted({ header: isAccepted(payload, 'header'), content: isAccepted(payload, 'content') });
    }
  }, [defaults, payload, updateAccepted, updateStorage]);

  useEffect(() => {
    if (allAccepted) {
      scroll();
    }
  }, [allAccepted]);

  if (!storageData) {
    return <EmailTemplatePlaceholder />;
  }

  const message =
    loading && !allAccepted
      ? undefined
      : {
          color: (allAccepted ? 'success' : 'warning') as Color,
          content: allAccepted ? 'email_template.message.all_accepted' : 'email_template.message.not_all_accepted',
        };

  return (
    <>
      <PageTemplate title="email_template.page.title" message={message}>
        <GridContainer>
          <GridItem xs={6}>
            <Styling />
            <Separator height={30} />
            <Content />
          </GridItem>
          <GridItem xs={6}>
            <Preview />
          </GridItem>
        </GridContainer>
      </PageTemplate>
      <WelcomeDialog
        title="email_template.welcome.title"
        body="email_template.welcome.body"
        storageKey="email_template_welcome_dialog"
      />
    </>
  );
};
