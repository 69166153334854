import React from 'react';

import { Typography, Separator } from 'shared/components';
import { Sublabel } from './Label.styles';
import { LabelProps } from './Label.types';

export const Label: React.FC<LabelProps> = ({ label, sublabel }) => (
  <>
    <Typography>{label}</Typography>
    <Separator />
    <Sublabel variant="caption">{sublabel}</Sublabel>
    <Separator height={10} />
  </>
);
