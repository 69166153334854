import React from 'react';

import { OptionFormControl } from '../optionFormControl/OptionFormControl';
import { connectControl } from '../connectControl/connectControl';

import { StyledCheckbox } from './Checkbox.styles';
import { CheckboxProps } from './Checkbox.types';

export const Checkbox: React.FC<CheckboxProps> = ({ checked, classes, onChange, value, label, disabled }) => {
  return (
    <OptionFormControl
      label={label}
      classes={classes}
      control={
        <StyledCheckbox
          checked={checked}
          onChange={onChange}
          value={value}
          disabled={disabled}
          inputProps={{ checked }}
        />
      }
    />
  );
};

export const CheckboxField = connectControl<CheckboxProps>(
  Checkbox,
  props =>
    ({
      type: 'checkbox',
      ...props,
    } as any),
);
