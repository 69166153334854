import { styled } from '@material-ui/core';

import { modalPadding } from '../Dialog.styles';

export const Footer = styled('footer')({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: `0 ${modalPadding}px`,
  '& > button': {
    marginLeft: 8,
  },
});
