import React from 'react';
import { useMutation } from 'react-fetching-library';
import { FORM_ERROR } from 'final-form';

import { useAuthDispatch } from 'shared/hooks';
import { setTokens, startAuthorizing } from 'context/auth';
import { createLoginAction } from 'api/actions/auth/authActions';
import { LoginForm } from './LoginForm';
import { SuccessResponse, FailureResponse } from './LoginForm.types';
import { LoginFormValues } from 'api/types';

const isSuccess = (payload: SuccessResponse | FailureResponse): payload is SuccessResponse => {
  if ('sessionToken' in payload) {
    return payload && payload.sessionToken !== undefined;
  }

  return false;
};

export const LoginFormContainer: React.FC = () => {
  const { mutate } = useMutation<SuccessResponse | FailureResponse>(createLoginAction);
  const authDispatch = useAuthDispatch();

  const handleSubmit = async (values: LoginFormValues) => {
    authDispatch(startAuthorizing());

    const { error, payload } = await mutate(values);

    if (!error && payload && isSuccess(payload)) {
      return authDispatch(setTokens({ accessToken: payload.sessionToken, refreshToken: payload.refreshToken }));
    }

    if (error && payload && !isSuccess(payload)) {
      return { [FORM_ERROR]: payload.message };
    }

    return {
      [FORM_ERROR]: 'error.unknown',
    };
  };

  return <LoginForm onSubmit={handleSubmit} />;
};
