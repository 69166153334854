import React, { useCallback } from 'react';

import { SurveyQuestion } from './SurveyQuestion';
import { SurveyQuestionContainerProps } from './SurveyQuestionContainer.types';

export const SurveyQuestionContainer: React.FC<SurveyQuestionContainerProps> = ({ fields, index, name }) => {
  const handleDelete = useCallback(() => {
    fields.remove(index);
  }, [fields, index]);

  return <SurveyQuestion field={name} onDelete={handleDelete} />;
};
