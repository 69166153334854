import React from 'react';
import { Collapse } from '@material-ui/core';
import { useField, useFormState } from 'react-final-form';

import { Collapsible, Alert, Icon } from 'shared/components';
import { useLocale } from 'shared/hooks';
import { useStyles } from './SectionHeader.styles';
import { SectionHeaderProps } from './SectionHeader.types';

const getSeverity = (accepted: boolean, hasErrors: boolean) => {
  if (accepted && !hasErrors) {
    return 'success';
  }

  return 'warning';
};

export const SectionHeader: React.FC<SectionHeaderProps> = ({ children }) => {
  const { formatMessage } = useLocale();
  const styles = useStyles();
  const {
    input: { value: accepted },
  } = useField('accepted', { subscription: { value: true } });
  const { hasSubmitErrors } = useFormState({ subscription: { hasSubmitErrors: true } });

  const severity = getSeverity(accepted, hasSubmitErrors);
  const isDone = accepted && !hasSubmitErrors;

  const message = formatMessage(
    { id: 'clip_template.section.header.title' },
    { accepted: isDone || false, section: formatMessage({ id: children }) as string },
  );

  return (
    <Collapsible.Header>
      {collapsibleState => (
        <>
          <Collapse in={!collapsibleState.open}>
            <Alert
              className={styles.alert}
              severity={severity}
              variant="outlined"
              action={<Icon icon={isDone ? 'Edit' : 'AddCircle'} className={styles.icon} />}
            >
              {message}
            </Alert>
          </Collapse>
        </>
      )}
    </Collapsible.Header>
  );
};
