import React from 'react';

import { SurveyBodyHeader } from '../header/SurveyBodyHeader';

import { StyledPaper, StyledWrapper, StyledDetailsField } from './SurveyDetails.styles';
import { required } from 'shared/validators';

export const SurveyDetails: React.FC = () => {
  return (
    <StyledPaper>
      <SurveyBodyHeader />
      <StyledWrapper>
        <StyledDetailsField
          name="survey.details"
          label="survey_template.details.label"
          placeholder="survey_template.details.placeholder"
          validate={[required()]}
          maxLength={1000}
          multiline
          InputLabelProps={{ shrink: true }}
        />
      </StyledWrapper>
    </StyledPaper>
  );
};
