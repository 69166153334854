import { Action } from 'react-fetching-library';

import { EmailTemplateData } from './emailTemplateActions.types';

export const fetchEmailTemplate: Action = {
  method: 'GET',
  endpoint: '/client-portal/email-templates',
};

export const updateEmailTemplate: (body: Partial<EmailTemplateData>) => Action = body => ({
  method: 'PATCH',
  endpoint: '/client-portal/email-templates',
  body,
});
