import React from 'react';

import { Button, GridItem, GridContainer, Form, Separator } from 'shared/components';
import { email, required } from 'shared/validators';
import { InputField } from 'shared/components/controls';
import { useStyles } from './LoginForm.styles';
import { LoginFormProps } from './LoginForm.types';

export const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const styles = useStyles();

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <GridItem xs={9}>
            <GridContainer spacing={0}>
              <GridItem xs={9}>
                <InputField name="email" type="email" label="login.content.email" fullWidth validate={[email()]} />
              </GridItem>
              <GridItem xs={3} />
            </GridContainer>
            <Separator height={37} />
            <GridContainer spacing={0}>
              <GridItem xs={9}>
                <InputField
                  name="password"
                  type="password"
                  label="login.content.password"
                  fullWidth
                  validate={[required()]}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <div className={styles.buttons}>
            <Button color="primary" to="/forgot-password">
              login.content.forgot_password
            </Button>
            <Button variant="contained" color="primary" type="submit">
              login.content.login
            </Button>
          </div>
        </form>
      )}
    />
  );
};
