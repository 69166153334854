import React from 'react';

import { Column } from '../../Table.types';
import { convertColumnsToDictionary } from '../../table.utils';

import { Chip } from 'shared/components';
import { useStyles } from './FiltersPreview.styles';
import { FiltersPreviewProps } from './FiltersPreview.types';

const defaultLabelFormatter = (v: any) => v;

export const FiltersPreview: React.FC<FiltersPreviewProps> = ({ filters, columns, onFilterChange }) => {
  const styles = useStyles();
  const columnsDictionary: { [key: string]: Column } = convertColumnsToDictionary(columns);

  const filtersLabels = Object.keys(filters)
    .filter(key => key !== 'search')
    .map(key => {
      const filterValue = filters[key];
      const labelFormatter = columnsDictionary[key].filterPreviewLabelFormatter || defaultLabelFormatter;

      return { label: labelFormatter(filterValue), key, resetValue: columnsDictionary[key].defaultFilterValue || '' };
    })
    .filter(({ label }) => !!label);

  return (
    <div className={styles.tags}>
      {filtersLabels.map(({ label, key, resetValue }) => (
        <Chip label={label} key={key} onDelete={() => onFilterChange(key, resetValue)} />
      ))}
    </div>
  );
};
