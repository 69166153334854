import React from 'react';
import { useQuery } from 'react-fetching-library';

import { fetchEmailTemplate } from 'api/actions/emailTemplate/emailTemplateActions';
import { EmailTemplate } from './EmailTemplate';
import { EmailTemplateData } from 'api/types';
import { StorageDataProvider, FormStorageProvider, AcceptedStateProvider } from './EmailTemplate.storage';
import { ClientDefaultsController } from 'context/clientDefaults/clientDefaultsController';

export const EmailTemplateContainer: React.FC = () => {
  const { payload, loading } = useQuery<EmailTemplateData>(fetchEmailTemplate);

  return (
    <ClientDefaultsController>
      <StorageDataProvider>
        <FormStorageProvider>
          <AcceptedStateProvider>
            <EmailTemplate loading={loading} payload={payload} />
          </AcceptedStateProvider>
        </FormStorageProvider>
      </StorageDataProvider>
    </ClientDefaultsController>
  );
};
