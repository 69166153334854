import React from 'react';
import { useField } from 'react-final-form';

import { Player } from 'shared/components/player/Player';
import { Playlist } from 'shared/components/player/Player.types';
import { Typography } from 'shared/components';
import { StyledList } from './AudioDefaultTemplate.styles';
import { Option } from 'shared/components/list/List.types';

const UNSET = -1;

export const AudioDefaultTemplate: React.FC = () => {
  const {
    input: { value: file, onChange: fileOnChange },
  } = useField('file', { subscription: { value: true } });

  const {
    input: { value: playlist },
  } = useField<Playlist>('songs', { subscription: { value: true } });

  const trackIndex = playlist.findIndex(({ src }) => src === file);
  const selected = trackIndex > UNSET ? trackIndex : 0;
  const listOptions: Option[] = playlist.map(({ title }) => ({ label: title }));

  const handleOnSongChange = (track: number) => {
    if (playlist[track]) {
      fileOnChange(playlist[track].src);
    }
  };

  return (
    <>
      <div>
        <Typography variant="h4">clip_template.audio.default_songs</Typography>
        <StyledList options={listOptions} onChange={handleOnSongChange} selected={selected} />
      </div>

      <Player playlist={playlist} track={selected} onChange={handleOnSongChange} />
    </>
  );
};
