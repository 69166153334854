import React, { useEffect } from 'react';
import { Fade } from '@material-ui/core';
import { FormSpy, useField, useForm, useFormState } from 'react-final-form';

import { useSectionStorageUpdate } from '../ClipTemplate.storage';
import { VariantType } from '../variant/Variant.types';
import { useInitialValues } from '../initialValues/useInitialValues/useInitialValues';

import { Collapsible, Button, Separator } from 'shared/components';
import { useStepperState } from 'shared/components/stepper/context/useStepperState';
import { SectionHeader } from './header/SectionHeader';
import { SectionBody } from './body/SectionBody';
import { Header, Title, Footer, Subtitle } from './Section.styles';
import { ClipTemplateSectionEnum } from 'api/types';
import { FormValues, SectionProps } from './Section.types';

export const Section: React.FC<SectionProps> & {
  Header: typeof SectionHeader;
  Body: typeof SectionBody;
} = ({ children, title, subtitle, step, handleSubmit }) => {
  const { step: activeStep, setStep, goNext, isLast, reset: resetStepper } = useStepperState();
  const updateState = useSectionStorageUpdate(ClipTemplateSectionEnum[step]);
  const initialValues = useInitialValues();
  const { hideVariantChange } = initialValues;

  const { submitSucceeded, submitting, dirty, dirtySinceLastSubmit } = useFormState();
  const { reset: resetForm } = useForm();
  const {
    input: { value: variant },
  } = useField('variant', { subscription: { value: true } });

  const {
    input: { value: accepted },
  } = useField('accepted', { subscription: { value: true } });

  const open = activeStep === step;

  const goToNextSection = () => (isLast ? resetStepper() : goNext());

  const handleVariantChange = () => {
    const nextVariant = variant === VariantType.default ? VariantType.custom : VariantType.default;

    resetForm({
      // @ts-ignore
      ...initialValues[nextVariant],
      variant: nextVariant,
    });
  };

  const handleSkip = () => {
    setTimeout(() => {
      resetForm({ ...initialValues[initialValues.initial] });
    }, 400);
    goToNextSection();
  };

  useEffect(() => {
    if (submitSucceeded) {
      goToNextSection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitSucceeded]);

  const isSkipDisabled = submitting || submitSucceeded;
  const isDirty = dirty || dirtySinceLastSubmit;
  const isVariantSet = variant !== VariantType.none;
  const isDefaultVariant = variant === VariantType.default;
  const disableSubmit = !isDirty && accepted;

  return (
    <form onSubmit={handleSubmit}>
      <Header>
        <Title variant="h3">{title}</Title>
        {!hideVariantChange && (
          <Fade in={isVariantSet && open}>
            <Button color="primary" onClick={handleVariantChange} disabled={submitting}>
              {isDefaultVariant ? 'clip_template.section.header.upload' : 'clip_template.section.header.default'}
            </Button>
          </Fade>
        )}
        <Subtitle variant="caption">{subtitle}</Subtitle>
      </Header>
      <Separator height={8} />
      <Collapsible open={open} onChange={() => setStep(step)}>
        {children}
      </Collapsible>
      <Fade in={open}>
        <Footer>
          <Button color="primary" onClick={handleSkip} disabled={isSkipDisabled}>
            clip_template.section.footer.skip
          </Button>
          {(!accepted || dirty) && (
            <Button type="submit" color="primary" variant="contained" disabled={submitSucceeded || disableSubmit}>
              {isVariantSet || dirty
                ? 'clip_template.section.footer.accept'
                : 'clip_template.section.footer.accept_without'}
            </Button>
          )}
        </Footer>
      </Fade>

      <FormSpy<FormValues> onChange={({ values }) => updateState(values)} />
    </form>
  );
};

Section.Header = SectionHeader;
Section.Body = SectionBody;
