import { Sections, SectionType } from '../../ClipTemplate.types';
import { VariantType } from '../../variant/Variant.types';
import { getFonts } from '../common';

export const getOverlayInitialValues: Sections['overlay']['getInitialValues'] = (values, defaults) => {
  const fonts = getFonts(values);

  return {
    [VariantType.custom]: {
      ...values,
      fonts,
      font: values.font || fonts[0].url,
      color: values.color || defaults.colors.primary,
      type: SectionType.overlay,
      variant: VariantType.custom,
    },
    [VariantType.default]: {
      ...values,
      type: SectionType.regular,
      variant: VariantType.none,
    },
    initial: VariantType.custom,
    hideVariantChange: true,
  };
};
