import React from 'react';
import { useQuery } from 'react-fetching-library';
import { useRouteMatch } from 'react-router';

import { EventDetails as Response } from 'api/actions/events/eventsActions.types';
import { fetchEventParticipants } from 'api/actions/events/eventsActions';
import { EventParticipantsList } from './EventParticipantsList';

export const EventParticipantsListContainer = (props: any) => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const { query, payload } = useQuery<Response>(fetchEventParticipants(id));

  return <EventParticipantsList {...props} participants={payload} reloadParticipants={query} />;
};
