import { useContext } from 'react';

import { AuthStateContext } from 'context/auth/authContext/authContext';

export const useAuthState = () => {
  const context = useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error('useAuthState must be used within an AuthStateContext');
  }

  return context;
};
