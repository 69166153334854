import React from 'react';

import { AspectRatio } from '../aspectRatio/AspectRatio';

import { StyledVideo } from './Video.styles';
import { VideoProps } from './Video.types';

export const Video: React.FC<VideoProps> = ({ src, ratio }) => {
  if (ratio) {
    return (
      <AspectRatio ratio={ratio}>
        <StyledVideo src={src} controls />
      </AspectRatio>
    );
  }

  return <StyledVideo src={src} controls />;
};
