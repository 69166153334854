import { ClipTemplateIntroSectionData } from 'api/types';

export const isArrayString = (value: any[]): value is string[] => {
  return value && typeof value[0] === 'string';
};

export const getFileName = (value: string) =>
  value
    .split('/')
    ?.pop()
    ?.split('.')[0]
    .replace(/_/g, ' ');

export type Font = {
  name: string;
  url: string;
};

export const getFonts = (values: ClipTemplateIntroSectionData & { fonts?: string[] | Font[] }) => {
  const fonts = values.fonts && values.fonts[0] ? values.fonts : (process.env.REACT_APP_FONTS || '').split(';');

  if (!isArrayString(fonts)) {
    return fonts;
  }

  return fonts.map((url: string, index) => ({
    name: getFileName(url) || `Font ${index}`,
    url,
  }));
};
