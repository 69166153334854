import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => {
  const activeWrapper = {
    color: palette.black.main,
    background: palette.background.default,
    borderLeft: `4px solid ${palette.green.main}`,
    opacity: 1,
  };

  return {
    wrapper: {
      background: palette.black.main,
      borderLeft: `4px solid ${palette.black.main}`,
      color: palette.background.default,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      opacity: 0.6,
      padding: 21,
      position: 'relative',
      width: '100%',
      textDecoration: 'none',
      '&::after, &&::before': {
        content: '""',
        display: 'block',
        background: palette.black.main,
        position: 'absolute',
        width: 'calc(100% + 4px)',
        height: 3,
        opacity: 0,
        left: -4,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
      },
      '&&::after': {
        top: 0,
        borderBottomRightRadius: 10,
      },
      '&&::before': {
        bottom: 0,
        borderTopRightRadius: 10,
      },
      '&:hover:not($disabled)': {
        ...activeWrapper,
        '&::after, &::before': {
          opacity: 1,
        },
      },
    },
    wrapperActive: {
      ...activeWrapper,
      '$wrapper&::after, $wrapper&::before': {
        opacity: 1,
      },
    },
    wrapperExpanded: {
      justifyContent: 'flex-start',
    },
    icon: {
      position: 'relative',
      fontSize: 32,
      left: -4,
    },
    disabled: {
      cursor: 'default',
    },
    itemLabel: {
      minWidth: 250,
      maxWidth: 250,
      opacity: 0,
      visibility: 'hidden',
    },
    itemLabelExpanded: {
      opacity: 1,
      visibility: 'visible',
    },
  };
});
