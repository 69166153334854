import { useContext } from 'react';

import { CollapsibleStateContext } from './CollapsibleStateContext';

export const useCollapsibleState = () => {
  const context = useContext(CollapsibleStateContext);

  if (context === undefined) {
    throw new Error('useCollapsibleState must be used within an CollapsibleStateContext');
  }

  return context;
};
