import React, { useEffect, useState } from 'react';

import { formattedDate } from 'shared/utils/formatters';
import { Separator } from 'shared/components';
import { PageTemplate } from 'shared/templates/page/PageTemplate';
import { useLocale } from 'shared/hooks/useLocale/useLocale';
import { useClientDefaults } from 'shared/hooks/useClientDefaults/useClientDefaults';
import { Typography } from 'shared/components/typography/Typography';
import { EventDetailsProps } from './EventDetails.types';
import { EventType, Timezone } from './EventDetails.styles';
import { EventDetailsPlaceholder } from './placeholder/EventDetailsPlaceholder';
import { EventParticipantsStats } from './participantsStats/EventParticipantsStats';
import { Participant } from 'api/actions/events/eventsActions.types';
import { EventParticipantsListContainer } from './participantsList/EventParticipantsListContainer';

export const EventDetails = ({ loading, error, data }: EventDetailsProps) => {
  const { formatMessage } = useLocale();
  const clientDefaults = useClientDefaults();
  const [participants, setParticipants] = useState<Participant[] | null>(null);

  useEffect(() => {
    if (!loading && data?.participants) {
      setParticipants(data.participants);
    }
  }, [data, loading]);

  if (!clientDefaults || loading || error || !data) {
    return <EventDetailsPlaceholder error={error} />;
  }

  const { name, startTimeLocal, type, deadlineDate, virtualGraduationVideoUrl, timezoneName } = data;

  const title = formatMessage(
    {
      id: 'event_details.page.title',
    },
    {
      name: name || '',
      type: <EventType variant="body2">{type}</EventType>,
    },
  );

  const subtitle = formatMessage(
    {
      id: 'event_details.page.subtitle',
    },
    {
      date: formattedDate(startTimeLocal, 'yyyy/MM/dd h:mm a'),
      timezone: (
        <Timezone display="inline">
          <Typography variant="caption">event_details.details.timezone</Typography>
          <Typography variant="caption">{timezoneName}</Typography>
        </Timezone>
      ),
    },
  );

  return (
    <PageTemplate title={title} subtitle={subtitle}>
      {/* NOTE: hide Event Details section until the decision is made about bringing it back
        <GridContainer>
          <GridItem xs={5}>
            <PageSection title="event_details.details.title">
              <PageSection.Body>
                <InputControl label="event_details.details.event_name" value={name} disabled />
                <InputControl
                  label="event_details.details.event_date"
                  value={formattedDate(startTime, 'yyyy/MM/dd h:mm a')}
                  hiddenError
                  disabled
                />
              </PageSection.Body>
            </PageSection>
          </GridItem>
        </GridContainer>
        <Separator height={80} />
      */}
      {type === 'Virtual Graduation' && (
        <>
          <EventParticipantsStats data={participants || []} />
          <Separator height={40} />
        </>
      )}
      <EventParticipantsListContainer
        data={participants}
        eventType={type}
        eventVideo={virtualGraduationVideoUrl}
        deadlineDate={deadlineDate}
        eventId={data.id}
      />
    </PageTemplate>
  );
};
