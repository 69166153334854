import React from 'react';

import { Typography, IconWithText } from 'shared/components';
import { SidebarTemplateLogo } from 'shared/templates/sidebar/logo/SidebarTemplateLogo';

export const Sidebar = () => {
  return (
    <>
      <SidebarTemplateLogo />
      <Typography variant="h3" color="textSecondary">
        login.sidebar.body
      </Typography>
      <Typography variant="caption" color="textSecondary">
        <IconWithText icon="InfoOutlined">login.sidebar.footer</IconWithText>
      </Typography>
    </>
  );
};
