import { Action } from 'react-fetching-library';

import { ClipTemplateData } from './clipTemplate.types';

export const fetchClipTemplates: Action = {
  method: 'GET',
  endpoint: '/client-portal/clip-templates',
};

export const updateClipTemplates: Action = (body: ClipTemplateData) => ({
  method: 'PATCH',
  endpoint: '/client-portal/clip-templates',
  body,
});
