import { Drawer as MaterialDrawer, withStyles, makeStyles } from '@material-ui/core';

export const Drawer = withStyles(({ palette }) => ({
  paper: {
    width: 72,
    backgroundColor: palette.black.main,
    padding: '32px 0',
  },
  paperAnchorDockedLeft: {
    border: 0,
  },
}))(MaterialDrawer);

export const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowX: 'hidden',
  },
  menuExpanded: {
    width: 'auto',
  },
  logo: {
    margin: '0 auto',
  },
  logoExpanded: {
    padding: '0 21px',
  },
});
