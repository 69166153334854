import React from 'react';

import { Typography } from '../../typography/Typography';

import { Wrapper, Label, Content, StyledIcon } from './Item.styles';
import { ItemProps } from './Item.types';

export const Item: React.FC<ItemProps> = ({ label, icon, content }) => {
  return (
    <Wrapper>
      <Label>
        <StyledIcon icon={icon} />
        <Typography variant="button">{label}</Typography>
      </Label>
      <Content>{content}</Content>
    </Wrapper>
  );
};
