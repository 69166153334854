import React from 'react';

import { Typography } from 'shared/components/typography/Typography';
import { IconWithText } from 'shared/components/icon/withText/IconWithText';
import { SidebarTemplateLogo } from 'shared/templates/sidebar/logo/SidebarTemplateLogo';

export const Sidebar = () => {
  return (
    <>
      <SidebarTemplateLogo />
      <Typography variant="h3" color="textSecondary">
        set_password.sidebar.body
      </Typography>
      <Typography variant="caption" color="textSecondary">
        <IconWithText icon="InfoOutlined">set_password.sidebar.footer</IconWithText>
      </Typography>
    </>
  );
};
