import React, { useCallback, useState } from 'react';
import { parseISO, isBefore, isAfter } from 'date-fns';
import { IconButton } from '@material-ui/core';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';

import { Status } from '../status/Status';
import { IconMap } from '../status/Status.types';
import { GenerateReportDialog } from '../generateReport/GenerateReportDialog';

import { formattedDate } from 'shared/utils/formatters';
import { PageTemplate } from 'shared/templates';
import { Message } from 'shared/templates/page/PageTemplate.types';
import { Button } from 'shared/components/button/Button';
import { Separator } from 'shared/components/separator/Separator';
import { Table } from 'shared/components/table/Table';
import { RadioGroup } from 'shared/components/controls';
import { Icon } from 'shared/components/icon/Icon';
import { useLocale } from 'shared/hooks/useLocale/useLocale';
import { EventsListProps } from './EventsList.types';
import { Column, RowData } from 'shared/components/table/Table.types';
import { useClientDefaults } from 'shared/hooks/useClientDefaults/useClientDefaults';
import { ClientDefaultsData } from 'api/types';
import { TableButtons } from './EventList.styles';

const iconMap: IconMap = {
  'events.table.status.no_data': { icon: 'Stop', level: 'normal' },
  'events.table.status.waiting_for_data': { icon: 'Stop', level: 'normal' },
  'events.table.status.waiting_for_data_urgent': { icon: 'Error', level: 'error' },
  'events.table.status.due_now': { icon: 'Error', level: 'error' },
  'events.table.status.in_progress': { icon: 'Replay', level: 'progress' },
  'events.table.status.uploaded': { icon: 'CheckCircle', level: 'success' },
  'events.table.status.available': { icon: 'CheckCircle', level: 'success' },
};

const useColumns = (): Column[] => {
  const { path } = useRouteMatch();

  return [
    {
      title: 'events.table.columns.start_time',
      field: 'startTimeLocal',
      formatter: date => formattedDate(date, 'MMM. dd yyyy h:mm a'),
      filter: (value, filter) => {
        if (filter === 'events.table.filters.past') {
          return isBefore(parseISO(value), new Date());
        }

        if (filter === 'events.table.filters.future') {
          return isAfter(parseISO(value), new Date());
        }

        return true;
      },
      defaultFilterValue: 'events.table.filters.all',
      filterPreviewLabelFormatter: (value: string) => {
        if (value === 'events.table.filters.all') {
          return null;
        }

        return value;
      },
    },
    { title: 'events.table.columns.event_name', field: 'name' },
    {
      title: 'events.table.columns.event_type',
      field: 'type',
    },
    {
      title: 'events.table.columns.participants_list',
      field: 'participantsListStatus',
      formatter: value => <Status status={value} iconMap={iconMap} />,
    },
    {
      title: 'events.table.columns.video',
      field: 'videoUploadedStatus',
      formatter: value => <Status status={value} iconMap={iconMap} />,
    },
    {
      title: 'events.table.columns.clip_status',
      field: 'clipStatus',
      formatter: value => <Status status={value} iconMap={iconMap} />,
    },
    {
      field: 'details',
      title: 'events.table.columns.details',
      sortable: false,
      formatter: (_, { id }) => (
        <Link to={`${path}/${id}`}>
          <IconButton>
            <Icon icon="MoreHoriz" />
          </IconButton>
        </Link>
      ),
    },
  ];
};

export const EventsList: React.FC<EventsListProps> = ({ data, error }) => {
  const columns = useColumns();
  const { formatMessage } = useLocale();
  const { push } = useHistory();
  const { path } = useRouteMatch();
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const { metrics } = useClientDefaults() as ClientDefaultsData;

  const message: Message | undefined = error
    ? {
        content: formatMessage(
          { id: 'error_page.content' },
          { email: formatMessage({ id: 'static.support_email' }) as string },
        ) as string,
        color: 'error',
      }
    : undefined;

  const goToEventDetails = useCallback(
    (row: RowData) => {
      push(`${path}/${row.id}`);
    },
    [path, push],
  );

  return (
    <PageTemplate title="events.page.title" subtitle="events.page.subtitle" message={message}>
      <TableButtons>
        {metrics && (
          <Button color="primary" variant="outlined" onClick={() => setReportModalOpen(true)}>
            events.generate_report.title
          </Button>
        )}
      </TableButtons>
      <Separator height={20} />
      <Table
        data={data}
        columns={columns}
        noDataText="events.table.no_data"
        filtersDialog={({ onFilterChange, filters }) => (
          <RadioGroup
            value={filters.startTime}
            onChange={value => onFilterChange('startTimeLocal', value)}
            options={[
              { value: 'events.table.filters.all', label: 'events.table.filters.all' },
              { value: 'events.table.filters.past', label: 'events.table.filters.past' },
              { value: 'events.table.filters.future', label: 'events.table.filters.future' },
            ]}
          />
        )}
        onRowClick={goToEventDetails}
        sort={{ column: 'startTimeLocal', direction: 'desc' }}
      />
      {reportModalOpen && <GenerateReportDialog open={reportModalOpen} closeModal={() => setReportModalOpen(false)} />}
    </PageTemplate>
  );
};
