import React, { useState } from 'react';
import clsx from 'clsx';
import { InputBaseProps } from '@material-ui/core';

import { controlBase, useFormControlState } from '../controlBase';
import { Icon } from '../../icon/Icon';

import { useTranslations } from 'shared/hooks';
import { EndAdornment } from './endAdornment/EndAdornment';
import { StyledInput, Counter, useStyles, PasswordInputWrapper, ShowPasswordButton } from './Input.styles';
import { TextFieldProps } from './Input.types';
import { EndAdornmentProps } from './endAdornment/EndAdornment.types';

export const Input: React.FC<TextFieldProps> = ({
  classes,
  className,
  counter,
  defaultValue,
  disabled,
  error,
  id,
  name,
  onBlur,
  onChange,
  onFocus,
  onClick,
  placeholder,
  required,
  success,
  type,
  value,
  maxLength,
  rowsMax,
  multiline,
  rows,
  readOnly,
}) => {
  const styles = useStyles();

  const inputClassNames = clsx(className, {
    [styles.success]: success,
  });

  const endAdornmentProps: EndAdornmentProps = {
    success,
    error,
  };

  const { message: translatedPlaceholder } = useTranslations(placeholder);

  const inputProps: InputBaseProps = {
    endAdornment: <EndAdornment {...endAdornmentProps} />,
    className: inputClassNames,
    defaultValue,
    disabled,
    id,
    name,
    onBlur,
    onChange,
    onFocus,
    onClick,
    placeholder: translatedPlaceholder as string | undefined,
    required,
    type,
    value,
    classes,
    inputProps: {
      maxLength,
    },
    rowsMax,
    multiline: multiline || !!rowsMax || !!rows,
    rows,
    readOnly,
  };

  return (
    <>
      <StyledInput {...inputProps} />
      {counter && maxLength && (
        <Counter variant="caption">
          {(value || '').length}/{maxLength}
        </Counter>
      )}
    </>
  );
};

export const PasswordInputFormControl: React.FC<TextFieldProps> = ({ type, ...props }) => {
  const { success, error, disabled, required, onBlur, onFocus, name, id } = useFormControlState();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <PasswordInputWrapper>
      <Input
        success={success}
        error={error}
        disabled={disabled}
        required={required}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        id={id}
        type={showPassword ? 'text' : type}
        {...props}
      />
      <ShowPasswordButton onClick={() => setShowPassword(prev => !prev)} data-testid="show-password">
        {showPassword ? <Icon icon="Visibility" /> : <Icon icon="VisibilityOff" />}
      </ShowPasswordButton>
    </PasswordInputWrapper>
  );
};

export const InputFormControl: React.FC<TextFieldProps> = props => {
  const { success, error, disabled, required, onBlur, onFocus, name, id } = useFormControlState();
  const { type } = props;

  if (type === 'password') {
    return (
      <PasswordInputFormControl
        success={success}
        error={error}
        disabled={disabled}
        required={required}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        id={id}
        {...props}
      />
    );
  }

  return (
    <Input
      success={success}
      error={error}
      disabled={disabled}
      required={required}
      onBlur={onBlur}
      onFocus={onFocus}
      name={name}
      id={id}
      {...props}
    />
  );
};

export const InputControl = controlBase<TextFieldProps>(InputFormControl);
