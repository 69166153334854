import React from 'react';
import { ListItemText as MaterialListItemText, ListItemTextProps } from '@material-ui/core';

import { useTranslations } from 'shared/hooks';

export const ListItemText: React.FC<ListItemTextProps> = ({ children }) => {
  const { message } = useTranslations(children);

  return <MaterialListItemText>{message}</MaterialListItemText>;
};
