import React, { useState, useEffect, useRef } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import Popper from '@material-ui/core/Popper';
import clsx from 'clsx';

import { connectControl } from '../connectControl/connectControl';
import { controlBase } from '../controlBase/ControlBase';
import { Typography } from '../../typography/Typography';

import { useOnClickOutside } from 'shared/hooks';
import {
  Preview,
  PreviewColor,
  PreviewHex,
  PreviewHexEmpty,
  ColorPickerWrapper,
  useStyles,
} from './ColorPicker.styles';
import { ColorPickerProps } from './ColorPicker.types';

export const ColorPickerBase: React.FC<ColorPickerProps> = ({ onChange, value = '', onFocus, onBlur }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const wrapperRef = useRef<HTMLDivElement | null>();
  const [color, setColor] = useState<string>(value);

  const open = !!anchorEl;

  const handleOpen = (element: HTMLElement) => {
    setAnchorEl(element);

    if (onFocus) {
      onFocus();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);

    if (onBlur) {
      onBlur();
    }
  };

  const handleColorChange = ({ hex }: ColorResult) => setColor(hex);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      handleClose();
    }

    handleOpen(event.currentTarget);
  };

  useOnClickOutside(handleClose, wrapperRef.current);

  useEffect(() => {
    if (typeof onChange === 'function' && color !== '') {
      onChange(color);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  useEffect(() => {
    if (value !== color) {
      setColor(value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const styles = useStyles();
  const previewClassNames = clsx({
    [styles.open]: open,
  });

  return (
    <>
      <Preview className={previewClassNames} onClick={handleClick}>
        <PreviewColor color={color} data-testid="color-preview" />
        {color ? (
          <PreviewHex data-testid="hex-preview">{color}</PreviewHex>
        ) : (
          <PreviewHexEmpty>
            <Typography variant="caption">control.color_picker.pick</Typography>
          </PreviewHexEmpty>
        )}
      </Preview>
      <Popper open={open} anchorEl={anchorEl} transition style={{ zIndex: 99 }}>
        <ColorPickerWrapper
          ref={ref => {
            wrapperRef.current = ref;
          }}
        >
          <ChromePicker color={color} onChange={handleColorChange} />
        </ColorPickerWrapper>
      </Popper>
    </>
  );
};

export const ColorPicker = controlBase<ColorPickerProps>(ColorPickerBase);
// @ts-ignore
export const ColorPickerField = connectControl<ColorPickerProps>(ColorPicker);
