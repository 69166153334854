import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import { Separator, PageAlert } from 'shared/components';
import { Props } from './Form.types';

export const Form: <T extends any>(props: Props<T>) => React.ReactElement = ({
  errorAlert = true,
  errorSeparator = 68,
  ...props
}) => {
  return (
    <FinalForm
      {...props}
      render={formProps => {
        const { submitError, hasSubmitErrors, dirtySinceLastSubmit } = formProps;

        const showError = !dirtySinceLastSubmit && hasSubmitErrors && submitError;
        const error = showError && (
          <>
            <PageAlert severity="error">{submitError}</PageAlert>
            <Separator height={errorSeparator} />
          </>
        );

        return (
          <>
            {errorAlert && error}
            {props.render({ ...formProps, errorAlert: error })}
          </>
        );
      }}
    />
  );
};
