import { TablePagination, withStyles } from '@material-ui/core';

export const StyledPagination = withStyles({
  root: {
    opacity: 0.54,
  },
  caption: {
    fontSize: 12,
  },
  select: {
    fontSize: 12,
  },
  selectIcon: {
    color: '#000',
  },
})(TablePagination);
