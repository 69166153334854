import { Action, useMutation } from 'react-fetching-library';

import { isMsBrowser } from 'shared/utils/isMsBrowser/isMsBrowser';

const saveFile = (data: Blob | { url: string }, fileName: string) => {
  const a = document.createElement('a');

  if (data instanceof Blob) {
    if (isMsBrowser) {
      window.navigator.msSaveBlob(data, fileName);

      return;
    }

    const url = window.URL.createObjectURL(data);
    a.href = url;
  } else {
    a.href = data.url;
  }

  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const useDownload = (action: Action, defaultFileName: string = new Date().toISOString()) => {
  const { mutate, loading, error, abort } = useMutation(action);

  return {
    loading,
    error,
    download: async (values: any, fileName?: string) => {
      const result = await mutate(values);
      const { payload, error: mutationError } = result;

      if (mutationError) {
        return result;
      }

      saveFile(payload, fileName || defaultFileName);

      return result;
    },
    downloadAbort: abort,
  };
};
