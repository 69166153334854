import React from 'react';
import { useMutation } from 'react-fetching-library';
import { FORM_ERROR, FormApi } from 'final-form';
import omit from 'lodash/omit';

import { useInitialValues } from '../initialValues/useInitialValues/useInitialValues';

import { Form } from 'shared/components';
import { SectionContainerProps, FormValues } from './Section.types';
import { Section } from './Section';
import { updateClipTemplates } from 'api/actions/clipTemplate/clipTemplateActions';

const omitKeys = ['variant', 'fonts', 'songs', 'type'];

export const SectionContainer: React.FC<SectionContainerProps> = ({ children, ...props }) => {
  const { mutate } = useMutation(updateClipTemplates);
  const initialValues = useInitialValues();
  const { initial: initialVariant } = initialValues;
  const { step } = props;

  const onSubmit = async (values: FormValues, { reset }: FormApi<FormValues>) => {
    const { error, payload } = await mutate({
      [step]: { ...omit(values, omitKeys), accepted: true },
    });

    if (error) {
      return { [FORM_ERROR]: payload.message || 'error.unknown' };
    }

    const acceptedVariant = values.variant === 'none' ? initialVariant : (values.variant as 'default' | 'custom');

    setTimeout(() => {
      reset({ ...initialValues[acceptedVariant], variant: acceptedVariant, ...payload[step] });
    });

    return undefined;
  };

  return (
    <section data-testid={`clip-template-section-${step}`} id={step}>
      <Form<FormValues>
        initialValues={initialValues[initialVariant]}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <Section {...props} handleSubmit={handleSubmit}>
            {children}
          </Section>
        )}
      />
    </section>
  );
};
