import React from 'react';

import { InlineSkeleton } from '../EventDetails.styles';
import { EventParticipantsListContainer } from '../participantsList/EventParticipantsListContainer';

import { PageTemplate } from 'shared/templates/page/PageTemplate';
import { Message } from 'shared/templates/page/PageTemplate.types';
import { Skeleton } from 'shared/components/skeleton/Skeleton';
import { useLocale } from 'shared/hooks/useLocale/useLocale';
import { GridContainer, GridItem } from 'shared/components/grid';
import { PageSection } from 'shared/components/pageSectionStatic/PageSection';
import { Separator } from 'shared/components/separator/Separator';
import { EventDetailsPlaceholderProps } from './EventDetailsPlaceholder.types';

export const EventDetailsPlaceholder = ({ error }: EventDetailsPlaceholderProps) => {
  const { formatMessage } = useLocale();

  const message: Message | undefined = error
    ? {
        content: formatMessage(
          { id: 'error_page.content' },
          { email: formatMessage({ id: 'static.support_email' }) as string },
        ) as string,
        color: 'error',
      }
    : undefined;

  const title = formatMessage(
    {
      id: 'event_details.page.title',
    },
    {
      name: <InlineSkeleton width={300} />,
      type: <InlineSkeleton width={150} />,
    },
  ) as string;

  const subtitle = formatMessage(
    {
      id: 'event_details.page.subtitle',
    },
    {
      date: <InlineSkeleton width={150} />,
      timezone: <InlineSkeleton width={100} />,
    },
  ) as string;

  return (
    <PageTemplate title={title} subtitle={subtitle} message={message}>
      <GridContainer>
        <GridItem xs={5}>
          <PageSection title={<InlineSkeleton width={160} />}>
            <PageSection.Body>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </PageSection.Body>
          </PageSection>
        </GridItem>
      </GridContainer>
      <Separator height={40} />
      <EventParticipantsListContainer
        data={null}
        eventType={null}
        updateParticipantData={() => undefined}
        deleteParticipant={() => undefined}
        addParticipant={() => undefined}
      />
    </PageTemplate>
  );
};
