import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-fetching-library';
import { FORM_ERROR } from 'final-form';
import { pickBy, omitBy, isEmpty } from 'lodash';

import { fetchSurveyTemplates, updateSurveyTemplates } from 'api/actions/surveyTemplate/surveyTemplateActions';
import { SurveyTemplateData } from 'api/actions/surveyTemplate/surveyTemplateActions.types';
import { ClientDefaultsController } from 'context/clientDefaults/clientDefaultsController';
import { SurveyTemplate } from './SurveyTemplate';
import { StorageDataProvider } from './SurveyTemplate.storage';
import { scroll } from 'shared/utils/scroll/scroll';

export const SurveyTemplateContainer: React.FC = () => {
  const [values, setValues] = useState<SurveyTemplateData>();
  const [accepted, setAccepted] = useState(false);
  const { loading, payload } = useQuery<SurveyTemplateData>(fetchSurveyTemplates);
  const { mutate } = useMutation(updateSurveyTemplates);

  useEffect(() => {
    setValues(payload);
    setAccepted(!!payload?.style?.logo && !!payload?.style?.color && !!payload?.survey);
  }, [payload]);

  useEffect(() => {
    if (accepted) {
      scroll();
    }
  }, [accepted]);

  const handleSubmit = useCallback(
    async (body: SurveyTemplateData) => {
      const { error: submitError, payload: submitPayload } = await mutate(
        omitBy(
          {
            style: pickBy(body.style || {}),
            survey: body.survey,
          },
          isEmpty,
        ),
      );

      if (submitError) {
        return { [FORM_ERROR]: submitPayload?.message || 'error.unknown' };
      }

      setValues(submitPayload);
      setAccepted(!!submitPayload?.style?.logo && !!submitPayload?.style?.color && !!submitPayload?.survey);

      return undefined;
    },
    [mutate],
  );

  return (
    <ClientDefaultsController>
      <StorageDataProvider>
        <SurveyTemplate accepted={accepted} initialValues={values} loading={loading} onSubmit={handleSubmit} />
      </StorageDataProvider>
    </ClientDefaultsController>
  );
};
