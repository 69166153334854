import React, { Fragment } from 'react';

import { Typography } from 'shared/components/typography/Typography';
import { Separator } from 'shared/components/separator/Separator';
import { Item } from './item/Item';
import { Wrapper } from './Specification.styles';
import { SpecificationProps } from './Specification.types';
import { ItemProps } from './item/Item.types';

const isItem = (value: any): value is ItemProps => value && typeof value.label === 'string';

export const Specification: React.FC<SpecificationProps> = ({ options, title }) => (
  <>
    {title && (
      <>
        <Typography variant="h4">{title}</Typography>
        <Separator height={24} />
      </>
    )}
    <Wrapper>
      {options.map((option, index) => {
        if (isItem(option)) {
          return <Item {...option} key={option.label} />;
        }

        // eslint-disable-next-line react/no-array-index-key
        return <Fragment key={index}>{option}</Fragment>;
      })}
    </Wrapper>
  </>
);
