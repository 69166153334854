import React from 'react';

import { useFormControlState } from '../useFormControlState/useFormControlState';

import { useTranslations } from 'shared/hooks';
import { Typography } from 'shared/components/typography/Typography';

export const Description: React.FC = ({ children }) => {
  const { message } = useTranslations(children);
  const { hiddenLabel } = useFormControlState();

  if (hiddenLabel) {
    return null;
  }

  return <Typography variant="caption">{message}</Typography>;
};
