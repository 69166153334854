import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

import { Alert } from 'shared/components/alert/Alert';
import { useNotifications, useUpdateNotifications } from './notifications.context';
import { NotificationsProps, Notification } from './Notifications.types';

// todo: implement maxStack > 1
export const Notifications = ({ autoHideDuration, maxStack = 1 }: NotificationsProps) => {
  const notifications = useNotifications();
  const updateNotifications = useUpdateNotifications();

  const setNotifications = (callback: (oldNotifications: Notification[]) => Notification[]) => {
    updateNotifications(prev => callback(prev || []));
  };

  const handleRemove = () => setNotifications(oldNotifications => oldNotifications.slice(1));
  const handleOnClose = (id: Notification['id']) =>
    setNotifications(oldNotifications =>
      oldNotifications.map(notification => ({
        ...notification,
        open: id === notification.id ? false : notification.open,
      })),
    );

  return (
    <>
      {notifications.slice(0, maxStack).map(notification => (
        <Snackbar
          open={notification.open}
          onClose={() => handleOnClose(notification.id)}
          key={notification.id}
          autoHideDuration={autoHideDuration}
          onExited={handleRemove}
        >
          <Alert onClose={() => handleOnClose(notification.id)} severity={notification.severity}>
            {notification.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};
