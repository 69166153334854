import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { Loader } from 'shared/components';
import { useAuthState } from 'shared/hooks';

export const AuthorizedRoute: React.FC<RouteProps> = props => {
  const { isAuthorized, isAuthorizing } = useAuthState();

  if (isAuthorizing) {
    return <Loader fullScreen />;
  }

  if (isAuthorized) {
    return <Route {...props} />;
  }

  return <Redirect to="/login" />;
};
