import React, { memo } from 'react';
import { IconButton } from '@material-ui/core';
import { Field } from 'react-final-form';

import { options } from '../SurveyQuestion.constants';
import { renderValue } from '../renderValue/renderValue';
import { SurveyQuestionHeaderColumn } from '../headerColumn/SurveyQuestionHeaderColumn';

import { GridContainer, GridItem, Icon } from 'shared/components';
import { SelectField } from 'shared/components/controls';
import { SurveyQuestionProps } from './SurveyQuestionHeader.types';

export const SurveyQuestionHeader: React.FC<SurveyQuestionProps> = memo(({ field, onDelete }) => {
  return (
    <GridContainer alignItems="center" justify="space-between">
      <GridItem xs={12} md={8} lg={5}>
        <SelectField name={`${field}.type`} options={options} renderValue={renderValue} displayEmpty fullWidth />
      </GridItem>
      <GridItem xs={12} md={8} lg={5}>
        <Field
          name={`${field}.type`}
          subscription={{ value: true }}
          field={field}
          component={SurveyQuestionHeaderColumn}
        />
      </GridItem>
      <GridItem>
        <IconButton type="button" onClick={onDelete}>
          <Icon icon="Delete" />
        </IconButton>
      </GridItem>
    </GridContainer>
  );
});
