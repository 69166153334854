import { useCallback, useState } from 'react';
import { useField } from 'react-final-form';

export const useLogoDialog = (fieldName: string) => {
  const [isDialogOpened, setDialogVisibility] = useState(false);

  const {
    input: { onChange },
  } = useField(fieldName, { subscription: {} });

  const handleDialogCancel = useCallback(() => {
    setDialogVisibility(false);
  }, [setDialogVisibility]);

  const handleDialogConfirm = useCallback(
    (file: File) => {
      onChange(file);
      setDialogVisibility(false);
    },
    [onChange, setDialogVisibility],
  );

  const open = useCallback(() => {
    setDialogVisibility(true);
  }, [setDialogVisibility]);

  return {
    isDialogOpened,
    handleDialogCancel,
    handleDialogConfirm,
    open,
  };
};
