import React from 'react';
import { useField } from 'react-final-form';

import { FontLoader } from '../../fontLoader/FontLoader';

import { Center, Wrapper, Logo, Text } from './PreviewRegular.styles';
import { AspectRatio } from 'shared/components/aspectRatio/AspectRatio';

// clip generator dimensions
const MAX_HEIGHT = 320;
const MAX_WIDTH = 800;

// K = fixed container width / clip generator frame width
const K = 422 / 1280;
const calculateDimensions = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
  const element = e.currentTarget;
  element.style.width = 'auto';
  element.style.height = 'auto';

  const { width, height } = element.getBoundingClientRect();

  const widthRatio = MAX_WIDTH / width;
  const heightRatio = MAX_HEIGHT / height;

  const isTooBig = width > MAX_WIDTH || height > MAX_HEIGHT;
  const scaleRatio = widthRatio > heightRatio ? heightRatio : widthRatio;
  const finalRatio = isTooBig ? scaleRatio : 1;

  const finalWidth = width * finalRatio * K;
  const finalHeight = height * finalRatio * K;

  element.style.width = `${finalWidth}px`;
  element.style.height = `${finalHeight}px`;
};

const getFilePreview = (file: FileList | string) => {
  if (typeof file === 'string') {
    return file;
  }

  if (file) {
    return URL.createObjectURL(file);
  }

  throw new Error('invalid file');
};

export const PreviewRegular: React.FC = () => {
  const {
    input: { value: background },
  } = useField('background', { subscription: { value: true } });

  const {
    input: { value: header },
  } = useField('header', { subscription: { value: true } });

  const {
    input: { value: logo },
  } = useField('logo', { subscription: { value: true } });

  const {
    input: { value: fonts = [] },
  } = useField('fonts', { subscription: { value: true } });

  return (
    <Center>
      <AspectRatio ratio={16 / 9}>
        <Wrapper color={background}>
          <FontLoader />
          <Logo src={getFilePreview(logo)} alt="logo" onLoad={calculateDimensions} />
          <Text
            color={header.color}
            style={{ fontFamily: (fonts.find(({ url }) => url === header.font) || { name: '' }).name }}
            data-testid="header.text"
          >
            {header.text}
          </Text>
        </Wrapper>
      </AspectRatio>
    </Center>
  );
};
