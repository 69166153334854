import React from 'react';
import { useField } from 'react-final-form';

import { getFileName } from '../../initialValues/common';

import { Player } from 'shared/components/player/Player';

export const PreviewAudio: React.FC = () => {
  const {
    input: { value: file },
  } = useField('file', { subscription: { value: true } });

  return <Player playlist={[{ src: file, title: getFileName(file) || 'clip_template.audio.default_music' }]} />;
};
