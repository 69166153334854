import React from 'react';
import { FormApi } from 'final-form';
import { parseISO, isAfter, formatDistanceToNow, subHours } from 'date-fns';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-fetching-library';
import addDays from 'date-fns/addDays';

import { Participant } from 'api/actions/events/eventsActions.types';
import { Dialog } from 'shared/components/dialog/Dialog';
import { Button } from 'shared/components/button/Button';
import { Video } from 'shared/components/video/Video';
import { Image } from 'shared/components/image/Image';
import { Separator } from 'shared/components/separator/Separator';
import { handleOnSubmit } from 'shared/utils';
import { handleRequest } from 'shared/utils/handleRequest/handleRequest';
import { Alert, Form } from 'shared/components';
import { SectionWrapper, SectionTitle, useStyles, ModalForm, Footer } from './EventParticipantUploadsPreview.styles';
import { EventParticipantUploadsPreviewProps } from './EventParticipantUploadsPreview.types';
import { ParticipantUploadActionEnum } from 'api/actions/participants/participantsActions.types';
import { createGetParticipantUploadLink } from 'api/actions/participants/participantsActions';

const Section: React.FC<{ title: string }> = ({ children, title }) => {
  return (
    <SectionWrapper>
      <SectionTitle>{title}</SectionTitle>
      <Separator height={10} />
      {children}
    </SectionWrapper>
  );
};

const changeStatus = (value: ParticipantUploadActionEnum, form: FormApi) => form.change('status', value);

const deadlineWarningMaxDiff = 24; // hours

const DeadlineInfo = ({ eventDeadlineDate }: { eventDeadlineDate: Date }) => {
  const { formatMessage } = useIntl();

  if (isAfter(new Date(), eventDeadlineDate)) {
    return (
      <>
        <Alert severity="error">{formatMessage({ id: 'event_details.preview.past_deadline' })}</Alert>
        <Separator height={24} />
      </>
    );
  }

  const deadlineWarningDate = subHours(eventDeadlineDate, deadlineWarningMaxDiff);

  if (isAfter(new Date(), deadlineWarningDate)) {
    return (
      <>
        <Alert severity="warning">
          {formatMessage(
            {
              id: 'event_details.preview.close_to_deadline',
            },
            { timeLeft: formatDistanceToNow(eventDeadlineDate) },
          )}
        </Alert>
        <Separator height={24} />
      </>
    );
  }

  return null;
};

export const EventParticipantUploadsPreview = ({
  eventDeadlineDate,
  data,
  onClose,
  onSubmit,
  updateParticipantData,
}: EventParticipantUploadsPreviewProps) => {
  const styles = useStyles();

  const { mutate: getUploadLinkQuery } = useMutation(createGetParticipantUploadLink);

  const getUploadLink = (participantId: string) => {
    handleRequest<{ url: string }>({
      action: () => getUploadLinkQuery(participantId),
      onSuccess: payload => {
        window.open(payload.url, '_blank', 'noopener noreferrer');
      },
    });
  };

  const deadlineDate = parseISO(eventDeadlineDate);

  if (!data || !data.virtualGraduation) {
    return null;
  }

  const {
    virtualGraduation: { file, image, message, state },
  } = data;

  const submitForm = handleOnSubmit({
    action: values => onSubmit({ participantId: data.id, ...values }),
    successCallback: (response: Participant) => {
      updateParticipantData(response);
      onClose();
    },
  });

  const isAfterDeadline = !!deadlineDate && isAfter(new Date(), addDays(deadlineDate, 3));
  const canAcceptSubmission = !isAfterDeadline && state === 'submitted';
  const canEditSubmission = !isAfterDeadline;
  const canRejectSubmission = !isAfterDeadline && ['submitted', 'accepted'].includes(state);

  return (
    <Dialog open>
      <Dialog.Body>
        <DeadlineInfo eventDeadlineDate={deadlineDate} />
        {!!file && (
          <Section title="event_details.preview.video">
            <Video src={file} />
            <Separator height={30} />
          </Section>
        )}
        {!!image && (
          <Section title="event_details.preview.image">
            <Image src={image} alt="participant image" />
            <Separator height={30} />
          </Section>
        )}
        {!!message && <Section title="event_details.preview.message">{message}</Section>}
      </Dialog.Body>
      <Footer>
        <Form
          errorSeparator={32}
          onSubmit={submitForm}
          render={({ handleSubmit, form }) => (
            <ModalForm onSubmit={handleSubmit}>
              <div className={styles.buttons}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => changeStatus(ParticipantUploadActionEnum.Accept, form)}
                    type="submit"
                    disabled={!canAcceptSubmission}
                  >
                    event_details.preview.accept
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => changeStatus(ParticipantUploadActionEnum.Reject, form)}
                    type="submit"
                    disabled={!canRejectSubmission}
                  >
                    event_details.preview.reject
                  </Button>
                  <Button variant="outlined" onClick={() => getUploadLink(data.id)} disabled={!canEditSubmission}>
                    event_details.preview.edit
                  </Button>
                </div>
                <div>
                  <Button variant="text" onClick={() => onClose()}>
                    event_details.preview.close
                  </Button>
                </div>
              </div>
            </ModalForm>
          )}
        />
      </Footer>
    </Dialog>
  );
};
