import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import Input from '@material-ui/core/Input';

import { Icon } from 'shared/components/icon/Icon';
import { Tooltip } from 'shared/components/tooltip/Tooltip';
import { useDebounce } from 'shared/hooks/useDebounce/useDebounce';
import { useLocale } from 'shared/hooks/useLocale/useLocale';
import { StyledToolbar, useStyles } from './Toolbar.styles';
import { ToolbarProps } from './Toolbar.types';
import { ColumnsDialog } from './columnsDialog/ColumnsDialog';
import { FiltersDialog } from './filterDialog/FiltersDialog';
import { FiltersPreview } from './filtersPreview/FiltersPreview';

export const Toolbar: React.FC<ToolbarProps> = ({
  columns,
  toggleColumn,
  filtersDialog,
  filters,
  onFilterChange,
  onSearchChange,
  search,
  data,
}) => {
  const styles = useStyles();
  const [searchExpand, setSearchExpand] = useState(false);
  const [searchText, setSearchText] = useState();
  const debounceSearch = useDebounce(searchText, 300);
  const { formatMessage } = useLocale();

  useEffect(() => {
    if (searchText !== undefined) {
      onSearchChange(debounceSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch, data]);

  useEffect(() => {
    if (search && !searchExpand && searchText) {
      setSearchText('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchExpand]);

  return (
    <StyledToolbar>
      <div className={clsx(styles.row, styles.options)}>
        {search && (
          <>
            <Tooltip title={searchExpand ? 'table.search.hide.tooltip' : 'table.search.show.tooltip'}>
              <IconButton onClick={() => setSearchExpand(old => !old)}>
                <Icon icon="Search" />
              </IconButton>
            </Tooltip>
            <Input
              placeholder={formatMessage({ id: 'table.search.placeholder' }) as string}
              className={clsx(searchExpand ? styles.expanded : styles.none, styles.input)}
              onChange={event => setSearchText(event.target.value)}
              value={searchText || ''}
            />
          </>
        )}

        <ColumnsDialog columns={columns} toggleColumn={toggleColumn} />

        {filtersDialog && (
          <FiltersDialog filters={filters} columns={columns}>
            {filtersDialog}
          </FiltersDialog>
        )}
      </div>
      <div className={styles.row}>
        <FiltersPreview filters={filters} columns={columns} onFilterChange={onFilterChange} />
      </div>
    </StyledToolbar>
  );
};
