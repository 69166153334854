import { Action } from 'react-fetching-library';

export const fetchEvents: Action = {
  method: 'GET',
  endpoint: '/client-portal/events',
};

export const createFetchEventDetails: (eventId: string) => Action = eventId => ({
  method: 'GET',
  endpoint: `/client-portal/events/${eventId}`,
});

export const fetchEventParticipants: (eventId: string) => Action = eventId => ({
  method: 'GET',
  endpoint: `/client-portal/events/${eventId}/participants`,
});
