import React from 'react';
import { RadioGroup as MaterialRadioGroup } from '@material-ui/core';

import { Radio } from '../radio/Radio';

import { RadioGroupProps } from './RadioGroup.types';

export const RadioGroup: React.FC<RadioGroupProps> = ({ options, onChange, name, value }) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, nextValue: string) => {
    if (onChange) {
      onChange(nextValue);
    }
  };

  return (
    <MaterialRadioGroup name={name} onChange={handleOnChange}>
      {options.map(option => (
        <Radio checked={value === option.value} value={option.value} label={option.label} key={option.value} />
      ))}
    </MaterialRadioGroup>
  );
};
