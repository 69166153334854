import React from 'react';

import { Container, GridContainer, GridItem } from 'shared/components';
import { useStyles } from './SidebarTemplate.styles';
import { SidebarTemplateProps } from './SidebarTemplate.types';

export const SidebarTemplate: React.FC<SidebarTemplateProps> = ({ sidebar, content }) => {
  const styles = useStyles();

  return (
    <GridContainer className={styles.root} disableVerticalMargins spacing={0}>
      <GridItem xs className={styles.left} />
      <Container maxWidth="lg" disableGutters>
        <GridContainer fullHeight>
          <GridItem xs={4} className={styles.sidebarWrapper}>
            <GridContainer fullHeight>
              <GridItem xs className={styles.sidebarContent}>
                {sidebar}
              </GridItem>
              <GridItem xs={1} />
            </GridContainer>
          </GridItem>
          <GridItem xs={8}>{content}</GridItem>
        </GridContainer>
      </Container>
      <GridItem xs />
    </GridContainer>
  );
};
