import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette }) => ({
  icon: {
    color: palette.black.main,
  },
  alert: {
    padding: 0,
    border: 0,
  },
}));
