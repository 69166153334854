import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link } from '@material-ui/core';

import { useFormStorage, useAcceptedState } from '../EmailTemplate.storage';

import { Typography, Image, BlobImage, Button, Separator } from 'shared/components';
import { Switch } from 'shared/components/controls';
import { Title, FinalLookWrapper, Wrapper, Header, Content, Footer, Line, Highlight } from './Preview.styles';
import { PageSection } from 'shared/components/pageSection/PageSection';

export const Preview: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const { content, header } = useFormStorage() || {};
  const { logo, color } = header || {};
  const { organisationName, text } = content || {};
  const { content: isContentAccepted, header: isHeaderAccepted } = useAcceptedState() || {};

  const { formatMessage } = useIntl();
  const HighlightWrapper = checked ? 'span' : Highlight;

  const allAccepted = isContentAccepted && isHeaderAccepted;

  useEffect(() => {
    if (allAccepted) {
      setChecked(true);
    }
  }, [allAccepted]);

  return (
    <PageSection
      title={
        <Title>
          <Typography variant="h3">email_template.sections.preview</Typography>
          <FinalLookWrapper>
            <Switch checked={checked} onChange={setChecked} />
            <Typography variant="body1">email_template.preview.final_look</Typography>
          </FinalLookWrapper>
        </Title>
      }
    >
      <PageSection.Body>
        <Wrapper>
          <Header color={color as any}>
            {!!logo &&
              (typeof logo === 'string' ? (
                <Image src={logo} alt="logo" />
              ) : (
                <BlobImage alt="logo" src={(logo as unknown) as File} />
              ))}
          </Header>
          <Content>
            <Separator height={40} />
            <Typography variant="h3">email_template.preview.content.title</Typography>
            <Separator height={40} />
            <Typography variant="body1">
              {formatMessage(
                { id: 'email_template.preview.content.main' },
                { university: <HighlightWrapper>{organisationName}</HighlightWrapper> },
              )}
            </Typography>
            <Separator height={30} />
            <Button variant="contained" color="secondary">
              email_template.preview.content.show_my_clip
            </Button>
            <Separator height={40} />
            <Typography variant="body1">
              <HighlightWrapper>{text}</HighlightWrapper>
            </Typography>
            <Separator height={50} />
            <div>
              <Typography variant="body1">email_template.preview.content.powered_by</Typography>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link href="#">StageClip</Link>
            </div>
            <Separator height={50} />
          </Content>

          <Footer>
            <Typography variant="caption">email_template.preview.footer.need_help</Typography>
            <Separator height={30} />
            <Line />
            <Separator height={30} />
            <Typography variant="caption">
              {formatMessage({ id: 'email_template.preview.footer.copyright' }, { year: new Date().getFullYear() })}
            </Typography>
            <Separator height={30} />
            <Typography variant="caption">email_template.preview.footer.unsubscribe</Typography>
          </Footer>
        </Wrapper>
      </PageSection.Body>
    </PageSection>
  );
};
