import React, { useMemo } from 'react';
import { useField } from 'react-final-form';

import { CustomTemplate } from '../customTemplate/CustomTemplate';
import { Font } from '../defaultTemplate/DefaultTemplate.types';
import { FontLabel } from '../fontSelect/FontSelect.styles';

import { Option } from 'shared/components/specification/Specification.types';
import { FileDropZoneFieldProps } from 'shared/components/controls/fileDropZone/FileDropZone.types';
import { ColorPickerField, SelectField } from 'shared/components/controls';
import { ImagePreview } from './ImagePreview/ImagePreview';

const allowedExtensions = ['png'];

const fileDropZoneProps: FileDropZoneFieldProps = { maxSize: '10 MB', disabled: true, hideUploadButton: true };

export const OverlayTemplate: React.FC = () => {
  const {
    input: { value: fontsList },
  } = useField<Font[]>('fonts', { subscription: { value: true } });

  const fonts = useMemo(
    () =>
      fontsList.map(({ url, name }: Font) => ({
        label: <FontLabel fontFamily={name}>{name}</FontLabel>,
        value: url,
      })),
    [fontsList],
  );

  const rules: Option[] = useMemo(
    () => [
      {
        label: 'text colour',
        icon: 'PaletteOutlined',
        content: <ColorPickerField name="color" hiddenError hiddenLabel />,
      },
      {
        label: 'clip_template.specification.font_family',
        icon: 'TextFields',
        content: <SelectField options={fonts} name="font" hiddenError hiddenLabel />,
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <CustomTemplate
      allowedExtensions={allowedExtensions}
      specificationRules={rules}
      fileDropZoneProps={fileDropZoneProps}
      info="clip_template.overlay.info"
      previews={{ image: ImagePreview }}
      specificationTitle="clip_template.overlay.specification.title"
    />
  );
};
