import { Action } from 'react-fetching-library';

import { SurveyTemplateData } from './surveyTemplateActions.types';

export const fetchSurveyTemplates: Action = {
  method: 'GET',
  endpoint: '/client-portal/survey-templates',
};

export const updateSurveyTemplates: Action = (body: SurveyTemplateData) => ({
  method: 'PATCH',
  endpoint: '/client-portal/survey-templates',
  body,
});
