import { createContext } from 'react';

import { Step } from '../Stepper.types';

type StepperStateContextType = {
  step: Step;
  setStep: (step: Step) => void;
  goNext: () => void;
  goBack: () => void;
  isFirst: boolean;
  isLast: boolean;
  reset: () => void;
};

export const StepperStateContext = createContext<StepperStateContextType | undefined>(undefined);
