import React from 'react';

import { Button, GridItem, GridContainer, Form, Separator } from 'shared/components';
import { Typography } from 'shared/components/typography/Typography';
import { required, confirmation, length, password } from 'shared/validators';
import { InputField } from 'shared/components/controls';
import { useStyles } from './SetPassword.styles';
import { SetPasswordProps } from './SetPassword.types';
import { SetPasswordFormValues } from 'api/actions/auth/auth.types';

export const SetPasswordForm: React.FC<SetPasswordProps> = ({ onSubmit, initialValues }) => {
  const styles = useStyles();

  return (
    <>
      <Typography variant="h2">set_password.form.title</Typography>
      <Typography variant="caption">set_password.form.subtitle</Typography>
      <Separator height={68} />
      <Form<SetPasswordFormValues>
        onSubmit={onSubmit}
        initialValues={initialValues as SetPasswordFormValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <GridItem xs={9}>
              <GridContainer spacing={0}>
                <GridItem xs={9}>
                  <InputField
                    name="password"
                    type="password"
                    label="set_password.form.password_input.label"
                    description="set_password.form.password_input.description"
                    fullWidth
                    validate={[required(), length({ min: 8, msg: 'validation.password.length' }), password()]}
                  />
                </GridItem>
                <GridItem xs={3} />
              </GridContainer>
              <Separator height={37} />
              <GridContainer spacing={0}>
                <GridItem xs={9}>
                  <InputField
                    name="repeat_password"
                    type="password"
                    label="set_password.form.repeat_input.label"
                    fullWidth
                    validate={[
                      required(),
                      confirmation({
                        field: 'password',
                        fieldLabel: 'Password',
                      }),
                    ]}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>

            <div className={styles.buttons}>
              <Button color="primary" to="/login">
                set_password.form.sign_in
              </Button>
              <Button variant="contained" color="primary" type="submit">
                set_password.form.continue
              </Button>
            </div>
          </form>
        )}
      />
    </>
  );
};
