import React from 'react';
import { useMutation } from 'react-fetching-library';

import { EventParticipantUploadsPreview } from './EventParticipantUploadsPreview';
import { EventParticipantUploadsPreviewContainerProps } from './EventParticipantUploadsPreview.types';
import { createParticipantUploadStatus } from 'api/actions/participants/participantsActions';

export const EventParticipantUploadsPreviewContainer: React.FC<EventParticipantUploadsPreviewContainerProps> = ({
  eventDeadlineDate,
  data,
  onClose,
  updateParticipantData,
}) => {
  const { mutate } = useMutation(createParticipantUploadStatus);

  return (
    <EventParticipantUploadsPreview
      eventDeadlineDate={eventDeadlineDate}
      data={data}
      onClose={onClose}
      onSubmit={mutate}
      updateParticipantData={updateParticipantData}
    />
  );
};
