import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Config } from './useAutoHashScroll.types';

export const useAutoHashScroll = ({ enabled = true, delay = 400, offset = 10, behavior = 'smooth' }: Config = {}) => {
  const { hash } = useLocation();
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (enabled && hash) {
      timeout.current = setTimeout(() => {
        const element = document.querySelector(hash);

        if (element) {
          const top = element.getBoundingClientRect().top + window.pageYOffset - offset;

          return window.scroll({ top, behavior });
        }

        throw Error(`Cannot find element with [id=${hash.replace('$', '')}]`);
      }, delay);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);
};
