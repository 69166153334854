import { useState } from 'react';

import { Config, Column } from './useColumnsState.types';

export const useColumnsState = ({ initialColumns }: Config) => {
  const [columns, setColumns] = useState<Column[]>(initialColumns.map(column => ({ visible: true, ...column })));

  const toggle = (field: string) => {
    setColumns(prev =>
      prev.map(column => {
        if (column.field === field) {
          return { ...column, visible: !column.visible };
        }

        return column;
      }),
    );
  };

  const visible = columns.filter(column => column.visible);

  return { columns, toggle, visible };
};
