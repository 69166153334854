import { withStyles, Dialog } from '@material-ui/core';

export const modalPadding = 24;

export const StyledDialog = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    minWidth: '30%',
  },
  paper: {
    width: '100%',
    padding: `${modalPadding}px 0`,
  },
})(Dialog);
