import React, { ProviderProps } from 'react';

import { AuthStateContext, AuthDispatchContext } from '../authContext/authContext';
import { AuthStateContextType, AuthDispatchContextType } from '../authContext/authContext.types';

export const AuthStateProvider: React.FC<ProviderProps<AuthStateContextType>> = ({ children, value }) => (
  <AuthStateContext.Provider value={value}>{children}</AuthStateContext.Provider>
);

export const AuthDispatchProvider: React.FC<ProviderProps<AuthDispatchContextType>> = ({ children, value }) => (
  <AuthDispatchContext.Provider value={value}>{children}</AuthDispatchContext.Provider>
);
