import React from 'react';
import { format } from 'date-fns';

import { ParticipantAuditLogDialogProps } from './ParticipantAuditLog.types';
import { Column } from 'shared/components/table/Table.types';
import { Dialog, Table } from 'shared/components';

export const ParticipantAuditLogDialog = ({
  data,
  open,
  onClose,
  participantFullName,
}: ParticipantAuditLogDialogProps) => {
  const columns: Column[] = [
    { title: 'Action', field: 'action' },
    { title: 'User', field: 'user', formatter: user => (user ? `${user.firstName} ${user.lastName}` : 'auto') },
    { title: 'Timestamp', field: 'timestamp', formatter: timestamp => format(new Date(timestamp), 'yyyy-MM-dd HH:mm') },
  ];

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <Dialog.Title onClose={onClose}>Participant audit log - {participantFullName}</Dialog.Title>
      <Dialog.Body>
        <Table data={data} columns={columns} />
      </Dialog.Body>
    </Dialog>
  );
};
