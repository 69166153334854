import React from 'react';

import { Button, Dialog } from 'shared/components';
import { useLocale } from 'shared/hooks';
import { ParticipantDeleteDialogProps } from './ParticipantDelete.types';

export const ParticipantDeleteDialog = ({
  open,
  onClose,
  deleteParticipant,
  participant,
}: ParticipantDeleteDialogProps) => {
  const { formatMessage } = useLocale();

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Title>delete_participant.title</Dialog.Title>
      <Dialog.Body>
        {formatMessage({ id: 'delete_participant.message' }, { fullName: <strong>{participant?.fullName}</strong> })}
      </Dialog.Body>
      <Dialog.Footer>
        <Button variant="contained" color="primary" onClick={onClose}>
          delete_participant.no
        </Button>
        <Button variant="outlined" onClick={deleteParticipant}>
          delete_participant.yes
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};
