import React from 'react';
import { useMutation } from 'react-fetching-library';

import { ParticipantAddEditDialog } from '../ParticipantAddEditDialog';

import { createUpdateParticipant } from 'api/actions/participants/participantsActions';
import { ParticipantEditContainerProps } from './ParticipantEditContainer.types';
import { ParticipantEditValues } from 'api/actions/participants/participantsActions.types';

export const ParticipantEditContainer = ({
  onClose,
  updateParticipantData,
  data,
  open,
}: ParticipantEditContainerProps) => {
  const { mutate, error: isError, payload: errorMessage } = useMutation(createUpdateParticipant(data?.id || ''));

  const onUpdate = async (values: ParticipantEditValues) => {
    const { error } = await mutate(values);

    if (!error) {
      onClose();
      updateParticipantData();
    }
  };

  return (
    <ParticipantAddEditDialog
      onClose={onClose}
      data={data}
      onUpdate={onUpdate}
      open={open}
      edit
      error={isError}
      errorMessage={errorMessage}
    />
  );
};
