import { Action } from 'react-fetching-library';

import { Participant } from '../events/eventsActions.types';

export enum ParticipantUploadActionEnum {
  Accept = 'accept',
  Reject = 'reject',
}

export type ParticipantUploadStatusValues = {
  participantId: string;
  status: ParticipantUploadActionEnum;
};

export type ParticipantEditValues = Pick<
  Participant,
  'fullName' | 'subtitle' | 'secondSubtitle' | 'email' | 'clientParticipantId' | 'eventOrderIndex'
>;

export type CreateParticipantUploadStatus = (values: ParticipantUploadStatusValues) => Action;
export type CreateGetParticipantUploadLink = (participantId: string) => Action;
export type CreateUpdateParticipant = (participantId: string) => (body: ParticipantEditValues) => Action;
export type CreateGetParticipantAuditLog = (participantId: string) => Action;
export type CreateDeleteParticipant = (participantId: string) => Action;
export type CreateAddParticipant = (eventId: string) => (body: ParticipantEditValues) => Action;
