import { withStyles } from '@material-ui/core';

import { Typography } from 'shared/components';

export const Info = withStyles(({ palette }) => ({
  root: {
    '& > strong': {
      color: palette.green.main,
    },
  },
}))(Typography);
