import React from 'react';

import { GridItem, GridContainer } from 'shared/components';
import { ResetPasswordFormContainer } from './ResetPasswordForm/ResetPasswordFormContainer';

export const Content: React.FC = () => {
  return (
    <GridContainer alignItems="center" justify="center" fullHeight>
      <GridItem xs={1} />
      <GridItem xs>
        <ResetPasswordFormContainer />
      </GridItem>
      <GridItem xs={1} />
    </GridContainer>
  );
};
