import React from 'react';
import { Button as MaterialButton, ButtonProps, withStyles, styled } from '@material-ui/core';

export const StyledButton: React.ComponentType<ButtonProps> = withStyles({
  root: {
    overflow: 'hidden',
  },
  label: {
    textTransform: 'uppercase',
  },
})(MaterialButton);

export const LoaderWrapper = styled('div')({
  alignItems: 'center',
  background: 'rgba(255,255,255, 0.5)',
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
});
