import React from 'react';
import { Redirect } from 'react-router-dom';

import { Button, GridItem, GridContainer } from 'shared/components';
import { Typography } from 'shared/components/typography/Typography';
import { Image } from 'shared/components/image/Image';
import { Separator } from 'shared/components/separator/Separator';
import { useAuthState } from 'shared/hooks/useAuthState/useAuthState';
import { ResetPasswordSuccessProps } from './SetPasswordSuccess.types';
import successIcon from 'assets/icons/success_bg.svg';

export const SetPasswordSuccess = ({ login }: ResetPasswordSuccessProps) => {
  const { isAuthorized } = useAuthState();

  if (isAuthorized) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <GridContainer spacing={0} justify="center" direction="column" alignItems="center">
        <GridItem container xs={10} direction="column">
          <Image alt="" src={successIcon} />
          <Separator height={10} />
          <Typography variant="h2" align="center">
            set_password.success.title
          </Typography>
          <Separator height={8} />
          <Typography variant="h4" align="center">
            set_password.success.body_info
          </Typography>
          <Typography variant="body1" align="center">
            set_password.success.body_continue
          </Typography>
          <Separator height={48} />
          <GridItem container xs justify="center">
            <Button variant="contained" color="primary" onClick={login}>
              set_password.success.button
            </Button>
          </GridItem>
        </GridItem>
      </GridContainer>
    </>
  );
};
