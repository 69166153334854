import React from 'react';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';

import { Dialog } from '../dialog/Dialog';
import { convertColumnsToDictionary } from '../../table.utils';

import { Icon, Menu, Tooltip } from 'shared/components';
import { FiltersDialogProps } from './FiltersDialog.types';
import { useStyles } from './FiltersDialog.styles';

export const FiltersDialog: React.FC<FiltersDialogProps> = ({ children, filters, columns }) => {
  const styles = useStyles();

  const columnsDictionary = convertColumnsToDictionary(columns);
  const isFilterActive = Object.keys(filters).some(
    key => filters[key] !== '' && filters[key] !== columnsDictionary[key].defaultFilterValue,
  );

  const iconButtonClassNames = clsx({
    [styles.filterActive]: isFilterActive,
  });

  return (
    <Menu
      anchorElement={(ref, handleToggle) => (
        <Tooltip title="table.filter_table">
          <IconButton ref={ref} onClick={handleToggle} className={iconButtonClassNames}>
            <Icon icon="FilterList" />
          </IconButton>
        </Tooltip>
      )}
    >
      <Dialog title="table.filter_table">{children}</Dialog>
    </Menu>
  );
};
