import React, { useEffect } from 'react';

import { PageTemplate } from 'shared/templates';
import { Stepper } from 'shared/components/stepper/Stepper';
import { Separator } from 'shared/components/separator/Separator';
import { SectionContainer } from './section/SectionContainer';
import { Section } from './section/Section';
import { ClipTemplateProps, Sections } from './ClipTemplate.types';
import { ClipTemplateSectionEnum } from 'api/types';
import { IntroTemplate } from './intro/IntroTemplate';
import { OverlayTemplate } from './overlay/OverlayTemplate';
import { ClosingTemplate } from './closing/ClosingTemplate';
import { AudioTemplate } from './audio/AudioTemplate';
import { WelcomeDialog } from 'shared/components/welcomeDialog/WelcomeDialog';
import {
  getAudioInitialValues,
  getIntroInitialValues,
  getOutroInitialValues,
  getOverlayInitialValues,
} from './initialValues/initialValues';
import { useClientDefaults } from 'shared/hooks';
import { SectionPlaceholder } from './sectionPlaceholder/SectionPlaceholder';
import { InitialValuesProvider } from './initialValues/provider/InitialValuesProvider';
import { RequestPreview } from './requestPreview/RequestPreview';
import { useSectionsStorage, useSectionStorageUpdate } from './ClipTemplate.storage';

const sections: Sections = {
  intro: {
    title: 'clip_template.sections.intro.title',
    subtitle: 'clip_template.sections.intro.subtitle',
    content: <IntroTemplate />,
    getInitialValues: getIntroInitialValues,
  },
  overlay: {
    title: 'clip_template.sections.overlay.title',
    subtitle: 'clip_template.sections.overlay.subtitle',
    content: <OverlayTemplate />,
    getInitialValues: getOverlayInitialValues,
  },
  outro: {
    title: 'clip_template.sections.outro.title',
    subtitle: 'clip_template.sections.outro.subtitle',
    content: <ClosingTemplate />,
    getInitialValues: getOutroInitialValues,
  },
  audio: {
    title: 'clip_template.sections.music.title',
    subtitle: 'clip_template.sections.music.subtitle',
    content: <AudioTemplate />,
    getInitialValues: getAudioInitialValues,
  },
};

const UNSET = -1;

export const ClipTemplate: React.FC<ClipTemplateProps> = ({ data, isLoading: loading }) => {
  const { previewRequested, ...sectionsState } = useSectionsStorage();
  const clientDefaults = useClientDefaults();
  const updatePreviewRequested = useSectionStorageUpdate(ClipTemplateSectionEnum.previewRequested);

  const isLoading = clientDefaults === null || loading;

  const firstNotAccepted = isLoading ? UNSET : Object.values(sectionsState).findIndex(({ accepted }) => !accepted);
  const allAccepted = isLoading ? false : Object.values(sectionsState).every(({ accepted }) => accepted);
  const activeStep = isLoading || allAccepted ? UNSET : firstNotAccepted;

  useEffect(() => {
    if (allAccepted) {
      setTimeout(() => {
        window.scroll({ top: 0, behavior: 'smooth' });
      }, 800);
    }
  }, [allAccepted]);

  useEffect(() => {
    if (!isLoading) {
      updatePreviewRequested(data.previewRequested);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <>
      <PageTemplate
        title="clip_template.page.title"
        subtitle="clip_template.page.subtitle"
        message={allAccepted ? { content: 'clip_template.alert.body', color: 'success' } : undefined}
      >
        <RequestPreview loading={isLoading} allAccepted={allAccepted} />
        <Separator height={15} />
        <Stepper activeStep={Object.keys(sections)[activeStep]} scrollToSection>
          {Object.entries(sections).map(([key, { title, subtitle, content, getInitialValues }]) => {
            if (isLoading) {
              return <SectionPlaceholder title={title} subtitle={subtitle} step={key} key={`placeholder-${key}`} />;
            }

            const sectionKey = key as ClipTemplateSectionEnum;
            const sectionData = data[sectionKey];
            // @ts-ignore
            const initialValues = getInitialValues(sectionData, clientDefaults);

            return (
              <InitialValuesProvider value={initialValues} step={sectionKey} key={key}>
                <SectionContainer title={title} subtitle={subtitle} key={key} step={sectionKey}>
                  <Section.Header>{title}</Section.Header>
                  <Section.Body>{content}</Section.Body>
                </SectionContainer>
              </InitialValuesProvider>
            );
          })}
        </Stepper>
      </PageTemplate>
      <WelcomeDialog
        title="clip_template.welcome.title"
        body="clip_template.welcome.body"
        storageKey="clip_template_welcome_dialog"
      />
    </>
  );
};
