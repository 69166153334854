import React from 'react';
import { useQuery } from 'react-fetching-library';

import { ClipTemplate } from './ClipTemplate';
import { fetchClipTemplates } from 'api/actions/clipTemplate/clipTemplateActions';
import { ClientDefaultsController } from 'context/clientDefaults/clientDefaultsController';
import { ClipTemplateData } from 'api/types';
import { SectionStorageProvider } from './ClipTemplate.storage';

export const ClipTemplateContainer: React.FC = () => {
  const { payload, loading } = useQuery<ClipTemplateData>(fetchClipTemplates);

  return (
    <ClientDefaultsController>
      <SectionStorageProvider>
        <ClipTemplate data={payload as ClipTemplateData} isLoading={loading} />
      </SectionStorageProvider>
    </ClientDefaultsController>
  );
};
