import React, { memo } from 'react';
import { Field } from 'react-final-form';

import { Separator } from 'shared/components';
import { BaseQuestion } from './base/BaseQuestion';
import { SurveyQuestionProps } from './SurveyQuestion.types';
import { StyledPaper } from './SurveyQuestion.styles';
import { SurveyQuestionHeader } from './header/SurveyQuestionHeader';

export const SurveyQuestion: React.FC<SurveyQuestionProps> = memo(({ field, onDelete }) => {
  return (
    <>
      <Separator height={24} />
      <StyledPaper>
        <SurveyQuestionHeader onDelete={onDelete} field={field} />
        <Field name={`${field}.type`} subscription={{ value: true }} component={BaseQuestion} field={field} />
      </StyledPaper>
    </>
  );
});
