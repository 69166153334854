import React from 'react';

import { SurveyQuestionType } from '../SurveyQuestion.constants';
import { SurveyEmailExcludedDomain } from '../email/SurveyEmailExcludedDomain';

import { SurveyQuestionHelperProps } from './SurveyQuestionHeaderColumn.types';

export const SurveyQuestionHeaderColumn = ({ input, field }: SurveyQuestionHelperProps) => {
  const { value } = input;

  switch (value) {
    case SurveyQuestionType.EMAIL:
      return <SurveyEmailExcludedDomain field={field} />;
    default:
      return null;
  }
};
