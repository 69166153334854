import React from 'react';

import { Typography } from 'shared/components';
import { SidebarTemplateLogo } from 'shared/templates/sidebar/logo/SidebarTemplateLogo';

export const Sidebar = () => {
  return (
    <>
      <SidebarTemplateLogo />
      <Typography variant="h3" color="textSecondary">
        forgot_password.sidebar.body
      </Typography>
      <div />
    </>
  );
};
