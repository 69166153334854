import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getFileType } from '../../utils';

import { ResolutionValidatorOptions } from './resolution.validator.types';

export const resolution = (config: ResolutionValidatorOptions) => (value: File) => {
  return new Promise(resolve => {
    if (value && value instanceof File) {
      const { width: expectedWidth, height: expectedHeight } = config;

      const fileType = getFileType(value);

      if (fileType) {
        const isImage = fileType === 'image';
        const element = isImage ? new Image() : document.createElement('video');
        element.src = URL.createObjectURL(value);

        if (element instanceof HTMLImageElement) {
          element.onload = () => {
            const { width, height } = element;

            if (width !== expectedWidth || height !== expectedHeight) {
              return resolve(<FormattedMessage id="validation.resolution" values={{ width, height }} />);
            }

            return resolve();
          };

          return undefined;
        }

        element.addEventListener('loadedmetadata', () => {
          const { videoWidth: width, videoHeight: height } = element;

          if (width !== expectedWidth || height !== expectedHeight) {
            return resolve(<FormattedMessage id="validation.resolution" values={{ width, height }} />);
          }

          return resolve();
        });

        return undefined;
      }
    }

    return resolve();
  });
};
