import { Action } from 'types';

export const createReducerAction = <ActionType = string, PayloadType = any>(
  type: ActionType,
  payload?: PayloadType,
): Action<ActionType> | Action<ActionType, PayloadType> => {
  if (typeof payload === 'undefined') {
    return { type };
  }

  return { type, payload };
};
