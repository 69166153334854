import styled from '@material-ui/styles/styled';

import { Typography } from 'shared/components/typography/Typography';
import { Skeleton } from 'shared/components/skeleton/Skeleton';

export const EventType = styled(Typography)({
  display: 'inline-block',
});

export const Timezone = styled(Typography)({
  opacity: 0.6,
});

export const InlineSkeleton = styled(Skeleton)({
  display: 'inline-block',
});
