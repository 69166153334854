import React from 'react';
import Validators, {
  required as requiredValidator,
  email as emailValidator,
  length as lengthValidator,
  file as fileValidator,
  url as urlValidator,
  confirmation as confirmationValidator,
  date as dateValidator,
  DefaultValidatorOptions,
  EmailValidatorOptions,
  LengthValidatorOptions,
  ConfirmationValidatorOptions,
  Validator,
} from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';

Validators.messages = {
  ...Validators.messages,
  file: {
    // @ts-ignore
    ...Validators.messages.file,
    id: 'validation.file.file',
  },
  fileTooBig: {
    // @ts-ignore
    ...Validators.messages.fileTooBig,
    id: 'validation.file.too_big',
  },
};

// @ts-ignore
Validators.formatMessage = msg => <FormattedMessage {...(msg.props || msg)} />;

const overWriteConfig = (id: string, config: DefaultValidatorOptions = {}) => ({
  ...config,
  message: {
    id: config.msg || id,
  },
});

const overWriteValidator = (validator: Validator, id: string, config?: DefaultValidatorOptions) =>
  validator(overWriteConfig(id, config));

export const required = (config?: DefaultValidatorOptions) =>
  overWriteValidator(requiredValidator, 'validation.required', config);

export const email = (config?: EmailValidatorOptions) =>
  overWriteValidator(emailValidator, 'validation.invalid_email', config);

export const length = (config?: LengthValidatorOptions) =>
  overWriteValidator(lengthValidator, 'validation.invalid_length', config);

export const date = (config?: any) => overWriteValidator(dateValidator, 'validation.date', config);

export const file = fileValidator;

export const url = urlValidator;

export const confirmation = (config?: ConfirmationValidatorOptions) =>
  overWriteValidator(confirmationValidator, 'validation.password.match', config);

export * from './password/password.validator';
export * from './resolution/resolution.validator';
export * from './date/bounds.validator';
