import { styled } from '@material-ui/core';

import { modalPadding } from '../Dialog.styles';

export const Title = styled('header')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `0 ${modalPadding}px`,
});
