import React, { useState, useEffect } from 'react';

import { Player } from '../player/Player';

import { BlobPlayerProps } from './BlobPlayer.types';

const hash = (file: File) => `${file.name}:${file.type}:${file.size}`;

export const BlobPlayer = ({ src, title, subtitle }: BlobPlayerProps) => {
  const [blob, setBlob] = useState();

  useEffect(() => {
    setBlob(URL.createObjectURL(src));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(src)]);

  return <Player playlist={[{ src: blob, title, subtitle }]} />;
};
