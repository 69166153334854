import { useContext } from 'react';

import { StepperStateContext } from './SteppertStateContext';

export const useStepperState = () => {
  const context = useContext(StepperStateContext);

  if (context === undefined) {
    throw new Error('useStepperState must be used within an StepperStateContext');
  }

  return context;
};
