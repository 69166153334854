import React, { useState } from 'react';
import find from 'lodash/find';
import { useMutation } from 'react-fetching-library';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import addDays from 'date-fns/addDays';
import { Button, Grid } from '@material-ui/core';

import { EventParticipantUploadsPreviewContainer } from '../participantUploadsPreview/EventParticipantUploadsPreviewContainer';
import { Status } from '../../status/Status';
import { IconMap } from '../../status/Status.types';
import { ParticipantEditContainer } from '../participantAddEdit/participantEdit/ParticipantEditContainer';
import { ParticipantAuditLogContainer } from '../participantAuditLog/ParticipantAuditLogContainer';
import { ParticipantDeleteContainer } from '../participantDelete/ParticipantDeleteContainer';
import { ParticipantAddContainer } from '../participantAddEdit/participantAdd/ParticipantAddContainer';

import { Separator } from 'shared/components';
import { PageSection } from 'shared/components/pageSectionStatic/PageSection';
import { Table } from 'shared/components/table/Table';
import { Icon } from 'shared/components/icon/Icon';
import { IconButton } from 'shared/components/iconButton/IconButton';
import { createGetParticipantUploadLink } from 'api/actions/participants/participantsActions';
import { handleRequest } from 'shared/utils/handleRequest/handleRequest';
import { Column } from 'shared/components/table/Table.types';
import { useLocale } from 'shared/hooks';
import { EventParticipantsListColumns, EventParticipantsListProps } from './EventParticipantsList.types';
import { Participant } from 'api/actions/events/eventsActions.types';

const formatter = (value: unknown) => value || 'n/a';

const iconMap: IconMap = {
  new: { icon: 'Replay', level: 'normal' },
  invited: { icon: 'Replay', level: 'normal' },
  submitted: { icon: 'CheckCircle', level: 'success' },
  accepted: { icon: 'CheckCircle', level: 'success' },
  rejected: { icon: 'Stop', level: 'error' },
};

const useColumns = ({
  showParticipantDetails,
  eventType,
  deadlineDate,
  editParticipant,
  getAuditLog,
  deleteParticipant,
}: EventParticipantsListColumns): Column[] => {
  const isVirtualGraduation = eventType === 'Virtual Graduation';
  const { mutate: getUploadLinkQuery } = useMutation(createGetParticipantUploadLink);

  const getUploadLink = (participantId: string) => {
    handleRequest<{ url: string }>({
      action: () => getUploadLinkQuery(participantId),
      onSuccess: payload => {
        window.open(payload.url, '_blank', 'noopener noreferrer');
      },
    });
  };

  const columns: Column[] = [
    { title: 'event_details.table.columns.ceremony_order', field: 'eventOrderIndex' },
    { title: 'event_details.table.columns.full_name', field: 'fullName' },
    {
      title: 'event_details.table.columns.subtitle',
      field: 'subtitle',
      formatter,
    },
    {
      title: 'event_details.table.columns.second_subtitle',
      field: 'secondSubtitle',
      formatter,
    },
    {
      title: 'event_details.table.columns.id',
      field: 'clientParticipantId',
      formatter,
    },
    {
      title: 'event_details.table.columns.email',
      field: 'email',
      formatter,
    },
  ];

  const virtualGraduationColumns: Column[] = [
    {
      title: 'event_details.table.columns.status',
      field: 'virtualGraduation.state',
      formatter: (value: string) => <Status status={value} iconMap={iconMap} />,
    },
    {
      title: 'event_details.table.columns.actions',
      field: 'actions',
      sortable: false,
      formatter: (_, row) => {
        const { id, virtualGraduation } = row as Participant;

        const previewEnabled = !!virtualGraduation && ['submitted', 'accepted'].includes(virtualGraduation.state);
        const isAfterDeadline = !!deadlineDate && isAfter(new Date(), addDays(parseISO(deadlineDate), 3));

        return (
          <>
            {previewEnabled && (
              <IconButton
                onClick={() => showParticipantDetails(id)}
                tooltip={
                  previewEnabled
                    ? 'event_details.table.actions.preview'
                    : 'event_details.table.actions.preview_disabled'
                }
              >
                <Icon icon="Visibility" />
              </IconButton>
            )}
            {!previewEnabled && (
              <IconButton
                tooltip="event_details.table.actions.upload"
                onClick={() => getUploadLink(id)}
                disabled={isAfterDeadline}
              >
                <Icon icon="Edit" />
              </IconButton>
            )}
            <IconButton
              tooltip="event_details.table.actions.edit"
              onClick={() => editParticipant(id)}
              disabled={isAfterDeadline}
            >
              <Icon icon="Person" />
            </IconButton>
            <IconButton onClick={() => getAuditLog(id)} tooltip="event_details.table.actions.show_audit">
              <Icon icon="Info" />
            </IconButton>
            <IconButton
              tooltip="event_details.table.actions.delete"
              onClick={() => deleteParticipant(id)}
              disabled={isAfterDeadline}
            >
              <Icon icon="Delete" />
            </IconButton>
          </>
        );
      },
    },
  ];

  return isVirtualGraduation ? [...columns, ...virtualGraduationColumns] : columns;
};

export const EventParticipantsList = ({
  data,
  deadlineDate,
  eventType,
  eventId,
  reloadParticipants,
  participants,
}: EventParticipantsListProps) => {
  const [uploadParticipantId, setUploadParticipantId] = useState<string | null>(null);
  const [editParticipantId, setEditParticipantId] = useState<string | null>(null);
  const [auditLogParticipantId, setAuditLogParticipantId] = useState<string | null>(null);
  const [deleteParticipantId, setDeleteParticipantId] = useState<string | null>(null);
  const [openAddParticipant, setOpenAddParticipant] = useState<boolean>(false);
  const columns = useColumns({
    showParticipantDetails: setUploadParticipantId,
    editParticipant: setEditParticipantId,
    getAuditLog: setAuditLogParticipantId,
    deleteParticipant: setDeleteParticipantId,
    eventType,
    deadlineDate,
  });
  const { formatMessage } = useLocale();

  return (
    <>
      <PageSection title="event_details.table.title">
        <>
          <Grid container direction="row" justify="flex-end" alignItems="center">
            <Button variant="contained" color="primary" onClick={() => setOpenAddParticipant(true)}>
              {formatMessage({ id: 'event_details.table.buttons.add_participant' })}
            </Button>
          </Grid>
          <Separator height={10} />
        </>
        <Table
          data={participants}
          columns={columns}
          searchColumns={['fullName', 'subtitle', 'clientParticipantId', 'email']}
        />
      </PageSection>

      {!!deadlineDate && !!data && uploadParticipantId && (
        <EventParticipantUploadsPreviewContainer
          eventDeadlineDate={deadlineDate}
          data={find(participants, { id: uploadParticipantId })}
          onClose={() => setUploadParticipantId(null)}
          updateParticipantData={reloadParticipants}
        />
      )}
      {editParticipantId && (
        <ParticipantEditContainer
          open={!!editParticipantId}
          onClose={() => setEditParticipantId(null)}
          data={find(participants, { id: editParticipantId })}
          updateParticipantData={reloadParticipants}
        />
      )}
      {auditLogParticipantId && (
        <ParticipantAuditLogContainer
          open={!!auditLogParticipantId}
          onClose={() => setAuditLogParticipantId(null)}
          data={find(participants, { id: auditLogParticipantId })}
        />
      )}
      {deleteParticipantId && (
        <ParticipantDeleteContainer
          open={!!deleteParticipantId}
          onClose={() => setDeleteParticipantId(null)}
          participant={find(participants, { id: deleteParticipantId })}
          updateParticipantsList={reloadParticipants}
        />
      )}
      {openAddParticipant && (
        <ParticipantAddContainer
          open={openAddParticipant}
          onClose={() => setOpenAddParticipant(false)}
          eventId={eventId}
          updateParticipantsList={reloadParticipants}
        />
      )}
    </>
  );
};
