import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import get from 'lodash/get';

import { Typography, GridItem, Separator, GridContainer } from 'shared/components';
import { Alert } from 'shared/components/alert/Alert';
import { useAuthState } from 'shared/hooks';
import { LoginFormContainer } from './loginForm/LoginFormContainer';

export const Content: React.FC = () => {
  const { isAuthorized } = useAuthState();
  const { state } = useLocation<{ error?: string }>();
  const error = get(state, 'error');

  if (isAuthorized) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <GridContainer alignItems="center" justify="center" fullHeight>
        <GridItem xs={1} />
        <GridItem xs>
          {!!error && (
            <>
              <Alert severity="error">{error}</Alert>
              <Separator height={24} />
            </>
          )}
          <Typography variant="h2">login.content.title</Typography>
          <Typography variant="caption">login.content.subtitle</Typography>
          <Separator height={68} />
          <LoginFormContainer />
        </GridItem>
        <GridItem xs={1} />
      </GridContainer>
    </>
  );
};
