import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Menu } from 'app/core';
import { Events } from 'app/events/Events';
import { ClipTemplateContainer } from 'app/clipTemplate/ClipTemplateContainer';
import { SurveyTemplateContainer } from 'app/surveyTemplate/SurveyTemplateContainer';
import { EmailTemplateContainer } from 'app/emailTemplate/EmailTemplateContainer';
import { useAuthState } from 'shared/hooks';
import { Loader } from 'shared/components/loader/Loader';
import { useClientDefaults } from 'shared/hooks/useClientDefaults/useClientDefaults';

export const Dashboard: React.FC = () => {
  const { user } = useAuthState();
  const clientsDefaults = useClientDefaults();

  if (!clientsDefaults) {
    return <Loader fullScreen />;
  }

  return (
    <>
      <Menu />
      <Switch>
        <Route path="/events" component={Events} />
        {user?.clipTemplateEditor && <Route path="/clip-template" exact component={ClipTemplateContainer} />}
        {user?.surveyTemplateEditor && <Route path="/survey-template" exact component={SurveyTemplateContainer} />}
        {user?.emailTemplateEditor && <Route path="/email-template" component={EmailTemplateContainer} />}
        <Redirect to="/events" />
      </Switch>
    </>
  );
};
