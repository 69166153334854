import React, { useState } from 'react';

import { Dialog, Image, Typography, Separator, Button, Form } from 'shared/components';
import { Body, StyledCheckbox } from './WelcomeDialog.styles';
import editIcon from 'assets/icons/edit_bg.svg';
import { WelcomeDialogProps, FormValues } from './WelcomeDialog.types';

export const WelcomeDialog: React.FC<WelcomeDialogProps> = props => {
  const { title, body, storageKey } = props;
  const [open, setOpen] = useState(!localStorage.getItem(storageKey));

  const onSubmit = ({ remember }: FormValues) => {
    if (remember) {
      localStorage.setItem(storageKey, 'false');
    }

    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <Body>
        <Image alt="welcome image" src={editIcon} />
        <Separator height={64} />
        <Typography variant="h3">{title}</Typography>
        <Separator height={54} />
        <Typography variant="body1">{body}</Typography>
      </Body>
      <Form<FormValues>
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Dialog.Footer>
              <StyledCheckbox name="remember" label="dialog.welcome.remember" />
              <Button color="primary" type="submit" autoFocus>
                dialog.confirm_label
              </Button>
            </Dialog.Footer>
          </form>
        )}
      />
    </Dialog>
  );
};
