import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const green = '#458606';
const blue = '#2baadf';
const red = '#b00020';
const black = '#373737';
const white = '#fff';
const gray = '#979797';
const lightGray = '#d8d8d8';
const bodyBackground = '#f7f7f8';
export const yellow = '#ffdc6c';

export const palette: PaletteOptions = {
  green: {
    main: green,
  },
  red: {
    main: red,
  },
  black: {
    main: black,
  },
  white: {
    main: white,
  },
  gray: {
    main: gray,
    light: lightGray,
  },
  primary: {
    main: green,
  },
  secondary: {
    main: blue,
  },
  error: {
    main: red,
  },
  success: {
    main: green,
  },
  warning: {
    main: yellow,
  },
  text: {
    primary: black,
    secondary: white,
  },
  background: {
    default: bodyBackground,
  },
};
