import React from 'react';

import { Skeleton } from '../skeleton/Skeleton';
import { Separator } from '../separator/Separator';

import { PageSectionTitle } from './title/PageSectionTitle';
import { PageSectionSubtitle } from './subtitle/PageSectionSubtitle';
import { PageSectionBody } from './body/PageSectionBody';
import { PageSectionFooter } from './footer/PageSectionFooter';
import { PageSectionProps } from './PageSection.types';

export const PageSection: React.FC<PageSectionProps> & {
  Title: typeof PageSectionTitle;
  Subtitle: typeof PageSectionSubtitle;
  Body: typeof PageSectionBody;
  Footer: typeof PageSectionFooter;
} = ({ title, subtitle, loading, children }) => {
  return (
    <>
      {!!title && <PageSectionTitle>{title}</PageSectionTitle>}
      {!!subtitle && <PageSectionSubtitle>{subtitle}</PageSectionSubtitle>}
      <Separator height={15} />
      {loading ? (
        <PageSectionBody>
          <Skeleton />
        </PageSectionBody>
      ) : (
        children
      )}
    </>
  );
};

PageSection.Title = PageSectionTitle;
PageSection.Subtitle = PageSectionSubtitle;
PageSection.Body = PageSectionBody;
PageSection.Footer = PageSectionFooter;
