import React from 'react';
import clsx from 'clsx';

import { Icon } from '../Icon';
import { IconProps } from '../Icon.types';

import { useTranslations } from 'shared/hooks';
import { useStyles } from './IconWithText.styles';

export const IconWithText: React.FC<IconProps> = ({ icon, children, className, classes }) => {
  const styles = useStyles();
  const { message } = useTranslations(children);

  const rootClassNames = clsx(styles.root, className, classes?.root);
  const iconClassNames = clsx(styles.icon, classes?.icon);

  return (
    <div className={rootClassNames}>
      <Icon icon={icon} className={iconClassNames} />
      {message}
    </div>
  );
};
