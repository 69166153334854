import React, { useCallback } from 'react';

import { SurveyActionBar } from './SurveyActionBar';
import { SurveyActionBarContainerProps } from './SurveyActionBarContainer.types';

export const SurveyActionBarContainer: React.FC<SurveyActionBarContainerProps> = ({ accepted, fields }) => {
  const isAddingDisabled = (fields.length || 0) >= 5;
  const isSubmittingDisabled = (fields.length || 0) < 1;

  const handleAdd = useCallback(() => {
    fields.push({});
  }, [fields]);

  return (
    <SurveyActionBar
      accepted={accepted}
      isAddingDisabled={isAddingDisabled}
      isSubmittingDisabled={isSubmittingDisabled}
      onAdd={handleAdd}
    />
  );
};
