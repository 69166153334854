import { styled, withStyles } from '@material-ui/core';

import { Typography } from 'shared/components';

export const Header = styled('header')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
});

export const Title = withStyles({
  root: {
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
})(Typography);

export const Subtitle = withStyles({
  root: {
    flex: '0 0 100%',
  },
})(Typography);

export const Footer = styled('footer')({
  display: 'flex',
  justifyContent: 'flex-end',
  '& > button:last-of-type': {
    marginLeft: 16,
  },
});
