import React from 'react';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';

import { Dialog } from '../dialog/Dialog';

import { Icon, Menu, Tooltip } from 'shared/components';
import { Checkbox } from 'shared/components/controls';
import { useStyles } from './ColumnsDialog.styles';
import { ColumnsDialogProps } from './ColumnsDialog.types';

export const ColumnsDialog: React.FC<ColumnsDialogProps> = ({ columns, toggleColumn }) => {
  const styles = useStyles();

  const isFilterActive = Object.values(columns).some(({ visible }) => visible === false);

  const iconButtonClassNames = clsx({
    [styles.filterActive]: isFilterActive,
  });

  return (
    <Menu
      anchorElement={(ref, handleToggle) => (
        <Tooltip title="table.show_columns">
          <IconButton ref={ref} onClick={handleToggle} className={iconButtonClassNames}>
            <Icon icon="ViewColumn" />
          </IconButton>
        </Tooltip>
      )}
    >
      <Dialog title="table.show_columns">
        {columns.map(({ title, visible, field }) => (
          <div key={field}>
            <Checkbox value={field} onChange={() => toggleColumn(field)} label={title} checked={visible} />
          </div>
        ))}
      </Dialog>
    </Menu>
  );
};
