import React from 'react';

import { Button } from '../button/Button';
import { Paper } from '../paper/Paper';

import { DialogTitle } from './title/DialogTitle';
import { DialogBody } from './body/DialogBody';
import { DialogFooter } from './footer/DialogFooter';
import { StyledDialog } from './Dialog.styles';
import { DialogProps } from './Dialog.types';

export const Dialog: React.FC<DialogProps> & {
  Title: typeof DialogTitle;
  Body: typeof DialogBody;
  Footer: typeof DialogFooter;
} = ({
  children,
  onCancel,
  onConfirm,
  title,
  cancelLabel = 'dialog.cancel_label',
  confirmLabel = 'dialog.confirm_label',
  cancelDisabled = false,
  confirmDisabled = false,
  onClose,
  ...props
}) => {
  return (
    <StyledDialog disableAutoFocus {...props} PaperComponent={Paper} data-testid="dialog">
      {title && <DialogTitle>{title}</DialogTitle>}
      {children}
      {(onCancel || onConfirm) && (
        <DialogFooter>
          {onCancel && (
            <Button color="primary" onClick={onCancel} disabled={cancelDisabled}>
              {cancelLabel}
            </Button>
          )}
          {onConfirm && (
            <Button color="primary" onClick={onConfirm} disabled={confirmDisabled}>
              {confirmLabel}
            </Button>
          )}
        </DialogFooter>
      )}
    </StyledDialog>
  );
};

Dialog.Title = DialogTitle;
Dialog.Body = DialogBody;
Dialog.Footer = DialogFooter;
