import React from 'react';
import { FormSpy } from 'react-final-form';

import { updateStorageData, useStorageData } from '../SurveyTemplate.storage';

import { Form, Separator } from 'shared/components';
import { SurveyStyleActionBar } from './actionBar/SurveyStyleActionBar';
import { SurveyStyleSpecification } from './specification/SurveyStyleSpecification';
import { SurveyStyleProps } from './SurveyStyle.types';

export const SurveyStyle: React.FC<SurveyStyleProps> = ({ accepted, initialValues, onSubmit }) => {
  const data = useStorageData();
  const update = updateStorageData();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, dirty, submitting }) => (
        <>
          <form onSubmit={handleSubmit}>
            <SurveyStyleSpecification />
            <Separator height={42} />
            <SurveyStyleActionBar accepted={accepted && !dirty} />
          </form>
          <FormSpy
            onChange={({ values }) => {
              if (submitting) {
                return;
              }
              update({ ...data, ...values });
            }}
            subscription={{ values: true }}
          />
        </>
      )}
    />
  );
};
