import React, { useMemo } from 'react';
import { useField } from 'react-final-form';

import { ItemProps } from 'shared/components/specification/item/Item.types';
import { Specification } from 'shared/components/specification/Specification';
import { LogoDialog } from 'shared/components';
import { ColorPickerField, FileField } from 'shared/components/controls';
import { GridContainer, GridItem } from 'shared/components/grid';
import { useLogoDialog } from 'shared/hooks';
import { required } from 'shared/validators';
import { StyledPaper } from './SurveyStyleSpecification.styles';

export const SurveyStyleSpecification: React.FC = () => {
  const { isDialogOpened, handleDialogCancel, handleDialogConfirm, open } = useLogoDialog('style.logo');
  const {
    input: { value: logo },
  } = useField('style.logo', { subscription: { value: true } });

  const options: ItemProps[] = useMemo(
    () => [
      {
        label: 'survey_template.specification.logo',
        icon: 'CenterFocusWeak',
        content: (
          <FileField
            name="style.logo"
            placeholder={
              logo ? 'survey_template.specification.logo_uploaded' : 'survey_template.specification.select_logo'
            }
            validate={[required()]}
            onClick={() => open()}
          />
        ),
      },
      {
        label: 'survey_template.specification.header_color',
        icon: 'PaletteOutlined',
        content: <ColorPickerField name="style.color" validate={[required()]} hiddenLabel hiddenError />,
      },
    ],
    [logo, open],
  );

  return (
    <StyledPaper>
      <GridContainer>
        <GridItem md={8} lg={6}>
          <Specification options={options} />
        </GridItem>
      </GridContainer>
      {isDialogOpened && (
        <LogoDialog
          onConfirm={handleDialogConfirm}
          onCancel={handleDialogCancel}
          open={isDialogOpened}
          name="style.logo"
        />
      )}
    </StyledPaper>
  );
};
