import React from 'react';

import { StyledIconWithText, useStyles } from './Status.styles';
import { StatusProps } from './Status.types';

export const Status: React.FC<StatusProps> = ({ status, iconMap }) => {
  const styles = useStyles();

  const mappedStatus = iconMap[status];

  if (!mappedStatus) {
    return <>N/A</>;
  }

  const className = styles[mappedStatus.level];

  return (
    <StyledIconWithText icon={mappedStatus.icon} className={className}>
      {status}
    </StyledIconWithText>
  );
};
