import React from 'react';
import { ClickAwayListener, Grow, Paper, Popper } from '@material-ui/core';

import { MenuProps } from './Menu.types';

export const Menu: React.FC<MenuProps> = ({ children, anchorElement }) => {
  const anchorRef = React.useRef<HTMLAnchorElement>(null);

  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      {anchorElement(anchorRef, handleToggle)}
      <Popper open={open} anchorEl={anchorRef.current} transition style={{ zIndex: 1400 }} data-testid="menu">
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>{children}</ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
