import { withStyles } from '@material-ui/core';

import { List } from 'shared/components';

export const StyledList = withStyles({
  root: {
    maxHeight: 300,
    overflow: 'auto',
  },
})(List);
