import React from 'react';

import { useCollapsibleState } from '../context/useCollapsibleState';

import { isChildrenAsFunction } from 'shared/typesGuard';
import { CollapsibleFooterProps } from './CollapsibleFooter.types';

export const CollapsibleFooter: React.FC<CollapsibleFooterProps> = ({ children }) => {
  const { open } = useCollapsibleState();

  return <div data-testid="collapsible-footer">{isChildrenAsFunction(children) ? children({ open }) : children}</div>;
};
