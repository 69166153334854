import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { typography } from './typography';
import { palette } from './palette';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette,
  typography,
  overrides: {
    MuiContainer: {
      root: {
        [defaultTheme.breakpoints.down('xs')]: {
          width: '100%',
        },
        [defaultTheme.breakpoints.up('sm')]: {
          // medium: 960px or larger
          width: 540,
        },
        [defaultTheme.breakpoints.up('md')]: {
          // medium: 960px or larger
          width: 920,
        },
        [defaultTheme.breakpoints.up('lg')]: {
          // large: 1280px or larger
          width: 1140,
        },
        [defaultTheme.breakpoints.up('xl')]: {
          // extra-large: 1920px or larger
          width: 1320,
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        color: palette.black.main,

        '&:hover': {
          color: palette.black.main,

          '&& $icon': {
            opacity: 1,
            color: palette.black.main,
          },
        },
        '&$active': {
          color: palette.black.main,

          '&& $icon': {
            opacity: 1,
            color: palette.black.main,
          },
        },
      },
    },
  },
});

export const Theme: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);
