import React, { memo } from 'react';
import { FieldArray } from 'react-final-form-arrays';

import { Button } from 'shared/components';
import { ConnectedTextField } from 'shared/components/controls';
import { GridItem } from 'shared/components/grid';
import { useTranslations } from 'shared/hooks';
import { required } from 'shared/validators';
import { SurveyOptionContainer } from './option/SurveyOptionContainer';
import { SurveyChoiceItem } from './item/SurveyChoiceItem';
import { SurveyChoiceProps } from './SurveyChoice.types';

export const SurveyChoice: React.FC<SurveyChoiceProps> = memo(({ field, icon }) => {
  const { message } = useTranslations('survey_template.choice.option');

  const focus = (name: string) => {
    setTimeout(() => {
      const element = document.querySelector<HTMLInputElement>(`input[name='${name}']`);

      if (element) {
        element.focus();
      }
    });
  };

  return (
    <>
      <ConnectedTextField
        name={`${field}.question`}
        placeholder="survey_template.choice.question"
        validate={[required()]}
        maxLength={250}
      />
      <FieldArray name={`${field}.answers`} defaultValue={([`${message} 1`] as unknown) as string}>
        {({ fields }) => (
          <>
            {fields.map((name, index) => (
              <SurveyOptionContainer key={name} name={name} icon={icon} fields={fields} index={index} />
            ))}
            <SurveyChoiceItem icon={icon}>
              <GridItem lg={5}>
                <Button
                  type="button"
                  onClick={() => {
                    fields.push(`${message} ${(fields.length || 0) + 1}`);
                    focus(`${fields.name}[${fields.length}]`);
                  }}
                >
                  survey_template.choice.add
                </Button>
              </GridItem>
            </SurveyChoiceItem>
          </>
        )}
      </FieldArray>
    </>
  );
});
