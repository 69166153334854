import React from 'react';

import { Button, GridItem, GridContainer } from 'shared/components';
import { Typography } from 'shared/components/typography/Typography';
import { Image } from 'shared/components/image/Image';
import { Separator } from 'shared/components/separator/Separator';
import { ResetPasswordSuccessProps } from './ResetPasswordSuccess.types';
import envelopeIcon from 'assets/icons/envelope_bg.svg';

export const ResetPasswordSuccess = ({ goBack }: ResetPasswordSuccessProps) => {
  return (
    <GridContainer spacing={0} justify="center" direction="column" alignItems="center">
      <GridItem container xs={10} direction="column" alignItems="stretch">
        <Image alt="" src={envelopeIcon} />
        <Separator height={10} />
        <Typography variant="h2" align="center">
          forgot_password.success.title
        </Typography>
        <Separator height={8} />
        <Typography variant="body1" align="center">
          forgot_password.success.body
        </Typography>
        <Separator height={38} />
        <Button variant="text" color="primary" fullWidth onClick={goBack}>
          forgot_password.success.wrong_email
        </Button>
      </GridItem>
    </GridContainer>
  );
};
