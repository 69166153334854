import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import Validators from 'redux-form-validators';

import { formattedDate } from 'shared/utils/formatters';
import { DateFormat } from 'shared/enums/dateFormat';

type Config = {
  field: string;
};

export const isAfterDate = ({ field }: Config) => (value: Date, allValues: any) => {
  const boundDate = allValues[field];

  if (boundDate && isAfter(value, boundDate)) {
    return Validators.formatMessage({
      id: `validation.date.is_after`,
      values: { date: formattedDate(boundDate, DateFormat.Date) },
    });
  }

  return undefined;
};

export const isBeforeDate = ({ field }: Config) => (value: Date, allValues: any) => {
  const boundDate = allValues[field];

  if (boundDate && isBefore(value, boundDate)) {
    return Validators.formatMessage({
      id: `validation.date.is_before`,
      values: { date: formattedDate(boundDate, DateFormat.Date) },
    });
  }

  return undefined;
};
