import React from 'react';

import { Button, GridItem, GridContainer, Form } from 'shared/components';
import { Typography } from 'shared/components/typography/Typography';
import { Image } from 'shared/components/image/Image';
import { Separator } from 'shared/components/separator/Separator';
import { email } from 'shared/validators';
import { InputField } from 'shared/components/controls';
import { LoginFormProps } from './ResetPassword.types';
import ideamanIcon from 'assets/icons/ideaman_bg.svg';

export const ResetPasswordForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  return (
    <GridContainer spacing={0} justify="center" direction="column" alignItems="center">
      <GridItem container xs={10} direction="column" alignItems="stretch">
        <Image alt="" src={ideamanIcon} />
        <Separator height={10} />
        <Typography variant="h2" align="center">
          forgot_password.form.forgot_password
        </Typography>
        <Separator height={8} />
        <Typography variant="body1" align="center">
          forgot_password.form.enter_email
        </Typography>
        <Separator height={16} />
        <Form
          errorSeparator={16}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <InputField name="email" type="email" fullWidth validate={[email()]} label="forgot_password.form.label" />
              <Separator height={16} />
              <Button variant="contained" color="primary" type="submit" fullWidth>
                forgot_password.form.next
              </Button>
              <Separator height={38} />
              <Button variant="text" color="primary" fullWidth to="/login">
                forgot_password.form.go_back
              </Button>
            </form>
          )}
        />
      </GridItem>
    </GridContainer>
  );
};
