import React, { useEffect } from 'react';
import { useField } from 'react-final-form';

import { Dialog } from 'shared/components';
import { FileDropZoneField } from 'shared/components/controls';
import { LogoDialogProps } from './LogoDialog.types';

export const LogoDialog: React.FC<LogoDialogProps> = ({ onConfirm, name, ...props }) => {
  const fieldName = `${name}Temp`;

  const {
    input: { value, onChange },
    meta: { invalid },
  } = useField<File>(fieldName, { subscription: { value: true, invalid: true } });

  const handleOnConfirm = () => {
    onConfirm(value);
  };

  useEffect(() => {
    return () => {
      onChange(undefined);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      title="clip_template.dialog.upload_logo"
      {...props}
      onConfirm={handleOnConfirm}
      confirmLabel="clip_template.dialog.use_it"
      confirmDisabled={invalid}
    >
      <Dialog.Body>
        <FileDropZoneField name={fieldName} />
      </Dialog.Body>
    </Dialog>
  );
};
