import React, { useEffect, useState } from 'react';
import { useField } from 'react-final-form';

import { Loader, BlobVideo, BlobImage } from 'shared/components';
import { getUrlAsFile, getFileType } from 'shared/utils';

const previews = {
  video: BlobVideo,
  image: BlobImage,
};

export const PreviewOverlay = () => {
  const [loading, setLoading] = useState(false);
  const [storage, setStorage] = useState<File | null>(null);

  const {
    input: { value },
  } = useField('file', { subscription: { value: true } });

  useEffect(() => {
    const readUrlAsFile = async (url: string) => {
      const file = await getUrlAsFile(url);
      setStorage(file);
    };

    if (typeof value === 'string' && value.length > 0) {
      readUrlAsFile(value).finally(() => setLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const type = storage && getFileType(storage);
  const Preview = (type && previews[type as 'video' | 'image']) || 'div';

  return (
    <div>
      {(loading || !storage) && <Loader />}
      {loading || (storage && Preview && <Preview src={storage} alt="overlay preview" ratio={16 / 9} />)}
    </div>
  );
};
