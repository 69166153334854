import React, { useMemo } from 'react';
import { ClientContextProvider, createClient } from 'react-fetching-library';

import {
  requestHostInterceptor,
  requestAuthInterceptor,
  requestBodyInterceptor,
  responseRefreshTokenInterceptor,
} from 'api/interceptors';
import { useAuthState, useAuthDispatch } from 'shared/hooks';

export const ClientContextController: React.FC = ({ children }) => {
  const { accessToken, refreshToken, user } = useAuthState();
  const dispatch = useAuthDispatch();

  const client = useMemo(() => {
    return createClient({
      requestInterceptors: [requestHostInterceptor, requestAuthInterceptor(accessToken), requestBodyInterceptor],
      responseInterceptors: [responseRefreshTokenInterceptor(refreshToken, user, dispatch)],
    });
  }, [accessToken, dispatch, refreshToken, user]);

  return <ClientContextProvider client={client}>{children}</ClientContextProvider>;
};
