import { useState } from 'react';

import { Column, RowData } from '../Table.types';

import { Config, Validator, Filters, OnFilterChange, ValidatorColumn } from './useFilters.types';

const createInitialState = (columns: Column[]) =>
  columns
    .filter(({ filter }) => filter)
    .reduce((acc, next) => ({ ...acc, [next.field]: next.defaultFilterValue || '' }), { search: '' });

const getValidators = (columns: Column[]) => columns.filter(({ filter }) => typeof filter !== 'undefined');

const applyFilters = (data: RowData[], validators: Validator[], filters: Filters) => {
  if (validators.length) {
    const runValidators = (row: RowData): boolean[] =>
      validators.map(([key, validator]) => validator(row[key], filters[key]));

    return data.filter(row => runValidators(row).every(result => result));
  }

  return data;
};

export const useFilters = ({ columns, data }: Config) => {
  const [filters, setFilters] = useState<Filters>(createInitialState(columns));

  const onFilterChange: OnFilterChange = (name, value) => setFilters(prev => ({ ...prev, [name]: value }));

  if (data === null) {
    return { data, onFilterChange, filters };
  }

  const validators = getValidators(columns) as ValidatorColumn[];

  return {
    data: applyFilters(
      data,
      validators.map<Validator>(column => [column.field, column.filter]),
      filters,
    ),
    onFilterChange,
    filters,
  };
};
