import { withStyles } from '@material-ui/core';

import { GridContainer } from 'shared/components';

export const StyledGridContainer = withStyles(({ spacing }) => ({
  root: {
    height: '48px',
    marginBottom: spacing(2),
    '& .MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiTextField-root': {
      marginBottom: 0,
    },
  },
}))(GridContainer);
