import { AuthStateContextType } from '../../authContext/authContext.types';
import {
  CLEAR_TOKENS,
  SET_AUTHORIZED,
  SET_TOKENS,
  SET_UNAUTHORIZED,
  START_AUTHORIZING,
} from '../actions/authStateActions';

export const authStateReducer = (state: AuthStateContextType, action: any): AuthStateContextType => {
  switch (action.type) {
    case START_AUTHORIZING:
      return {
        ...state,
        isAuthorizing: true,
      };
    case SET_TOKENS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case SET_AUTHORIZED:
      return {
        ...state,
        isAuthorized: true,
        isAuthorizing: false,
        user: action.payload,
      };
    case SET_UNAUTHORIZED:
      return {
        ...state,
        isAuthorized: false,
        isAuthorizing: false,
        user: null,
      };
    case CLEAR_TOKENS:
      return {
        ...state,
        refreshToken: '',
        accessToken: '',
        isAuthorized: false,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
