import React from 'react';
import { useMutation } from 'react-fetching-library';

import { createDeleteParticipant } from 'api/actions/participants/participantsActions';
import { ParticipantDeleteDialog } from './ParticipantDeleteDialog';
import { ParticipantDeleteContainerProps } from './ParticipantDelete.types';

export const ParticipantDeleteContainer = ({
  participant,
  onClose,
  open,
  updateParticipantsList,
}: ParticipantDeleteContainerProps) => {
  const { mutate } = useMutation(createDeleteParticipant);

  const handleDelete = async () => {
    const { error } = await mutate(participant?.id || '');

    if (!error) {
      onClose();
      updateParticipantsList();
    }
  };

  return (
    <ParticipantDeleteDialog onClose={onClose} open={open} deleteParticipant={handleDelete} participant={participant} />
  );
};
