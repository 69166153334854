import React from 'react';
import classNames from 'clsx';
import { NavLink } from 'react-router-dom';

import { Icon } from 'shared/components/icon/Icon';
import { Typography } from 'shared/components/typography/Typography';
import { useStyles } from './MenuItem.styles';
import { MenuItemProps } from './MenuItem.types';

export const MenuItem: React.FC<MenuItemProps> = ({ children, icon, expanded, active, to, disabled = false }) => {
  const styles = useStyles();

  const wrapperClassNames = classNames(styles.wrapper, {
    [styles.wrapperExpanded]: expanded,
    [styles.wrapperActive]: active,
    [styles.disabled]: disabled,
  });

  const itemLabelClassNames = classNames(styles.itemLabel, {
    [styles.itemLabelExpanded]: expanded,
  });

  const Content = () => (
    <>
      <Icon icon={icon} className={styles.icon} />
      <Typography className={itemLabelClassNames} variant="body1">
        {children}
      </Typography>
    </>
  );

  if (to) {
    return (
      <NavLink className={wrapperClassNames} to={to} activeClassName={styles.wrapperActive}>
        <Content />
      </NavLink>
    );
  }

  return (
    <div className={wrapperClassNames}>
      <Content />
    </div>
  );
};
