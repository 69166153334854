export const regular = 400;
export const medium = 500;
export const bold = 700;

export const typography = {
  h1: {
    fontSize: 48,
    fontWeight: regular,
    lineHeight: '1.5',
    letterSpacing: -1.5,
  },
  h2: {
    fontSize: 34,
    fontWeight: regular,
    lineHeight: '1.5',
    letterSpacing: 0.25,
  },
  h3: {
    fontSize: 24,
    fontWeight: bold,
  },
  h4: {
    fontSize: 20,
    fontWeight: medium,
    lineHeight: '1.5',
    letterSpacing: 0.15,
  },
  body1: {
    fontSize: 16,
    fontWeight: regular,
    letterSpacing: 0.5,
  },
  button: {
    fontSize: 16,
    fontWeight: medium,
    lineHeight: '1.71',
    letterSpacing: 1.09,
  },
  caption: {
    fontSize: 12,
    fontWeight: regular,
    lineHeight: '1.29',
    letterSpacing: 0.47,
  },
};
