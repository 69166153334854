import React, { useEffect, useState } from 'react';
import {
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  TableSortLabel,
} from '@material-ui/core';
import get from 'lodash/get';

import { usePagination } from 'shared/hooks';
import { Typography, Loader } from 'shared/components';
import { Toolbar } from './toolbar/Toolbar';
import { Pagination } from './pagination/Pagination';
import { TableProps, RowData } from './Table.types';
import { useStyles, TableRow } from './Table.styles';
import { useColumnsState } from './useColumnsState/useColumnsState';
import { useFilters } from './useFilters/useFilters';
import { useSort } from './useSort/useSort';

const defaultFormatter = (value: unknown) => value;
const isPageInPaginationRange = (numberOfResults: number, resultsPerPage: number, page: number) =>
  Math.ceil(numberOfResults / resultsPerPage) - 1 >= page;

export const Table: React.FC<TableProps> = ({
  data,
  columns,
  rowsPerPageOptions = [10, 25, 100],
  onRowClick,
  filtersDialog,
  noDataText = 'table.no_data',
  searchColumns,
  sort,
}) => {
  const styles = useStyles();
  const [searchData, setSearchData] = useState<RowData[] | null>(null);
  const { page, rowsPerPage, handlePageChange, handleRowsPerPageChange } = usePagination(rowsPerPageOptions[0]);
  const { columns: cols, toggle, visible: visibleColumns } = useColumnsState({ initialColumns: columns });
  const { data: filteredData, onFilterChange, filters } = useFilters({ columns, data });
  const { order, orderBy, handleSort, getSortedData, getComparator } = useSort(sort);

  const onSearchChange = (text: string) => {
    if (!filteredData) return;

    const foundSearchColumn = (rowData: RowData) =>
      Object.keys(rowData || {}).filter(key => {
        if (searchColumns?.includes(key)) {
          return (rowData[key] || '').toLowerCase().indexOf(text.toLowerCase()) >= 0;
        }

        return false;
      });

    const foundData = filteredData.filter((el: RowData) => !!foundSearchColumn(el).length);

    setSearchData(foundData || []);
  };

  const handleRowClick = (row: RowData) => () => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const tableData = searchData || filteredData;

  const isPageInRange = tableData && isPageInPaginationRange((tableData || []).length, rowsPerPage, page);

  useEffect(() => {
    if (page !== 0) {
      handlePageChange(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.values(filters).toString()]);

  return (
    <Paper className={styles.wrapper} data-testid="table">
      <Toolbar
        filters={filters}
        columns={cols}
        toggleColumn={toggle}
        onFilterChange={onFilterChange}
        filtersDialog={filtersDialog && filtersDialog({ onFilterChange, filters })}
        search={!!searchColumns}
        onSearchChange={onSearchChange}
        data={data}
      />
      <TableContainer>
        <MaterialTable>
          <TableHead>
            <TableRow>
              {visibleColumns.map(({ title, field, sortable }) => (
                <TableCell key={title} sortDirection={orderBy === field ? order : false}>
                  {sortable === undefined || sortable ? (
                    <TableSortLabel
                      active={field === orderBy}
                      direction={orderBy === field ? order : 'asc'}
                      onClick={() => handleSort(field)}
                    >
                      <Typography variant="caption">{title}</Typography>
                    </TableSortLabel>
                  ) : (
                    <Typography variant="caption">{title}</Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData && !(tableData || []).length && (
              <TableRow>
                <TableCell colSpan={visibleColumns.length} className={styles.textCenter}>
                  <Typography variant="caption">{noDataText}</Typography>
                </TableCell>
              </TableRow>
            )}
            {tableData ? (
              getSortedData(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => (
                  <TableRow key={row.id} hover={!!onRowClick} onClick={onRowClick ? handleRowClick(row) : undefined}>
                    {visibleColumns.map(({ field, formatter = defaultFormatter }) => (
                      <TableCell key={`${row.id}-${field}`}>
                        <>{formatter(get(row, field), row)}</>
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={visibleColumns.length} className={styles.textCenter}>
                  <Loader />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MaterialTable>
        <Pagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={(tableData || []).length}
          rowsPerPage={rowsPerPage}
          // https://github.com/mui-org/material-ui/issues/15616
          page={isPageInRange ? page : 0}
          onChangePage={(event: unknown, newPage: number) => handlePageChange(newPage)}
          onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleRowsPerPageChange(+event.target.value)
          }
        />
      </TableContainer>
    </Paper>
  );
};
