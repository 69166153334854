import withStyles from '@material-ui/styles/withStyles';

import { Paper } from 'shared/components/paper/Paper';

export const Wrapper = withStyles({
  root: {
    padding: '28px 30px',
    marginBottom: 22,
    overflow: 'hidden',
  },
})(Paper);
